.z-desplegable-resumen {
  width: 100%;
  display: block;
  margin: 4px 0 0;

  .titular {
    @include flex();
    @include flex-position-child('left', 'center');
    @include flex-position-vertical('center');
    padding: 4px 0;
    background: var(--bs--color-grises-gris-5);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: calc(100% - 29px);
      left: 19px;
      bottom: 0;
      display: block;
      background: var(--bs--color-principal-naranja);
      z-index: 1;

      @media #{$no-mobile} {
        left: 10px;
      }
    }

    @media #{$no-mobile} {
      background: none;
    }

    figure {
      z-index: 10;
      @include flex();
      @include flex-position-child('center', 'top');
      @include flex-position-vertical('top');
      height: 40px;
      width: 40px;

      img {
        margin-top: 8px;
        height: 22px;
        width: auto;
        z-index: 10;
      }

      @media #{$no-mobile} {
        @include flex-position-child('left', 'top');
      }
    }

    figcaption {
      h2 {
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: var(--bs--color-principal-naranja);
        font-family: $overPass_bold;
        text-transform: uppercase;
      }

      h3 {
        font-size: 1.5rem;
        line-height: 2rem;

        span {
          font-family: $overPass_regular;
        }
      }
    }
  }

  /**/
  .deploy {
    .data {
      @include flex();
      @include flex-position-child('left', 'top');
      @include flex-position-vertical('top');
      padding-right: 8px;
      position: relative;

      @media #{$no-mobile} {
        padding-right: 0;
      }

      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        left: 19px;
        top: 0;
        display: block;
        background: var(--bs--color-principal-naranja);

        @media #{$no-mobile} {
          left: 10px;
        }
      }

      span {
        width: 36px;
        height: 40px;
        position: relative;

        &::after {
          display: block;
          height: 18px;
          width: 18px;
          border: 2px solid var(--bs--color-principal-naranja);
          content: '';
          position: absolute;
          left: 9px;
          top: 10px;
          @include border-radius(50%);
          background: var(--bs--color-principal-blanco) center center no-repeat;

          @media #{$no-mobile} {
            left: 0;
          }
        }
      }

      .data-item {
        flex: 1;
        min-height: 4rem;
        border-bottom: 2px solid var(--bs--color-grises-gris-20);
      }

      &.completed {
        span {
          &::after {
            background-image: $check_button_naranja;
          }
        }

        button.data-interaction {
          line-height: 4rem;
          color: var(--bs--color-principal-negro);
          text-transform: uppercase;
          font-size: 1.3rem;
          font-family: $overPass_bold;
          padding: 0 0 0 4px;
          width: 100%;
          display: inline-block;
          cursor: pointer;
          text-align: left;
          background: $icon_down_naranja calc(100% - 10px) center no-repeat;
          border: none;
          &.active {
            background-image: $icon_up_naranja;
            color: var(--bs--color-principal-negro);

            &:hover {
              color: var(--bs--color-grises-gris-80);
              filter: grayscale(0%);
              -webkit-filter: grayscale(0%);
            }
          }

          &:hover {
            color: var(--bs--color-grises-gris-50);
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
          }
        }

        .data-item {
          .extra {
            padding-left: 16px;
          }
        }
      }

      &.current {
        &::before {
          height: 50%;
        }

        .data-item {
          background: var(--bs--color-secundario-naranja-10);

          padding: 0 4px 0 8px;

          h2 {
            color: var(--bs--color-principal-naranja);
            text-transform: uppercase;
            font-size: 1.3rem;
            font-family: $overPass_bold;
            line-height: 4rem;
          }
        }
      }
    }
  }
}
