figure.z-iconos {
  @include figure-size(64px, 64px);
  margin: 0;
  padding: 0;

  &.plain {
    height: auto !important;
    width: auto !important;
    @include flex-inline();
    @include flex-position-child("center", "center");
    @include flex-position-vertical("center");
  }

  img {
    width: 100%;
    height: auto;
  }

  /*Sistema de tamaños, la caja */

  display: inline-block;

  &.block {
    display: block;
  }

  &.z-center {
    text-align: center;
    @include flex();
    @include flex-position-child("center", "center");
    @include flex-position-vertical("center");

    img {
      display: inline-block;
      margin: 0 auto;
    }
  }

  @for $i from 0 through 30 {
    $height: 5px+($i * 5);
    $name: 5+($i * 5);
    $height_tx: 5px+($i * 5px);

    &.w-#{$name} {
      @include figure-size($height, $height);

      img {
        @include figure-size($height, $height);
      }

    }
  }

  $sizes: 16, 24, 32, 48, 64, 72, 96, 144;

  @each $size in $sizes {
    &.w-#{$size} {

      @include figure-size(#{$size}px, #{$size}px);

      img {
        @include figure-size(#{$size}px, #{$size}px);
      }
    }
  }

}

figure.z-imagen {
  img {
    width: 100%;
    height: auto;
  }
}

figure {
  display: inline-block;

  &.block {
    display: block;
  }

  &.z-ilustraciones {
    $sizes: 64,
    128,
    256;

    @each $size in $sizes {
      &.w-#{$size} {

        @include illus-size(#{$size}px);

        img {
          @include illus-size(#{$size}px);
        }
      }
    }
  }
}