.z-menu {
  position: fixed;
  background-color: var(--bs--color-grises-gris-10);
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 600px;
  height: 100vh;
  z-index: 1024;
  @include box-shadow(4px, 0px, 20px, 0px, rgba(0, 0, 0, 0.2), false);
  overflow: auto;
  @include scrolling(
    4px,
    4px,
    6px,
    var(--bs--color-grises-gris-10),
    var(--bs--color-grises-gris-50)
  );

  @media #{$tablet } {
    max-width: 360px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  a.close {
    cursor: pointer;
    position: relative;

    span {
      @include animate(all, 0.08s, linear);
      opacity: 1;
      color: var(--bs--color-grises-gris-80);
      font-size: 1.6rem;
    }

    width: 100%;
    text-decoration: none;
    min-height: 48px;
    background: $icon-zenit-cruz 0px center no-repeat;
    background-size: auto 40px;
    opacity: 0.4;

    &:hover {
      opacity: 0.8;
    }
  }

  figure {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    height: 20px;
    width: 16px;
    display: inline-block;
    margin: 0 24px 0 12px;
    padding: 0;
    @include flex();
    @include flex-position-child('center', 'center');
    @include flex-position-vertical('center');

    img {
      display: inline-block;
      margin: 0;
      padding: 0;
    }

    animation-duration: 0.4s;
    animation-name: menuExpand;
    margin: 0 12px;

    @media #{$tablet} {
      margin: 0 24px 0 12px;
    }
  }

  button {
    span {
      opacity: 1;
      color: var(--bs--color-grises-gris-80);
      font-size: 1.6rem;
      line-height: 1;
      font-family: $overPass_bold;
      @include animate(all, 0.08s, linear);
    }

    @include flex();
    @include flex-position-child('left', 'center');
    @include flex-position-vertical('center');

    &:hover {
      figure {
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
      }

      span {
        color: var(--bs--color-principal-naranja);
      }
    }

    cursor: pointer;
  }

  > ul {
    li {
      a {
        cursor: pointer;
      }

      list-style: none;
      padding: 0;

      &.header {
        @include flex();
        @include flex-position-child('left', 'center');
        @include flex-position-vertical('center');

        a {
          figure {
            filter: grayscale(0%) !important;
            -webkit-filter: grayscale(0%) !important;
          }
        }
      }
    }

    > li:not(.header) {
      border-bottom: 1px solid rgba($gris-20, 0.8);

      &:nth-child(2) {
        border-top: 1px solid rgba($gris-20, 0.8);
      }

      &:last-child {
        border-top: none;
        border-bottom: none;
      }

      > .sub-menu {
        padding-left: 40px;

        > li {
          min-height: 24px;

          a {
            flex: 0 0 100%;
            font-family: $overPass_regular;
            color: var(--bs--color-grises-gris-80);
            text-decoration: none;
            font-size: 1.6rem;
            min-height: 24px;
            line-height: 1;
            cursor: pointer;
            @include flex();
            @include flex-position-child('left', 'center');
            @include flex-position-vertical('center');
            @include animate(all, 0.08s, linear);

            &:hover {
              color: var(--bs--color-principal-naranja);
            }
          }

          span {
            opacity: 1;
          }

          > .sub-menu {
            flex: 0 0 100%;
            // display: none;
            padding-left: 16px;
            //    border-left: 2px solid var(--bs--color-grises-gris-10);
            padding-top: 4px;
            padding-bottom: 4px;

            li {
              min-height: 24px;

              a {
                line-height: 1;
                min-height: 24px;
                font-size: 1.3rem;
              }
            }

            .sub-menu {
              display: none;
            }
          }
        }
      }

      > a {
        //animation-duration: 0.4s;
        //animation-name: menuslide_back;
        & + ul {
          display: none;
        }

        &.activo {
          & + ul {
            opacity: 0.8;
            background: var(--bs--color-principal-blanco);
            display: block;
          }
        }

        &.padre {
          background: $icon_next_naranja calc(100% - 8px) center no-repeat;
          background-size: auto 16px;
        }
      }

      &:hover {
        > a {
          opacity: 0.8;
          //@include box-shadow(-36px, 0px, 24px, 0px, rgba(0, 0, 0, 0.2), false);
          position: relative;
          background: var(--bs--color-principal-blanco);

          &.padre {
            background: $icon_down_naranja var(--bs--color-principal-blanco)
              calc(100% - 8px) center no-repeat;
            background-size: 16px auto;
          }

          > span {
            font-family: $overPass_bold;
          }

          &.activo {
            opacity: 1;

            > span {
              font-family: $overPass_bold;
            }
          }

          & + ul {
            opacity: 0.8;
            background: var(--bs--color-principal-blanco);

            display: block;
          }
        }
      }

      > a {
        font-family: $overPass_regular;
        cursor: pointer;
        position: relative;

        span {
          @include animate(all, 0.08s, linear);
          opacity: 1;
          color: var(--bs--color-grises-gris-80);
          font-size: 1.6rem;
        }

        width: 100%;
        text-decoration: none;
        min-height: 48px;

        @media #{$desktop} {
          min-height: 64px;
        }

        @include flex();
        @include flex-position-child('left', 'center');
        @include flex-position-vertical('center');

        &:hover {
          figure {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
          }

          span {
            color: var(--bs--color-principal-naranja);
          }
        }

        &:hover,
        &.activo {
          span {
            color: var(--bs--color-principal-naranja);
          }

          figure {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
          }
        }

        &.activo {
          z-index: 101;
          //  @include box-shadow(-36px, 0px, 24px, 0px, rgba(0, 0, 0, 0.1), false);
          position: relative;
          //   background-color: var(--bs--color-principal-blanco);
          font-family: $overPass_bold;

          &:after,
          &:before {
            height: 12px;
            width: 12px;
            display: block;
            content: '';
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}

.z-menu-back {
  display: none;

  @media #{$tablet} {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
}
