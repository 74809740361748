.bloque-planes {
  background-color: var(--bs--color-principal-blanco);
  padding-top: 4px;
  padding-bottom: 4px;
  @include border-radius(10px);
  @include flex();
  @include flex-position-child('center', 'top');
  @include flex-position-vertical('top');
  @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.12), false);
  > .h3 {
    margin: 8px 0;
  }
  > .h4 {
    & + .h1 {
      line-height: 1.3 !important;
    }
  }
  > .h1 {
    line-height: 2 !important;
  }
  > section {
    width: 100%;
    padding: 4px 0;
    background-color: var(--bs--color-secundario-naranja-10);
  }
  .z-btn-link {
    margin: 8px 0 !important;
    width: 100% !important;
    display: inline-block;
  }

  > article {
    width: 100%;
    display: block;
    margin: 8px;
    border-top: var(--bs--color-grises-gris-20) 1px solid;
    border-bottom: var(--bs--color-grises-gris-20) 1px solid;

    > div {
      @include flex();
      @include flex-position-child('bw', 'center');
      @include flex-position-vertical('center');
      padding: 4px 6px;
      > p {
        font-size: 1.2rem;
        flex: 2;
        text-align: left;

        &:last-child {
          flex: 1;
          text-align: right;
        }
      }
    }
  }

  p {
    width: 100%;
  }
  &.compacto {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5 {
      // line-height: 1;
    }
  }
}
