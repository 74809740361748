.z-informacion-lateral {
  width: 100%;
  height: 100%;
  display: block;

  figure {
    flex: 0 0 100%;
    img {
      height: 30px;
      @media #{$desktop } {
        height: auto;
      }
    }
  }

  h1 {
    font-family: $overPass_regular;
    font-size: 2.2rem;
    text-align: left;
    line-height: 1;

    color: var(--bs--color-principal-negro);

    @media #{$tablet } {
      font-size: 2.6rem;
    }

    @media #{$desktop } {
      line-height: 1.3;
      font-size: 3rem;
    }

    span {
      font-family: $overPass_bold;
      display: block;
      font-size: 3rem;

      @media #{$tablet } {
        font-size: 4rem;
      }

      @media #{$full } {
        font-size: 5rem;
      }
    }
  }

  &.claro {
    h1 {
      color: var(--bs--color-principal-blanco);
    }
  }
}
