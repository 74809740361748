.z-contenedor {
  margin: 0 auto;
  display: block;
  width: 100%;
  padding: 0 16px;
  max-width: $container-size;

  &.extended {
    max-width: $big-container-size;
  }

  &.full {
    max-width: calc(100% - 32px) !important;
  }
}

.z-main-container {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  overflow: hidden auto;
  @include scrolling(
    2px,
    4px,
    6px,
    var(--bs--color-grises-gris-10),
    var(--bs--color-principal-negro)
  );
}

.z-background {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  background-size: cover;
  background-color: var(--bs--color-grises-gris-20);
  background-repeat: no-repeat;
  background-position: center center;

  overflow: hidden auto;
  @include scrolling(
    2px,
    4px,
    6px,
    var(--bs--color-grises-gris-10),
    var(--bs--color-principal-negro)
  );
}

.z-wrapper {
  margin: 0 auto;
  display: block;
  padding: 0 16px;
  max-width: $container-size;
  width: 100%;

  &.extended {
    max-width: calc($container-size + 380px);
  }

  .dummy {
    flex: 1;
  }
}

.no-margin {
  margin: 0 !important;
}

.no-margin-tb {
  margin-top: 0 !important;

  margin-bottom: 0 !important;
}

.no-margin-lr {
  margin-left: 0 !important;

  margin-right: 0 !important;
}
