h1.z-titulo,
p.z-titulo.h1 {
  font-family: $overPass_bold;
  font-size: var(--bs--font-size-h1);
  color: var(--bs--color-principal-negro);

  &.z-contenido {
    font-size: var(--bs--font-size-titulo-xl);
    text-transform: uppercase;
  }

  &.z-publicidad {
    font-size: var(--bs--font-size-titulo-publicidad-h1);
    font-weight: 900;
    font-family: $nunito;
    line-height: 1.3;
  }

  &.z-especial {
    font-size: var(--bs--font-size-h1-especial);

    span {
      display: block;
      font-size: var(--bs--font-size-h1-especial-span);
    }
  }
}

h2.z-titulo,
p.z-titulo.h2 {
  font-size: var(--bs--font-size-h2);
  font-family: $overPass_bold;
  color: var(--bs--color-principal-naranja);
  line-height: 2rem;

  @media #{$tablet } {
    line-height: 3.4rem;
  }

  &.z-contenido {
    font-size: var(--bs--font-size-titulo-l);
    text-transform: uppercase;
    &strong {
      font-weight: 900;
    }
  }

  &.z-publicidad {
    font-size: var(--bs--font-size-titulo-publicidad-h2);
    line-height: 1.3;
    font-family: $nunito;
  }
}

h3.z-titulo,
p.z-titulo.h3 {
  font-size: var(--bs--font-size-h3);
  font-family: $overPass_bold;
  color: var(--bs--color-principal-negro);

  &.z-contenido {
    font-size: var(--bs--font-size-titulo-m);
    text-transform: uppercase;
    color: var(--bs--color-principal-azul);

    &.z-gris-80 {
      color: var(--bs--color-grises-gris-80);
    }

    &.z-negro {
      color: var(--bs--color-principal-negro);
    }
  }

  &.z-publicidad {
    font-size: var(--bs--font-size-titulo-publicidad-h3);
    font-weight: 900;
    color: var(--bs--color-principal-naranja);
    font-family: $nunito;
    line-height: 1.3;
  }
}

h4.z-titulo,
p.z-titulo.h4 {
  font-family: $overPass_regular;
  font-size: var(--bs--font-size-h4);
  color: var(--bs--color-principal-negro);
}

h5.z-titulo,
p.z-titulo.h5 {
  font-family: $overPass_bold;
  font-size: var(--bs--font-size-h5);
  color: var(--bs--color-principal-negro);
}

p.z-label,
.z-label {
  color: var(--bs--color-principal-negro);
  font-size: 1.5rem;
  font-family: $overPass_bold;
  line-height: 3rem;

  &.simple {
    font-family: $overPass_regular;
  }

  &[disabled] {
    color: var(--bs--color-grises-gris-50);

    & ~ .z-caption {
      color: var(--bs--color-grises-gris-50);
    }
  }
}

.z-optional {
  font-family: $overPass_regular;
  font-size: 1.3rem;
  color: var(--bs--color-grises-gris-80);
  margin-left: 4px;
}

.z-required {
  color: var(--bs--color-secundario-rojo);
}

h5.z-titulo,
p.z-titulo.h1,
h4.z-titulo,
p.z-titulo.h2,
h3.z-titulo,
p.z-titulo.h3,
h2.z-titulo,
p.z-titulo.h4,
h1.z-titulo,
p.z-titulo.h5,
p,
small {
  &.z-center {
    text-align: center !important;
  }

  line-height: 1.2;

  @media #{$desktop } {
    line-height: 1.6;
  }

  &.z-stroke {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    -webkit-text-decoration: line-through;
    -webkit-text-decoration-thickness: 2px;
  }

  &.z-normal {
    font-family: $overPass_regular;
  }

  &.z-negrita {
    font-family: $overPass_bold;
  }

  &.z-gris-50,
  &.gris-50 {
    color: var(--bs--color-grises-gris-50);
  }

  &.z-gris-80,
  &.gris-80 {
    color: var(--bs--color-grises-gris-80);
  }

  &.z-negro,
  &.negro {
    color: var(--bs--color-principal-negro);
  }

  &.z-naranja,
  &.naranja {
    color: var(--bs--color-principal-naranja);
  }

  &.z-blanco,
  &.blanco {
    color: var(--bs--color-principal-blanco);
  }

  &.z-up {
    text-transform: uppercase;
  }

  strong {
    font-family: $overPass_bold;
  }
}

p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo) {
  font-size: var(--bs--font-size-p);
  font-family: $overPass_regular;
  color: var(--bs--color-principal-negro);
  line-height: 2.4rem;

  a {
    cursor: pointer;
    color: var(--bs--color-principal-naranja);
    text-decoration: underline;
    font-family: $overPass_bold;

    &:hover {
      color: var(--bs--color-secundario-naranja-hover);
    }
  }

  strong {
    font-family: $overPass_bold;

    &.naranja {
      color: var(--bs--color-principal-naranja);
    }
  }

  &.z-caption {
    font-size: var(--bs--font-size-caption);
    color: var(--bs--color-grises-gris-50);
    line-height: 1.5rem;

    &.z-error {
      color: var(--bs--color-secundario-rojo);
    }
  }
}

p small,
small {
  font-size: var(--bs--font-size-caption);
  font-family: $overPass_regular;
  color: var(--bs--color-principal-negro);
  line-height: 0.8rem !important;

  strong {
    font-family: $overPass_bold;
  }

  a {
    font-family: $overPass_bold;
    color: var(--bs--color-principal-naranja);
    text-decoration: underline;
  }
}

// listas

div.z-lista {
  @include flex();
  @include flex-position-child('left', 'top');
  @include flex-position-vertical('top');

  span {
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    color: var(--bs--color-principal-blanco);
    font-size: 1.3rem;
    margin-right: 12px;
    background-color: var(--bs--color-principal-naranja);
    @include border-radius(50%);
    margin-top: 2px;

    &.check {
      background: $check_button_naranja center center no-repeat;
    }
  }

  p {
    flex: 1;
  }
}

ul.z-lista,
ol.z-lista {
  li {
    list-style: none;
    font-size: var(--bs--font-size-p);
    color: var(--bs--color-principal-negro);
    margin-bottom: 12px;
    padding-left: 30px;
  }
}

ul.z-lista {
  li {
    background: $icon_next_naranja left 1px no-repeat;
    background-size: auto 20px;

    min-height: 24px;
    line-height: 1.5;
  }
}

ol.z-lista {
  counter-reset: element-counter;

  li {
    position: relative;
    counter-increment: element-counter;

    &::before {
      content: counter(element-counter);
      height: 24px;
      width: 24px;
      display: grid;
      place-items: center;
      position: absolute;
      @include border-radius(50%);
      left: 0;
      top: 1px;
      color: var(--bs--color-principal-blanco);
      background-color: var(--bs--color-principal-naranja);
      font-family: $overPass_bold;
      font-size: 1.2rem;
    }
  }
}
