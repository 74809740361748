// variables globales

:root {
  --bs--font-size-titulo-xl: clamp(1.5rem, 3vw + 0.2rem, 2rem);
  --bs--font-size-titulo-l: clamp(1.3rem, 3vw + 0.2rem, 1.7rem);
  --bs--font-size-titulo-m: clamp(1.2rem, 3vw + 0.2rem, 1.5rem);
  --bs--font-size-titulo-label: 1.5rem;
  --bs--font-size-placeholder: 1.5rem;
  //
  --bs--font-size-titulo-publicidad-h1: clamp(2rem, 3vw + 0.2rem, 3.6rem);
  --bs--font-size-titulo-publicidad-h2: clamp(1.8rem, 3vw + 0.2rem, 2rem);
  --bs--font-size-titulo-publicidad-h3: clamp(1.6rem, 3vw + 0.2rem, 1.8rem);
  //
  --bs--font-size-label-checkbox: 1.5rem;
  --bs--font-size-p: 1.5rem;
  --bs--font-size-caption: 1.3rem;
  --bs--font-size-menu: 1.5rem;
  --bs--font-size-h1: clamp(2.2rem, 3vw + 0.2rem, 4rem);
  --bs--font-size-h1-especial: clamp(2.2rem, 4vw, 3rem);
  --bs--font-size-h1-especial-span: clamp(3rem, 4vw, 5rem);
  --bs--font-size-h2: clamp(1.8rem, 2vw + 0.2rem, 3rem);
  --bs--font-size-h3: clamp(1.6rem, 2vw + 0.2rem, 2rem);
  --bs--font-size-h4: 1.8rem;
  --bs--font-size-h5: 1.5rem;
  --bs--font-size-button: 1.5rem;

  // colores
  --bs--color-principal-azul: #006fb9;
  --bs--color-comercial-amarillo: #f9b316;
  --bs--color-principal-verde: #00953a;
  --bs--color-principal-blanco: #{$blanco};
  --bs--color-principal-negro: #{$negro};
  --bs--color-principal-naranja: #{$naranja};
  --bs--color-secundario-naranja-10: #{$naranja-10};
  --bs--color-secundario-naranja-20: #{$naranja-20};
  --bs--color-secundario-naranja-50: #{$naranja-50};
  --bs--color-secundario-naranja-90: #{$naranja-90};
  --bs--color-secundario-naranja-hover: #{$naranjahover};
  --bs--color-secundario-naranja-dark: #{$naranjoOscuro};
  --bs--color-secundario-negro-hover: #{$gris-80};
  --bs--color-secundario-blanco-hover: #{$gris-20};

  // monocromo
  --bs--color-grises-gris-5: #{$gris-5};
  --bs--color-grises-gris-10: #{$gris-10};
  --bs--color-grises-gris-15: #{$gris-15};
  --bs--color-grises-gris-20: #{$gris-20};
  --bs--color-grises-gris-40: #{$gris-40};
  --bs--color-grises-gris-50: #{$gris-50};
  --bs--color-grises-gris-80: #{$gris-80};

  // Rojo
  --bs--color-secundario-rojo: #{$rojo};
  --bs--color-secundario-rojo-hover: #{$rojo-hover};
  --bs--color-validacion-rojo-10: #{$rojo-10};
  --bs--color-validacion-rojo-50: #{$rojo-50};
}
