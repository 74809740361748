.z-pasos-indice {
  @include flex();
  @include flex-position-child('left', 'center');
  width: 100%;

  p-progressbar {
    margin-top: 4px;
    flex: 0 0 100%;
  }

  .index {
    height: 24px;
    width: 24px;
    display: grid;
    place-items: center;
    @include border-radius(50%);
    color: var(--bs--color-grises-gris-50);
    border: 2px solid var(--bs--color-grises-gris-50);

    font-size: 1.4rem;
    font-family: $overPass_bold;
    text-align: center;

    padding: 0;
    margin: 0 8px 0 0;
  }

  .pasos {
    color: var(--bs--color-grises-gris-80);
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: $overPass_regular;
    text-align: right;
  }

  h2.z-contenido {
    font-family: $overPass_bold;
    flex: 1;
    color: var(--bs--color-grises-gris-50);
  }

  &.active {
    .index {
      color: var(--bs--color-principal-naranja);
      border: 2px solid var(--bs--color-principal-naranja);
    }

    h2 {
      color: var(--bs--color-principal-negro);

      span {
        color: var(--bs--color-principal-naranja);
      }
    }
  }

  &.completed {
    .index {
      color: rgba(0, 0, 0, 0);
      border: 2px solid var(--bs--color-principal-naranja);
      background: $check_button_blanco var(--bs--color-principal-naranja) center
        center no-repeat;
      background-size: auto 12px;
    }

    h2 {
      color: var(--bs--color-principal-naranja);
    }
  }
}
