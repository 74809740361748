$blanco:#ffffff !default; 
$negro:#000000 !default;
$naranja : #ff9015 !default;
$naranja-10: #FFF4E8 !default;
$naranja-20: #FFE9D0 !default;
$naranja-50: #FFC78A !default;
$naranja-90: #FD9B2D !default;
$naranjahover: #E06308 !default;
$naranjoOscuro: #673906 !default;
// monocromo
$gris-5: #fafafa !default;
$gris-10: #F4F4F4 !default;
$gris-15: #EFF1F4 !default;
$gris-20: #EAEAEA !default;
$gris-40: #E2E2E3 !default;
$gris-50: #ACADAC !default;
$gris-80: #8f8f8f !default;
// rojo
$rojo: #EF3742 !default;
$rojo-10: #FFE7E9 !default;
$rojo-50: #F79BA0 !default; 
$rojo-hover: #B91B24 !default;
/*
$verde-hover:#037730 !default;
$azul-hover:#0C6098 !default;
 
// secundarios
$amarillo: #F7D500 !default;
 
$verde-50:  #80CA9C !default;
$azul-40:  #99C5E3 !default;
$azul-10:  #F0F8FE !default;
// escala de grises
$gris-10: #F4F4F4 !default;
$gris-15: #EFF1F4 !default;
$gris-20: #EAEAEA !default;
$gris-40: #E2E2E3 !default;
$gris-50: #ACADAC !default;
$gris-80: #8f8f8f !default;

// validacion

$verde-10: #E5F4EB !default;
$verde-50: #80CA9C !default;
$amarillo-10: #FEF9D9 !default;
$amarillo-50: #FBEA80 !default;
 */
