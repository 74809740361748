.z-paginador {
  margin-top: 20px;
  min-height: 40px;
  width: 100%;
  display: block;

  flex: 0 0 100%;
  @include flex();

  @include flex-position-child('center', 'center');
  @include flex-position-vertical('center');
  position: relative;

  .p-paginator {
    flex: 0 0 100%;
    width: 100%;
    @include centrar();
    @include flex-position-child('bw', 'center');
  }

  .custom-nav {
    width: 100%;
    @include flex();
    @include flex-position-child('bw', 'center');
    @include flex-position-vertical('center');

    section {
      flex: 1;
      @include flex();
      @include flex-position-child('center', 'center');
      @include flex-position-vertical('center');
    }

    .elipsis {
      font-size: 2rem;
      height: 28px;
      margin: 0 4px;
      color: var(--bs--color-grises-gris-80);
      font-family: $overPass_bold;
      text-align: center;
      @include flex();
      @include flex-position-child('center', 'bottom');
      @include flex-position-vertical('bottom');
      line-height: 2.4rem;
      margin: 0 2px;

      @media #{$tablet} {
        height: 32px;
      }
    }

    button {
      font-size: 1.5rem;
      height: 28px;
      margin-right: 0px;

      @media #{$tablet} {
        height: 32px;
        margin: 0 2px;
      }

      @include flex();
      @include flex-position-child('center', 'center');
      @include flex-position-vertical('center');
      @include border-radius(50px);
      cursor: pointer;

      &.disabled {
        background: none;
        color: var(--bs--color-grises-gris-50);
        cursor: not-allowed;

        opacity: 0.3;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }

      &.number {
        padding: 0 4px;
        min-width: 24px;
        font-family: $overPass_regular;
        //background-color: var(--bs--color-grises-gris-80);
        color: var(--bs--color-grises-gris-80);

        @media #{$tablet} {
          min-width: 32px;
        }

        &.activo {
          font-family: $overPass_bold;
          color: $blanco;
          background-color: var(--bs--color-principal-naranja);
        }

        &:hover {
          font-family: $overPass_bold;

          color: var(--bs--color-principal-naranja);

          &.activo {
            color: $blanco;
            background-color: var(--bs--color-secundario-naranja-hover);
          }
        }
      }

      &.nav {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 20px;
        width: 24px;

        @media #{$tablet} {
          width: 48px;
        }

        &.nav-first {
          background-image: $icon-paginator-first;
          background-size: auto 16px;
        }

        &.nav-last {
          background-size: auto 16px;
          background-image: $icon-paginator-last;
        }

        &.nav-next {
          background-image: $icon-paginator-next;
        }

        &.nav-back {
          background-image: $icon-paginator-prev;
        }

        &:hover {
          background-color: var(--bs--color-grises-gris-10) !important;

          &.disabled {
            background-color: var(--bs--color-principal-blanco) !important;
          }
        }
      }
    }
  }
}
