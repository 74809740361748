p-checkbox {
  margin-bottom: 8px;
  .compacto & {
    margin-bottom: 0px;
  }
  .z-tabla-datos & {
    margin-bottom: 0px;
  }
  align-items: flex-start !important;
}
.p-checkbox {
  margin: 0 10px 0 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  .z-tabla-datos & {
    margin: 0 10px 0 0;
  }
  &.p-checkbox-focused {
    .p-checkbox-box {
      border: 1px solid var(--bs--color-grises-gris-80) !important;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }
  }
  &.p-checkbox-checked {
    .p-checkbox-box {
      position: relative;
      &:after {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: $check_button_naranja center center no-repeat !important;
      }
    }
  }
  .p-checkbox-box {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    background-color: var(--bs--color-principal-blanco);
    .p-checkbox-icon {
      display: none;
    }

    @include no-shadow();
    &:hover {
      @include no-shadow();
    }
    span {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }
    height: 24px;
    width: 24px;
    @include border-radius(4px);
    border: 1px solid var(--bs--color-grises-gris-80) !important;
    &:focus {
      border: 1px solid var(--bs--color-grises-gris-80) !important;
    }
    &.p-highlight {
      background-color: var(--bs--color-principal-blanco) !important;
      border-color: var(--bs--color-principal-negro) !important;
      @include no-shadow();
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      span {
        color: var(--bs--color-principal-negro);
        font-weight: 700;
        font-size: 1.2rem;
      }
      &:hover {
        background: var(--bs--color-principal-negro);
        border-color: var(--bs--color-principal-negro);
      }
    }
    &.p-disabled {
      cursor: not-allowed;
      border-color: var(--bs--color-grises-gris-50) !important;
      opacity: 1 !important;

      &:after {
        opacity: 0.5 !important;
      }
    }
    /* .ng-invalid &, */
    .is-invalid & {
      border-color: var(--bs--color-validacion-rojo-50) !important;
    }
  }
  & + .p-checkbox-label {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $overPass_bold;
    color: var(--bs--color-principal-negro);
    font-size: var(--bs--font-size-label-checkbox);
    margin-left: 0 !important;
    line-height: 2.4rem;
  }
  &.p-checkbox-disabled {
    cursor: not-allowed;
    .p-checkbox-box {
      cursor: not-allowed;
    }
    & + .p-checkbox-label {
      cursor: not-allowed;

      color: var(--bs--color-grises-gris-80);
    }
  }
}

.p-multiselect {
  .p-checkbox-box {
    &.p-highlight {
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: $check_button_naranja center center no-repeat !important;
      }
    }
  }
}
