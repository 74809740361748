.z-modal {
  .p-dialog-mask {
    z-index: 10000001 !important;
  }

  @for $i from 4 through 100 {
    $width_modal: ($i * 10px);
    $name_modal: ($i * 10);

    &.w-#{$name_modal} {
      .p-dialog {
        width: 98%;
        max-width: $width_modal;
      }
    }
  }

  .p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .p-dialog {
    @include border-radius(3px);
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border: 0 none;

    .p-dialog-header {
      border-bottom: 0 none;
      background: #ffffff;
      color: #495057;
      padding: 1.5rem 1.5rem 0.2rem 1.5rem;
      @include border-radius(4px 4px 0 0);
      @include flex-position-child('bw', 'top');

      .z-titulo,
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      h1,
      h2,
      h3,
      h4,
      h5 {
        flex: 0 0 100%;
        width: 100%;
        padding: 8px 0 4px;

        figure {
          margin: 20px auto 10px;
          display: block;
        }
      }

      .p-dialog-header-icons {
        position: absolute;
        top: 10px;
        right: 10px;

        .p-dialog-header-close {
          height: 24px;
          width: 24px;
          background: $icon_close_naranja center center no-repeat;
          background-size: auto 16px;

          @media #{$tablet } {
            background-size: auto 20px;
          }

          .p-dialog-header-close-icon {
            &::before {
              display: none;
            }
          }
        }
      }
    }

    .p-dialog-content {
      background: var(--bs--color-principal-blanco);
      padding: 0 20px;

      @media #{$tablet} {
        padding: 0 30px;
      }

      @media #{$desktop} {
        padding: 0 60px;
      }

      .simple {
        color: var(--bs--color-grises-gris-50);
        width: 100%;
        @include flex();
        @include flex-position-child('center', 'center');
        @include flex-position-vertical('center');

        & ~ .simple {
          padding-top: 10px;
          padding-bottom: 20px;
        }
      }
    }

    .dummy {
      width: 20px;
      color: var(--bs--color-grises-gris-40);
      text-align: center;
    }

    .p-dialog-footer {
      @include border-radius(0 0 4px 4px);
      background: var(--bs--color-principal-blanco);
      color: var(--bs--color-principal-negro);
      padding: 1rem 16px;
      position: relative;

      .simple {
        color: var(--bs--color-grises-gris-50);
        width: 100%;
        @include flex();
        @include flex-position-child('center', 'center');
        @include flex-position-vertical('center');

        & ~ .simple {
          padding-top: 10px;
          padding-bottom: 20px;
        }
      }

      .extremos {
        width: 100%;
        padding: 10px;
        @include flex();
        @include flex-position-child('bw', 'center');
        @include flex-position-vertical('center');

        .z-btn-primary {
          @media #{$no-mobile} {
          }
        }
      }

      .derecha {
        width: 100%;
        padding: 10px;
        @include flex();
        @include flex-position-child('right', 'center');
        @include flex-position-vertical('center');
      }

      .izquierda {
        width: 100%;
        padding: 10px;
        @include flex();
        @include flex-position-child('left', 'center');
        @include flex-position-vertical('center');
      }

      .centrar {
        width: 100%;
        padding: 10px;
        @include flex();
        @include flex-position-child('center', 'center');
        @include flex-position-vertical('center');
      }

      .extremos,
      .derecha,
      .izquierda,
      .centrar {
        @media #{$no-m} {
          .z-btn-primary {
            order: 1;
          }

          .z-btn-secondary {
            order: 2;
          }

          .z-btn-link {
            order: 3;
          }
        }
      }
    }

    .z-modulo-ayuda {
      position: absolute;
      left: 0;
      width: 100%;
      padding: 10px;
      background-color: var(--bs--color-secundario-naranja-10);

      &.gris {
        background-color: var(--bs--color-grises-gris-10);
      }

      p,
      h5,
      .h5 {
        text-align: center;
        line-height: 1.6;
      }

      p:not(.h5) {
        font-size: 1.7rem;
      }
    }
  }

  &.busqueda-avanzada {
    .p-dialog {
      max-height: calc(100% - 1.5rem);

      .p-dialog-content {
        padding: 0 16px;
        @include scrolling(
          4px,
          4px,
          6px,
          var(--bs--color-grises-gris-10),
          var(--bs--color-grises-gris-50)
        );
      }
    }

    .p-dialog-header {
      padding-top: 2px;
      padding-bottom: 2px;

      @media #{$tablet} {
        padding-top: 48px;
      }

      .p-dialog-header-icons {
        top: 8px;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        width: 100%;
        padding-top: 6px;
        padding-bottom: 6px;

        @media #{$tablet} {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
    }
  }

  &.compacto {
    .p-dialog-content {
      padding: 0;
    }

    .p-dialog-header {
      padding-top: 2px;
      padding-bottom: 2px;

      .p-dialog-header-icons {
        top: 8px;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        width: 100%;
        padding-top: 6px;
        padding-bottom: 6px;

        @media #{$tablet} {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
    }
  }

  &.z-dividido {
    .p-dialog {
      .p-dialog-header {
        height: 0;
        padding: 0;
      }

      .p-dialog-content {
        padding: 0;
        @include border-radius(4px);

        display: block;

        @media #{$tablet} {
          @include grid(2, 0px, 0px);
        }

        .dialog-column {
          flex: 0 0 50%;
          padding: 20px;
          height: 100%;
          display: block;

          &.background {
            &.gris-10 {
              background: var(--bs--color-grises-gris-10);
            }

            &.gris-20 {
              background: var(--bs--color-grises-gris-20);
            }

            &.gris-40 {
              background: var(--bs--color-grises-gris-40);
            }
          }
        }
      }
    }
  }
}

.z-modulo-dialog-imagen-detalle {
  width: 100%;
  min-height: 50px;
  display: block;
  position: relative;
  @include animate(height, 0.1s, linear);

  @media #{$tablet} {
    min-height: 50px;
  }

  @media #{$desktop} {
    min-height: 50px;
  }

  img {
    /*
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    object-fit: cover;*/
    width: calc(100% - 20px);
    height: auto;
    margin: 10px;
  }
}

.z-modulo-lista-tabla-datos {
  width: 100%;
  padding: 16px 16px 4px;

  .modulo {
    width: 100%;
    @include flex();
    @include flex-position-child('bw', 'top');
    @include flex-position-vertical('top');
    min-height: 90px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid var(--bs--color-grises-gris-20);
    border-bottom: 1px solid var(--bs--color-grises-gris-20);

    @media #{$tablet} {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &:last-child {
      border-bottom: none;
    }

    figure {
      flex: 1 0 100%;
      max-width: 75px;
      margin-right: 12px;

      img {
        width: 100%;
        height: 75px;
        object-fit: cover;
        @include border-radius(8px);
      }
    }

    figcaption {
      flex: 1;
      @include flex();
      @include flex-position-child('bw', 'top');
      @include flex-position-vertical('top');

      .z-simple-data {
        margin-bottom: 8px;
        display: inline;
        width: auto;

        &:first-child {
          width: 100%;

          @media #{$tablet} {
            width: auto;
          }
        }
      }

      a {
        flex: 0 0 100%;
        text-align: left;
      }
    }
  }
}
