.z-separador {
  clear: both;
  float: none;
  width: 100%;
  display: block;
  height: 10px;

  @for $i from 0 through 22 {
    $height: 5px+ ($i * 5);
    $name: 5+ ($i * 5);

    &.h-#{$name} {
      height: $height;
    }
  }

  &.lineal {
    position: relative;
    margin: 2px 0;

    &:after {
      display: block;
      content: '';
      @include centrar(50%, 50%);
      width: 100%;
      background-color: var(--bs--color-grises-gris-40);
    }

    @for $i from 1 through 10 {
      $height: 1px * ($i);
      $name: 1 * ($i);

      &.th-#{$name} {
        &:after {
          @include border-radius(calc(#{$height}/ 2));
          height: $height !important;
        }
      }
    }
  }
  &.h-0 {
    height: 0px !important;
  }
}
