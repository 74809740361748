@charset "UTF-8";
main {
  display: block;
}

[hidden] {
  display: none !important;
}

button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

input, textarea, select {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  appearance: button;
}

/*
$verde-hover:#037730 !default;
$azul-hover:#0C6098 !default;

// secundarios
$amarillo: #F7D500 !default;

$verde-50:  #80CA9C !default;
$azul-40:  #99C5E3 !default;
$azul-10:  #F0F8FE !default;
// escala de grises
$gris-10: #F4F4F4 !default;
$gris-15: #EFF1F4 !default;
$gris-20: #EAEAEA !default;
$gris-40: #E2E2E3 !default;
$gris-50: #ACADAC !default;
$gris-80: #8f8f8f !default;

// validacion

$verde-10: #E5F4EB !default;
$verde-50: #80CA9C !default;
$amarillo-10: #FEF9D9 !default;
$amarillo-50: #FBEA80 !default;
 */
@font-face {
  font-family: "Nunito";
  src: url(~src/assets/fonts/Nunito-Black.woff2) format("woff2"), url(~src/assets/fonts/Nunito-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url(~src/assets/fonts/Nunito-Bold.woff2) format("woff2"), url(~src/assets/fonts/Nunito-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "overpassbold";
  src: url(~src/assets/fonts/overpass-bold-webfont.woff2) format("woff2"), url(~src/assets/fonts/overpass-bold-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "overpasslight";
  src: url(~src/assets/fonts/overpass-light-webfont.woff2) format("woff2"), url(~src/assets/fonts/overpass-light-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "overpassregular";
  src: url(~src/assets/fonts/overpass-regular-webfont.woff2) format("woff2"), url(~src/assets/fonts/overpass-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
:root {
  --bs--font-size-titulo-xl: clamp(1.5rem, 3vw + 0.2rem, 2rem);
  --bs--font-size-titulo-l: clamp(1.3rem, 3vw + 0.2rem, 1.7rem);
  --bs--font-size-titulo-m: clamp(1.2rem, 3vw + 0.2rem, 1.5rem);
  --bs--font-size-titulo-label: 1.5rem;
  --bs--font-size-placeholder: 1.5rem;
  --bs--font-size-titulo-publicidad-h1: clamp(2rem, 3vw + 0.2rem, 3.6rem);
  --bs--font-size-titulo-publicidad-h2: clamp(1.8rem, 3vw + 0.2rem, 2rem);
  --bs--font-size-titulo-publicidad-h3: clamp(1.6rem, 3vw + 0.2rem, 1.8rem);
  --bs--font-size-label-checkbox: 1.5rem;
  --bs--font-size-p: 1.5rem;
  --bs--font-size-caption: 1.3rem;
  --bs--font-size-menu: 1.5rem;
  --bs--font-size-h1: clamp(2.2rem, 3vw + 0.2rem, 4rem);
  --bs--font-size-h1-especial: clamp(2.2rem, 4vw, 3rem);
  --bs--font-size-h1-especial-span: clamp(3rem, 4vw, 5rem);
  --bs--font-size-h2: clamp(1.8rem, 2vw + 0.2rem, 3rem);
  --bs--font-size-h3: clamp(1.6rem, 2vw + 0.2rem, 2rem);
  --bs--font-size-h4: 1.8rem;
  --bs--font-size-h5: 1.5rem;
  --bs--font-size-button: 1.5rem;
  --bs--color-principal-azul: #006fb9;
  --bs--color-comercial-amarillo: #f9b316;
  --bs--color-principal-verde: #00953a;
  --bs--color-principal-blanco: #ffffff;
  --bs--color-principal-negro: #000000;
  --bs--color-principal-naranja: #ff9015;
  --bs--color-secundario-naranja-10: #FFF4E8;
  --bs--color-secundario-naranja-20: #FFE9D0;
  --bs--color-secundario-naranja-50: #FFC78A;
  --bs--color-secundario-naranja-90: #FD9B2D;
  --bs--color-secundario-naranja-hover: #E06308;
  --bs--color-secundario-naranja-dark: #673906;
  --bs--color-secundario-negro-hover: #8f8f8f;
  --bs--color-secundario-blanco-hover: #EAEAEA;
  --bs--color-grises-gris-5: #fafafa;
  --bs--color-grises-gris-10: #F4F4F4;
  --bs--color-grises-gris-15: #EFF1F4;
  --bs--color-grises-gris-20: #EAEAEA;
  --bs--color-grises-gris-40: #E2E2E3;
  --bs--color-grises-gris-50: #ACADAC;
  --bs--color-grises-gris-80: #8f8f8f;
  --bs--color-secundario-rojo: #EF3742;
  --bs--color-secundario-rojo-hover: #B91B24;
  --bs--color-validacion-rojo-10: #FFE7E9;
  --bs--color-validacion-rojo-50: #F79BA0;
}

h1.z-titulo,
p.z-titulo.h1 {
  font-family: "overpassbold", sans-serif;
  font-size: var(--bs--font-size-h1);
  color: var(--bs--color-principal-negro);
}
h1.z-titulo.z-contenido,
p.z-titulo.h1.z-contenido {
  font-size: var(--bs--font-size-titulo-xl);
  text-transform: uppercase;
}
h1.z-titulo.z-publicidad,
p.z-titulo.h1.z-publicidad {
  font-size: var(--bs--font-size-titulo-publicidad-h1);
  font-weight: 900;
  font-family: "Nunito", sans-serif;
  line-height: 1.3;
}
h1.z-titulo.z-especial,
p.z-titulo.h1.z-especial {
  font-size: var(--bs--font-size-h1-especial);
}
h1.z-titulo.z-especial span,
p.z-titulo.h1.z-especial span {
  display: block;
  font-size: var(--bs--font-size-h1-especial-span);
}

h2.z-titulo,
p.z-titulo.h2 {
  font-size: var(--bs--font-size-h2);
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-naranja);
  line-height: 2rem;
}
@media only screen and (min-width : 600px) {
  h2.z-titulo,
p.z-titulo.h2 {
    line-height: 3.4rem;
  }
}
h2.z-titulo.z-contenido,
p.z-titulo.h2.z-contenido {
  font-size: var(--bs--font-size-titulo-l);
  text-transform: uppercase;
}
h2.z-titulo.z-contenidostrong,
p.z-titulo.h2.z-contenidostrong {
  font-weight: 900;
}
h2.z-titulo.z-publicidad,
p.z-titulo.h2.z-publicidad {
  font-size: var(--bs--font-size-titulo-publicidad-h2);
  line-height: 1.3;
  font-family: "Nunito", sans-serif;
}

h3.z-titulo,
p.z-titulo.h3 {
  font-size: var(--bs--font-size-h3);
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-negro);
}
h3.z-titulo.z-contenido,
p.z-titulo.h3.z-contenido {
  font-size: var(--bs--font-size-titulo-m);
  text-transform: uppercase;
  color: var(--bs--color-principal-azul);
}
h3.z-titulo.z-contenido.z-gris-80,
p.z-titulo.h3.z-contenido.z-gris-80 {
  color: var(--bs--color-grises-gris-80);
}
h3.z-titulo.z-contenido.z-negro,
p.z-titulo.h3.z-contenido.z-negro {
  color: var(--bs--color-principal-negro);
}
h3.z-titulo.z-publicidad,
p.z-titulo.h3.z-publicidad {
  font-size: var(--bs--font-size-titulo-publicidad-h3);
  font-weight: 900;
  color: var(--bs--color-principal-naranja);
  font-family: "Nunito", sans-serif;
  line-height: 1.3;
}

h4.z-titulo,
p.z-titulo.h4 {
  font-family: "overpassregular", sans-serif;
  font-size: var(--bs--font-size-h4);
  color: var(--bs--color-principal-negro);
}

h5.z-titulo,
p.z-titulo.h5 {
  font-family: "overpassbold", sans-serif;
  font-size: var(--bs--font-size-h5);
  color: var(--bs--color-principal-negro);
}

p.z-label,
.z-label {
  color: var(--bs--color-principal-negro);
  font-size: 1.5rem;
  font-family: "overpassbold", sans-serif;
  line-height: 3rem;
}
p.z-label.simple,
.z-label.simple {
  font-family: "overpassregular", sans-serif;
}
p.z-label[disabled],
.z-label[disabled] {
  color: var(--bs--color-grises-gris-50);
}
p.z-label[disabled] ~ .z-caption,
.z-label[disabled] ~ .z-caption {
  color: var(--bs--color-grises-gris-50);
}

.z-optional {
  font-family: "overpassregular", sans-serif;
  font-size: 1.3rem;
  color: var(--bs--color-grises-gris-80);
  margin-left: 4px;
}

.z-required {
  color: var(--bs--color-secundario-rojo);
}

h5.z-titulo,
p.z-titulo.h1,
h4.z-titulo,
p.z-titulo.h2,
h3.z-titulo,
p.z-titulo.h3,
h2.z-titulo,
p.z-titulo.h4,
h1.z-titulo,
p.z-titulo.h5,
p,
small {
  line-height: 1.2;
}
h5.z-titulo.z-center,
p.z-titulo.h1.z-center,
h4.z-titulo.z-center,
p.z-titulo.h2.z-center,
h3.z-titulo.z-center,
p.z-titulo.h3.z-center,
h2.z-titulo.z-center,
p.z-titulo.h4.z-center,
h1.z-titulo.z-center,
p.z-titulo.h5.z-center,
p.z-center,
small.z-center {
  text-align: center !important;
}
@media only screen and (min-width : 1000px) {
  h5.z-titulo,
p.z-titulo.h1,
h4.z-titulo,
p.z-titulo.h2,
h3.z-titulo,
p.z-titulo.h3,
h2.z-titulo,
p.z-titulo.h4,
h1.z-titulo,
p.z-titulo.h5,
p,
small {
    line-height: 1.6;
  }
}
h5.z-titulo.z-stroke,
p.z-titulo.h1.z-stroke,
h4.z-titulo.z-stroke,
p.z-titulo.h2.z-stroke,
h3.z-titulo.z-stroke,
p.z-titulo.h3.z-stroke,
h2.z-titulo.z-stroke,
p.z-titulo.h4.z-stroke,
h1.z-titulo.z-stroke,
p.z-titulo.h5.z-stroke,
p.z-stroke,
small.z-stroke {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  -webkit-text-decoration: line-through;
  -webkit-text-decoration-thickness: 2px;
}
h5.z-titulo.z-normal,
p.z-titulo.h1.z-normal,
h4.z-titulo.z-normal,
p.z-titulo.h2.z-normal,
h3.z-titulo.z-normal,
p.z-titulo.h3.z-normal,
h2.z-titulo.z-normal,
p.z-titulo.h4.z-normal,
h1.z-titulo.z-normal,
p.z-titulo.h5.z-normal,
p.z-normal,
small.z-normal {
  font-family: "overpassregular", sans-serif;
}
h5.z-titulo.z-negrita,
p.z-titulo.h1.z-negrita,
h4.z-titulo.z-negrita,
p.z-titulo.h2.z-negrita,
h3.z-titulo.z-negrita,
p.z-titulo.h3.z-negrita,
h2.z-titulo.z-negrita,
p.z-titulo.h4.z-negrita,
h1.z-titulo.z-negrita,
p.z-titulo.h5.z-negrita,
p.z-negrita,
small.z-negrita {
  font-family: "overpassbold", sans-serif;
}
h5.z-titulo.z-gris-50, h5.z-titulo.gris-50,
p.z-titulo.h1.z-gris-50,
p.z-titulo.h1.gris-50,
h4.z-titulo.z-gris-50,
h4.z-titulo.gris-50,
p.z-titulo.h2.z-gris-50,
p.z-titulo.h2.gris-50,
h3.z-titulo.z-gris-50,
h3.z-titulo.gris-50,
p.z-titulo.h3.z-gris-50,
p.z-titulo.h3.gris-50,
h2.z-titulo.z-gris-50,
h2.z-titulo.gris-50,
p.z-titulo.h4.z-gris-50,
p.z-titulo.h4.gris-50,
h1.z-titulo.z-gris-50,
h1.z-titulo.gris-50,
p.z-titulo.h5.z-gris-50,
p.z-titulo.h5.gris-50,
p.z-gris-50,
p.gris-50,
small.z-gris-50,
small.gris-50 {
  color: var(--bs--color-grises-gris-50);
}
h5.z-titulo.z-gris-80, h5.z-titulo.gris-80,
p.z-titulo.h1.z-gris-80,
p.z-titulo.h1.gris-80,
h4.z-titulo.z-gris-80,
h4.z-titulo.gris-80,
p.z-titulo.h2.z-gris-80,
p.z-titulo.h2.gris-80,
h3.z-titulo.z-gris-80,
h3.z-titulo.gris-80,
p.z-titulo.h3.z-gris-80,
p.z-titulo.h3.gris-80,
h2.z-titulo.z-gris-80,
h2.z-titulo.gris-80,
p.z-titulo.h4.z-gris-80,
p.z-titulo.h4.gris-80,
h1.z-titulo.z-gris-80,
h1.z-titulo.gris-80,
p.z-titulo.h5.z-gris-80,
p.z-titulo.h5.gris-80,
p.z-gris-80,
p.gris-80,
small.z-gris-80,
small.gris-80 {
  color: var(--bs--color-grises-gris-80);
}
h5.z-titulo.z-negro, h5.z-titulo.negro,
p.z-titulo.h1.z-negro,
p.z-titulo.h1.negro,
h4.z-titulo.z-negro,
h4.z-titulo.negro,
p.z-titulo.h2.z-negro,
p.z-titulo.h2.negro,
h3.z-titulo.z-negro,
h3.z-titulo.negro,
p.z-titulo.h3.z-negro,
p.z-titulo.h3.negro,
h2.z-titulo.z-negro,
h2.z-titulo.negro,
p.z-titulo.h4.z-negro,
p.z-titulo.h4.negro,
h1.z-titulo.z-negro,
h1.z-titulo.negro,
p.z-titulo.h5.z-negro,
p.z-titulo.h5.negro,
p.z-negro,
p.negro,
small.z-negro,
small.negro {
  color: var(--bs--color-principal-negro);
}
h5.z-titulo.z-naranja, h5.z-titulo.naranja,
p.z-titulo.h1.z-naranja,
p.z-titulo.h1.naranja,
h4.z-titulo.z-naranja,
h4.z-titulo.naranja,
p.z-titulo.h2.z-naranja,
p.z-titulo.h2.naranja,
h3.z-titulo.z-naranja,
h3.z-titulo.naranja,
p.z-titulo.h3.z-naranja,
p.z-titulo.h3.naranja,
h2.z-titulo.z-naranja,
h2.z-titulo.naranja,
p.z-titulo.h4.z-naranja,
p.z-titulo.h4.naranja,
h1.z-titulo.z-naranja,
h1.z-titulo.naranja,
p.z-titulo.h5.z-naranja,
p.z-titulo.h5.naranja,
p.z-naranja,
p.naranja,
small.z-naranja,
small.naranja {
  color: var(--bs--color-principal-naranja);
}
h5.z-titulo.z-blanco, h5.z-titulo.blanco,
p.z-titulo.h1.z-blanco,
p.z-titulo.h1.blanco,
h4.z-titulo.z-blanco,
h4.z-titulo.blanco,
p.z-titulo.h2.z-blanco,
p.z-titulo.h2.blanco,
h3.z-titulo.z-blanco,
h3.z-titulo.blanco,
p.z-titulo.h3.z-blanco,
p.z-titulo.h3.blanco,
h2.z-titulo.z-blanco,
h2.z-titulo.blanco,
p.z-titulo.h4.z-blanco,
p.z-titulo.h4.blanco,
h1.z-titulo.z-blanco,
h1.z-titulo.blanco,
p.z-titulo.h5.z-blanco,
p.z-titulo.h5.blanco,
p.z-blanco,
p.blanco,
small.z-blanco,
small.blanco {
  color: var(--bs--color-principal-blanco);
}
h5.z-titulo.z-up,
p.z-titulo.h1.z-up,
h4.z-titulo.z-up,
p.z-titulo.h2.z-up,
h3.z-titulo.z-up,
p.z-titulo.h3.z-up,
h2.z-titulo.z-up,
p.z-titulo.h4.z-up,
h1.z-titulo.z-up,
p.z-titulo.h5.z-up,
p.z-up,
small.z-up {
  text-transform: uppercase;
}
h5.z-titulo strong,
p.z-titulo.h1 strong,
h4.z-titulo strong,
p.z-titulo.h2 strong,
h3.z-titulo strong,
p.z-titulo.h3 strong,
h2.z-titulo strong,
p.z-titulo.h4 strong,
h1.z-titulo strong,
p.z-titulo.h5 strong,
p strong,
small strong {
  font-family: "overpassbold", sans-serif;
}

p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo) {
  font-size: var(--bs--font-size-p);
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  line-height: 2.4rem;
}
p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo) a {
  cursor: pointer;
  color: var(--bs--color-principal-naranja);
  text-decoration: underline;
  font-family: "overpassbold", sans-serif;
}
p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo) a:hover {
  color: var(--bs--color-secundario-naranja-hover);
}
p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo) strong {
  font-family: "overpassbold", sans-serif;
}
p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo) strong.naranja {
  color: var(--bs--color-principal-naranja);
}
p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo).z-caption {
  font-size: var(--bs--font-size-caption);
  color: var(--bs--color-grises-gris-50);
  line-height: 1.5rem;
}
p:not(h1):not(h2):not(h3):not(h4):not(h5):not(.z-titulo).z-caption.z-error {
  color: var(--bs--color-secundario-rojo);
}

p small,
small {
  font-size: var(--bs--font-size-caption);
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  line-height: 0.8rem !important;
}
p small strong,
small strong {
  font-family: "overpassbold", sans-serif;
}
p small a,
small a {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-naranja);
  text-decoration: underline;
}

div.z-lista {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
div.z-lista span {
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
  color: var(--bs--color-principal-blanco);
  font-size: 1.3rem;
  margin-right: 12px;
  background-color: var(--bs--color-principal-naranja);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-top: 2px;
}
div.z-lista span.check {
  background: url(~src/assets/img/svg/checkbox_naranja_check.svg) center center no-repeat;
}
div.z-lista p {
  flex: 1;
}

ul.z-lista li,
ol.z-lista li {
  list-style: none;
  font-size: var(--bs--font-size-p);
  color: var(--bs--color-principal-negro);
  margin-bottom: 12px;
  padding-left: 30px;
}

ul.z-lista li {
  background: url(~src/assets/img/svg/icon-arrow-next.svg) left 1px no-repeat;
  background-size: auto 20px;
  min-height: 24px;
  line-height: 1.5;
}

ol.z-lista {
  counter-reset: element-counter;
}
ol.z-lista li {
  position: relative;
  counter-increment: element-counter;
}
ol.z-lista li::before {
  content: counter(element-counter);
  height: 24px;
  width: 24px;
  display: grid;
  place-items: center;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  top: 1px;
  color: var(--bs--color-principal-blanco);
  background-color: var(--bs--color-principal-naranja);
  font-family: "overpassbold", sans-serif;
  font-size: 1.2rem;
}

.z-contenedor {
  margin: 0 auto;
  display: block;
  width: 100%;
  padding: 0 16px;
  max-width: 1200px;
}
.z-contenedor.extended {
  max-width: clamp(1200px,100%,1600px);
}
.z-contenedor.full {
  max-width: calc(100% - 32px) !important;
}

.z-main-container {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  overflow: hidden auto;
}
.z-main-container::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.z-main-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.z-main-container::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-principal-negro);
  cursor: pointer;
}

.z-background {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  background-size: cover;
  background-color: var(--bs--color-grises-gris-20);
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden auto;
}
.z-background::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.z-background::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.z-background::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-principal-negro);
  cursor: pointer;
}

.z-wrapper {
  margin: 0 auto;
  display: block;
  padding: 0 16px;
  max-width: 1200px;
  width: 100%;
}
.z-wrapper.extended {
  max-width: calc($container-size + 380px);
}
.z-wrapper .dummy {
  flex: 1;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-tb {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-margin-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/*!
 * Bootstrap Grid v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
header.z-header.simple, header.z-header.comercial, header.z-header.privado {
  height: 54px;
  width: 100%;
  background-color: var(--bs--color-principal-blanco);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--bs--color-grises-gris-10);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  z-index: 9999999;
}
@media only screen and (min-width : 600px) {
  header.z-header.simple, header.z-header.comercial, header.z-header.privado {
    height: 60px;
  }
}
@media only screen and (min-width : 1000px) {
  header.z-header.simple, header.z-header.comercial, header.z-header.privado {
    height: 70px;
  }
}
header.z-header.simple a.menu, header.z-header.comercial a.menu, header.z-header.privado a.menu {
  width: 32px;
  height: 54px;
  display: inline-block;
  background: url(~src/assets/img/svg/icon-ui-nav-menu-menu-simple.svg) center center no-repeat;
  background-size: auto 40px;
  opacity: 0.3;
  cursor: pointer;
}
header.z-header.simple a.menu:hover, header.z-header.comercial a.menu:hover, header.z-header.privado a.menu:hover {
  opacity: 0.6;
}
@media only screen and (min-width : 600px) {
  header.z-header.simple a.menu, header.z-header.comercial a.menu, header.z-header.privado a.menu {
    background-size: auto 32px;
    height: 60px;
    width: 60px;
    background: url(~src/assets/img/svg/icon-ui-nav-menu-menu-simple.svg) left center no-repeat;
  }
}
@media only screen and (min-width : 1280px) {
  header.z-header.simple a.menu, header.z-header.comercial a.menu, header.z-header.privado a.menu {
    background-position: center center;
    height: 70px;
    width: 80px;
    background-size: auto 48px;
  }
}
header.z-header.simple a.logotipo figure, header.z-header.comercial a.logotipo figure, header.z-header.privado a.logotipo figure {
  margin: 0;
  padding: 0;
}
header.z-header.simple a.logotipo figure img, header.z-header.comercial a.logotipo figure img, header.z-header.privado a.logotipo figure img {
  height: 32px;
  width: auto;
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  -ms-transition: height 0.2s ease-in-out;
  -o-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}
@media only screen and (min-width : 600px) {
  header.z-header.simple a.logotipo figure img, header.z-header.comercial a.logotipo figure img, header.z-header.privado a.logotipo figure img {
    height: 36px;
  }
}
@media only screen and (min-width : 1000px) {
  header.z-header.simple a.logotipo figure img, header.z-header.comercial a.logotipo figure img, header.z-header.privado a.logotipo figure img {
    height: 48px;
  }
}
header.z-header.simple ul.z-header-toolbar, header.z-header.comercial ul.z-header-toolbar, header.z-header.privado ul.z-header-toolbar {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  flex: 1;
}
header.z-header.simple ul.z-header-toolbar li, header.z-header.comercial ul.z-header-toolbar li, header.z-header.privado ul.z-header-toolbar li {
  margin-left: 10px;
  list-style: none;
}
header.z-header.simple ul.z-header-toolbar li.no-margin, header.z-header.comercial ul.z-header-toolbar li.no-margin, header.z-header.privado ul.z-header-toolbar li.no-margin {
  margin-left: 0;
}
header.z-header.simple ul.z-header-toolbar li a, header.z-header.comercial ul.z-header-toolbar li a, header.z-header.privado ul.z-header-toolbar li a {
  text-decoration: none;
}
header.z-header.simple .z-wrapper, header.z-header.comercial .z-wrapper, header.z-header.privado .z-wrapper {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  padding: 0 8px;
}
@media only screen and (min-width : 600px) {
  header.z-header.simple .z-wrapper, header.z-header.comercial .z-wrapper, header.z-header.privado .z-wrapper {
    padding: 0 16px;
  }
}
header.z-header.simple .z-wrapper a.logotipo, header.z-header.comercial .z-wrapper a.logotipo, header.z-header.privado .z-wrapper a.logotipo {
  flex: 0 0 90px;
}
@media only screen and (min-width : 600px) {
  header.z-header.simple .z-wrapper a.logotipo, header.z-header.comercial .z-wrapper a.logotipo, header.z-header.privado .z-wrapper a.logotipo {
    flex: 1 0 130px;
  }
}
header.z-header.simple .z-wrapper .z-header-toolbar, header.z-header.comercial .z-wrapper .z-header-toolbar, header.z-header.privado .z-wrapper .z-header-toolbar {
  flex: 1;
}
header.z-header.simple .z-btn, header.z-header.comercial .z-btn, header.z-header.privado .z-btn {
  width: auto !important;
}
header.z-header.privado .z-wrapper {
  justify-content: flex-start;
  align-items: center;
}
header.z-header.privado .z-wrapper a.menu {
  margin-right: 8px;
}
header.z-header.privado .z-wrapper a.logotipo {
  max-width: 200px;
}
header.z-header.privado .z-wrapper section {
  flex: 1;
}
header.z-header.privado .z-wrapper section > ul > li {
  position: relative;
  height: 40px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li {
    height: 50px;
  }
}
@media only screen and (min-width : 1000px) {
  header.z-header.privado .z-wrapper section > ul > li {
    height: 64px;
  }
}
header.z-header.privado .z-wrapper section > ul > li > ul {
  display: none;
  position: fixed;
  top: 46px;
  right: 0;
  min-width: max-content;
  z-index: 3;
  width: 100%;
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li > ul {
    min-width: max-content;
    width: auto;
    top: 101%;
    position: absolute;
  }
}
header.z-header.privado .z-wrapper section > ul > li[fix] > ul {
  min-width: 300px;
}
header.z-header.privado .z-wrapper section > ul {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
header.z-header.privado .z-wrapper section > ul > li.sep {
  width: 1px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin: 0 8px;
  background-color: var(--bs--color-grises-gris-20);
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li.sep {
    margin: 0 16px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) {
  list-style: none;
  min-width: 24px;
  min-height: 24px;
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) {
    min-width: 36px;
    min-height: 36px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy]:hover {
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -ms-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris]:hover {
  border-left: 1px solid var(--bs--color-grises-gris-20);
  border-right: 1px solid var(--bs--color-grises-gris-20);
  background-color: var(--bs--color-grises-gris-10);
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris] > ul {
  background-color: var(--bs--color-grises-gris-10);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris] > ul > li {
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris] > ul > li:first-child {
  border: none;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris] > ul > li a span {
  color: var(--bs--color-grises-gris-80);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris] > ul > li a figure svg {
  fill: var(--bs--color-grises-gris-80);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris] > ul > li a:hover span {
  color: var(--bs--color-principal-negro);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][gris] > ul > li a:hover figure svg {
  fill: var(--bs--color-principal-negro);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja]:hover {
  border-left: 1px solid var(--bs--color-secundario-naranja-50);
  border-right: 1px solid var(--bs--color-secundario-naranja-50);
  border-bottom: 1px solid var(--bs--color-secundario-naranja-50);
  background-color: var(--bs--color-principal-naranja);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja]:hover > .user-id,
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja]:hover > a {
  color: var(--bs--color-principal-blanco);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja]:hover > .user-id span,
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja]:hover > a span {
  color: var(--bs--color-principal-blanco);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja]:hover > .user-id figure img,
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja]:hover > a figure img {
  filter: grayscale(100%) brightness(500%);
  -webkit-filter: grayscale(100%) brightness(500%);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja] > ul {
  background-color: var(--bs--color-principal-naranja);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja] > ul > li {
  border-top: 1px solid var(--bs--color-secundario-naranja-50);
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja] > ul > li:first-child {
  border: none;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja] > ul > li a span {
  color: var(--bs--color-principal-blanco) !important;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja] > ul > li a:hover span {
  color: var(--bs--color-secundario-naranja-50) !important;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja] > ul > li a:hover figure svg {
  fill: var(--bs--color-secundario-naranja-50) !important;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep)[deploy][naranja] > ul > li a figure svg {
  fill: var(--bs--color-principal-blanco) !important;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover > a,
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover > .user-id {
  filter: 100%;
  -webkit-filter: 100%;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul {
  display: block;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul {
    -webkit-border-radius: 10px 0 10px 10px;
    -moz-border-radius: 10px 0 10px 10px;
    -ms-border-radius: 10px 0 10px 10px;
    border-radius: 10px 0 10px 10px;
  }
  header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul:after {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    width: 100px;
    display: block;
    background-color: rgba(0, 0, 0, 0);
    height: 150%;
  }
  header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul:before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 100%;
    left: -50px;
    width: calc(100% + 100px);
    display: block;
    background-color: rgba(0, 0, 0, 0);
    height: 200px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul > li {
  z-index: 10;
  list-style: none;
  padding: 0 16px;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul > li a {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  text-decoration: none;
  height: 64px;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul > li a figure {
  margin: 0 0 0 16px;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul > li a figure[big] svg {
  height: 32px;
  width: auto;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul[invert] > li > a span {
  order: 2;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep):hover[deploy] > ul[invert] > li > a figure {
  order: 1;
  margin: 0 16px 0 0;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a,
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id {
  text-decoration: none;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a[push],
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id[push] {
  position: relative;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a[push]:after,
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id[push]:after {
  content: attr(push);
  position: absolute;
  display: block;
  font-size: 1.1rem;
  font-family: "overpassbold", sans-serif;
  padding: 0 5px;
  margin: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  top: -6px;
  right: 15%;
  background-color: var(--bs--color-secundario-rojo);
  color: var(--bs--color-principal-blanco);
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a,
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id {
    min-width: 36px;
    min-height: 36px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a figure,
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id figure {
  width: 24px;
  height: 24px;
  display: inline-block;
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a figure,
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id figure {
    width: 36px;
    height: 36px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a figure img,
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id figure img {
  width: auto;
  height: 100%;
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id {
  padding-left: 4px;
  padding-right: 4px;
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id {
    padding-left: 0px;
    padding-right: 0px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a {
  padding-left: 4px;
  padding-right: 4px;
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) > a {
    padding-left: 8px;
    padding-right: 8px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id figure {
    margin-right: 8px;
  }
}
header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id span {
  display: none;
}
@media only screen and (min-width : 1000px) {
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id span {
    flex: 1;
    text-align: right;
    font-size: 1.4rem;
    line-height: 1;
    margin-right: 8px;
    padding-left: 8px;
    display: inline-block;
  }
  header.z-header.privado .z-wrapper section > ul > li:not(.sep) > .user-id span strong {
    display: block;
    font-family: "overpassbold", sans-serif;
  }
}
header.z-header.comercial section {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
header.z-header.comercial section .z-btn {
  margin-left: 4px;
  height: 44px;
}
@media only screen and (min-width : 1000px) {
  header.z-header.comercial section .z-btn {
    margin-left: 6px;
    height: 48px;
  }
}
@media only screen and (min-width : 1280px) {
  header.z-header.comercial section .z-btn {
    margin-left: 10px;
    height: 48px;
  }
}

.z-header-separator {
  width: 10px;
  position: relative;
  height: 50px;
  display: none;
}
.z-header-separator:not(.ghost):after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 100%;
  display: block;
  content: "";
  background-color: var(--bs--color-grises-gris-20);
}
@media only screen and (min-width : 600px) {
  .z-header-separator:not(.ghost):after {
    display: block;
  }
}

.z-header-span {
  height: 60px;
  display: block;
  width: 100%;
}
@media only screen and (min-width : 600px) {
  .z-header-span {
    height: 70px;
  }
}
@media only screen and (min-width : 1000px) {
  .z-header-span {
    height: 80px;
  }
}

.z-separador {
  clear: both;
  float: none;
  width: 100%;
  display: block;
  height: 10px;
}
.z-separador.h-5 {
  height: 5px;
}
.z-separador.h-10 {
  height: 10px;
}
.z-separador.h-15 {
  height: 15px;
}
.z-separador.h-20 {
  height: 20px;
}
.z-separador.h-25 {
  height: 25px;
}
.z-separador.h-30 {
  height: 30px;
}
.z-separador.h-35 {
  height: 35px;
}
.z-separador.h-40 {
  height: 40px;
}
.z-separador.h-45 {
  height: 45px;
}
.z-separador.h-50 {
  height: 50px;
}
.z-separador.h-55 {
  height: 55px;
}
.z-separador.h-60 {
  height: 60px;
}
.z-separador.h-65 {
  height: 65px;
}
.z-separador.h-70 {
  height: 70px;
}
.z-separador.h-75 {
  height: 75px;
}
.z-separador.h-80 {
  height: 80px;
}
.z-separador.h-85 {
  height: 85px;
}
.z-separador.h-90 {
  height: 90px;
}
.z-separador.h-95 {
  height: 95px;
}
.z-separador.h-100 {
  height: 100px;
}
.z-separador.h-105 {
  height: 105px;
}
.z-separador.h-110 {
  height: 110px;
}
.z-separador.h-115 {
  height: 115px;
}
.z-separador.lineal {
  position: relative;
  margin: 2px 0;
}
.z-separador.lineal:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: var(--bs--color-grises-gris-40);
}
.z-separador.lineal.th-1:after {
  -webkit-border-radius: calc(1px/ 2);
  -moz-border-radius: calc(1px/ 2);
  -ms-border-radius: calc(1px/ 2);
  border-radius: calc(1px/ 2);
  height: 1px !important;
}
.z-separador.lineal.th-2:after {
  -webkit-border-radius: calc(2px/ 2);
  -moz-border-radius: calc(2px/ 2);
  -ms-border-radius: calc(2px/ 2);
  border-radius: calc(2px/ 2);
  height: 2px !important;
}
.z-separador.lineal.th-3:after {
  -webkit-border-radius: calc(3px/ 2);
  -moz-border-radius: calc(3px/ 2);
  -ms-border-radius: calc(3px/ 2);
  border-radius: calc(3px/ 2);
  height: 3px !important;
}
.z-separador.lineal.th-4:after {
  -webkit-border-radius: calc(4px/ 2);
  -moz-border-radius: calc(4px/ 2);
  -ms-border-radius: calc(4px/ 2);
  border-radius: calc(4px/ 2);
  height: 4px !important;
}
.z-separador.lineal.th-5:after {
  -webkit-border-radius: calc(5px/ 2);
  -moz-border-radius: calc(5px/ 2);
  -ms-border-radius: calc(5px/ 2);
  border-radius: calc(5px/ 2);
  height: 5px !important;
}
.z-separador.lineal.th-6:after {
  -webkit-border-radius: calc(6px/ 2);
  -moz-border-radius: calc(6px/ 2);
  -ms-border-radius: calc(6px/ 2);
  border-radius: calc(6px/ 2);
  height: 6px !important;
}
.z-separador.lineal.th-7:after {
  -webkit-border-radius: calc(7px/ 2);
  -moz-border-radius: calc(7px/ 2);
  -ms-border-radius: calc(7px/ 2);
  border-radius: calc(7px/ 2);
  height: 7px !important;
}
.z-separador.lineal.th-8:after {
  -webkit-border-radius: calc(8px/ 2);
  -moz-border-radius: calc(8px/ 2);
  -ms-border-radius: calc(8px/ 2);
  border-radius: calc(8px/ 2);
  height: 8px !important;
}
.z-separador.lineal.th-9:after {
  -webkit-border-radius: calc(9px/ 2);
  -moz-border-radius: calc(9px/ 2);
  -ms-border-radius: calc(9px/ 2);
  border-radius: calc(9px/ 2);
  height: 9px !important;
}
.z-separador.lineal.th-10:after {
  -webkit-border-radius: calc(10px/ 2);
  -moz-border-radius: calc(10px/ 2);
  -ms-border-radius: calc(10px/ 2);
  border-radius: calc(10px/ 2);
  height: 10px !important;
}
.z-separador.h-0 {
  height: 0px !important;
}

.z-bloque {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 50px;
  display: block;
  box-sizing: border-box;
  padding: 20px;
}
.z-bloque.mensaje > article {
  width: 100%;
  max-width: 400px;
}
.z-bloque.fotografia {
  padding: 0;
}
.z-bloque.no-padding {
  padding: 0;
}
.z-bloque.no-padding > article {
  padding: 20px;
}
@media only screen and (max-width : 500px) {
  .z-bloque.no-padding > article.no-mobile {
    padding: 8px 0;
  }
}
.z-bloque.no-padding > article.background.naranja {
  background-color: var(--bs--color-principal-naranja);
}
.z-bloque.no-padding > article.background.naranja-20 {
  background-color: var(--bs--color-secundario-naranja-20);
}
.z-selector .z-bloque {
  padding: 0 !important;
}
.z-bloque[disabled] {
  opacity: 0.3;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.z-bloque + .z-bloque {
  margin-top: 8px;
}
.z-bloque.h-0 {
  height: 0px;
}
.z-bloque.h-5 {
  height: 5px;
}
.z-bloque.h-10 {
  height: 10px;
}
.z-bloque.h-15 {
  height: 15px;
}
.z-bloque.h-20 {
  height: 20px;
}
.z-bloque.h-25 {
  height: 25px;
}
.z-bloque.h-30 {
  height: 30px;
}
.z-bloque.h-35 {
  height: 35px;
}
.z-bloque.h-40 {
  height: 40px;
}
.z-bloque.h-45 {
  height: 45px;
}
.z-bloque.h-50 {
  height: 50px;
}
.z-bloque.h-55 {
  height: 55px;
}
.z-bloque.h-60 {
  height: 60px;
}
.z-bloque.h-65 {
  height: 65px;
}
.z-bloque.h-70 {
  height: 70px;
}
.z-bloque.h-75 {
  height: 75px;
}
.z-bloque.h-80 {
  height: 80px;
}
.z-bloque.h-85 {
  height: 85px;
}
.z-bloque.h-90 {
  height: 90px;
}
.z-bloque.h-95 {
  height: 95px;
}
.z-bloque.h-100 {
  height: 100px;
}
.z-bloque.h-105 {
  height: 105px;
}
.z-bloque.h-110 {
  height: 110px;
}
.z-bloque.h-115 {
  height: 115px;
}
.z-bloque.h-120 {
  height: 120px;
}
.z-bloque.h-125 {
  height: 125px;
}
.z-bloque.h-130 {
  height: 130px;
}
.z-bloque.h-135 {
  height: 135px;
}
.z-bloque.h-140 {
  height: 140px;
}
.z-bloque.h-145 {
  height: 145px;
}
.z-bloque.h-150 {
  height: 150px;
}
.z-bloque.h-155 {
  height: 155px;
}
.z-bloque.h-160 {
  height: 160px;
}
.z-bloque.h-165 {
  height: 165px;
}
.z-bloque.h-170 {
  height: 170px;
}
.z-bloque.h-175 {
  height: 175px;
}
.z-bloque.h-180 {
  height: 180px;
}
.z-bloque.h-185 {
  height: 185px;
}
.z-bloque.h-190 {
  height: 190px;
}
.z-bloque.h-195 {
  height: 195px;
}
.z-bloque.h-200 {
  height: 200px;
}
.z-bloque.h-205 {
  height: 205px;
}
.z-bloque.h-210 {
  height: 210px;
}
.z-bloque.h-215 {
  height: 215px;
}
.z-bloque.h-220 {
  height: 220px;
}
.z-bloque.h-225 {
  height: 225px;
}
.z-bloque.h-230 {
  height: 230px;
}
.z-bloque.h-235 {
  height: 235px;
}
.z-bloque.h-240 {
  height: 240px;
}
.z-bloque.h-245 {
  height: 245px;
}
.z-bloque.h-250 {
  height: 250px;
}
.z-bloque.h-255 {
  height: 255px;
}
.z-bloque.h-260 {
  height: 260px;
}
.z-bloque.h-265 {
  height: 265px;
}
.z-bloque.h-270 {
  height: 270px;
}
.z-bloque.h-275 {
  height: 275px;
}
.z-bloque.h-280 {
  height: 280px;
}
.z-bloque.h-285 {
  height: 285px;
}
.z-bloque.h-290 {
  height: 290px;
}
.z-bloque.h-295 {
  height: 295px;
}
.z-bloque.h-300 {
  height: 300px;
}
.z-bloque.h-305 {
  height: 305px;
}
.z-bloque.h-310 {
  height: 310px;
}
.z-bloque.h-315 {
  height: 315px;
}
.z-bloque.h-320 {
  height: 320px;
}
.z-bloque.h-325 {
  height: 325px;
}
.z-bloque.h-330 {
  height: 330px;
}
.z-bloque.h-335 {
  height: 335px;
}
.z-bloque.h-340 {
  height: 340px;
}
.z-bloque.h-345 {
  height: 345px;
}
.z-bloque.h-350 {
  height: 350px;
}
.z-bloque.h-355 {
  height: 355px;
}
.z-bloque.h-360 {
  height: 360px;
}
.z-bloque.h-365 {
  height: 365px;
}
.z-bloque.h-370 {
  height: 370px;
}
.z-bloque.h-375 {
  height: 375px;
}
.z-bloque.h-380 {
  height: 380px;
}
.z-bloque.h-385 {
  height: 385px;
}
.z-bloque.h-390 {
  height: 390px;
}
.z-bloque.h-395 {
  height: 395px;
}
.z-bloque.h-400 {
  height: 400px;
}
.z-bloque.h-405 {
  height: 405px;
}
.z-bloque.h-410 {
  height: 410px;
}
.z-bloque.h-415 {
  height: 415px;
}
.z-bloque.h-420 {
  height: 420px;
}
.z-bloque.h-425 {
  height: 425px;
}
.z-bloque.h-430 {
  height: 430px;
}
.z-bloque.h-435 {
  height: 435px;
}
.z-bloque.h-440 {
  height: 440px;
}
.z-bloque.h-445 {
  height: 445px;
}
.z-bloque.h-450 {
  height: 450px;
}
.z-bloque.h-455 {
  height: 455px;
}
.z-bloque.h-460 {
  height: 460px;
}
.z-bloque.h-465 {
  height: 465px;
}
.z-bloque.h-470 {
  height: 470px;
}
.z-bloque.h-475 {
  height: 475px;
}
.z-bloque.h-480 {
  height: 480px;
}
.z-bloque.h-485 {
  height: 485px;
}
.z-bloque.h-490 {
  height: 490px;
}
.z-bloque.h-495 {
  height: 495px;
}
.z-bloque.h-500 {
  height: 500px;
}
.z-bloque.h-505 {
  height: 505px;
}
.z-bloque.h-510 {
  height: 510px;
}
.z-bloque.h-515 {
  height: 515px;
}
.z-bloque.h-520 {
  height: 520px;
}
.z-bloque.h-525 {
  height: 525px;
}
.z-bloque.h-530 {
  height: 530px;
}
.z-bloque.h-535 {
  height: 535px;
}
.z-bloque.h-540 {
  height: 540px;
}
.z-bloque.h-545 {
  height: 545px;
}
.z-bloque.h-550 {
  height: 550px;
}
.z-bloque.h-555 {
  height: 555px;
}
.z-bloque.h-560 {
  height: 560px;
}
.z-bloque.h-565 {
  height: 565px;
}
.z-bloque.h-570 {
  height: 570px;
}
.z-bloque.h-575 {
  height: 575px;
}
.z-bloque.h-580 {
  height: 580px;
}
.z-bloque.h-585 {
  height: 585px;
}
.z-bloque.h-590 {
  height: 590px;
}
.z-bloque.h-595 {
  height: 595px;
}
.z-bloque.h-600 {
  height: 600px;
}
.z-bloque.h-605 {
  height: 605px;
}
.z-bloque.h-610 {
  height: 610px;
}
.z-bloque.h-615 {
  height: 615px;
}
.z-bloque.h-620 {
  height: 620px;
}
.z-bloque.h-625 {
  height: 625px;
}
.z-bloque.h-630 {
  height: 630px;
}
.z-bloque.h-635 {
  height: 635px;
}
.z-bloque.h-640 {
  height: 640px;
}
.z-bloque.h-645 {
  height: 645px;
}
.z-bloque.h-650 {
  height: 650px;
}
.z-bloque.h-655 {
  height: 655px;
}
.z-bloque.h-660 {
  height: 660px;
}
.z-bloque.h-665 {
  height: 665px;
}
.z-bloque.h-670 {
  height: 670px;
}
.z-bloque.h-675 {
  height: 675px;
}
.z-bloque.h-680 {
  height: 680px;
}
.z-bloque.h-685 {
  height: 685px;
}
.z-bloque.h-690 {
  height: 690px;
}
.z-bloque.h-695 {
  height: 695px;
}
.z-bloque.h-700 {
  height: 700px;
}
.z-bloque.h-705 {
  height: 705px;
}
.z-bloque.h-710 {
  height: 710px;
}
.z-bloque.h-715 {
  height: 715px;
}
.z-bloque.h-720 {
  height: 720px;
}
.z-bloque.h-725 {
  height: 725px;
}
.z-bloque.h-730 {
  height: 730px;
}
.z-bloque.h-735 {
  height: 735px;
}
.z-bloque.h-740 {
  height: 740px;
}
.z-bloque.h-745 {
  height: 745px;
}
.z-bloque.h-750 {
  height: 750px;
}
.z-bloque.h-755 {
  height: 755px;
}
.z-bloque.h-760 {
  height: 760px;
}
.z-bloque.h-765 {
  height: 765px;
}
.z-bloque.h-770 {
  height: 770px;
}
.z-bloque.h-775 {
  height: 775px;
}
.z-bloque.h-780 {
  height: 780px;
}
.z-bloque.h-785 {
  height: 785px;
}
.z-bloque.h-790 {
  height: 790px;
}
.z-bloque.h-795 {
  height: 795px;
}
.z-bloque.h-800 {
  height: 800px;
}
.z-bloque.h-805 {
  height: 805px;
}
.z-bloque.h-810 {
  height: 810px;
}
.z-bloque.h-815 {
  height: 815px;
}
.z-bloque.h-820 {
  height: 820px;
}
.z-bloque.h-825 {
  height: 825px;
}
.z-bloque.h-830 {
  height: 830px;
}
.z-bloque.h-835 {
  height: 835px;
}
.z-bloque.h-840 {
  height: 840px;
}
.z-bloque.h-845 {
  height: 845px;
}
.z-bloque.h-850 {
  height: 850px;
}
.z-bloque.h-855 {
  height: 855px;
}
.z-bloque.h-860 {
  height: 860px;
}
.z-bloque.h-865 {
  height: 865px;
}
.z-bloque.h-870 {
  height: 870px;
}
.z-bloque.h-875 {
  height: 875px;
}
.z-bloque.h-880 {
  height: 880px;
}
.z-bloque.h-885 {
  height: 885px;
}
.z-bloque.h-890 {
  height: 890px;
}
.z-bloque.h-895 {
  height: 895px;
}
.z-bloque.h-900 {
  height: 900px;
}
.z-bloque.h-905 {
  height: 905px;
}
.z-bloque.h-910 {
  height: 910px;
}
.z-bloque.h-915 {
  height: 915px;
}
.z-bloque.h-920 {
  height: 920px;
}
.z-bloque.h-925 {
  height: 925px;
}
.z-bloque.h-930 {
  height: 930px;
}
.z-bloque.h-935 {
  height: 935px;
}
.z-bloque.h-940 {
  height: 940px;
}
.z-bloque.h-945 {
  height: 945px;
}
.z-bloque.h-950 {
  height: 950px;
}
.z-bloque.h-955 {
  height: 955px;
}
.z-bloque.h-960 {
  height: 960px;
}
.z-bloque.h-965 {
  height: 965px;
}
.z-bloque.h-970 {
  height: 970px;
}
.z-bloque.h-975 {
  height: 975px;
}
.z-bloque.h-980 {
  height: 980px;
}
.z-bloque.h-985 {
  height: 985px;
}
.z-bloque.h-990 {
  height: 990px;
}
.z-bloque.h-995 {
  height: 995px;
}
.z-bloque.h-1000 {
  height: 1000px;
}
.z-bloque.max-w-0p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-0p {
    max-width: 0%;
  }
}
.z-bloque.max-w-10p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-10p {
    max-width: 10%;
  }
}
.z-bloque.max-w-20p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-20p {
    max-width: 20%;
  }
}
.z-bloque.max-w-30p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-30p {
    max-width: 30%;
  }
}
.z-bloque.max-w-40p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-40p {
    max-width: 40%;
  }
}
.z-bloque.max-w-50p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-50p {
    max-width: 50%;
  }
}
.z-bloque.max-w-60p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-60p {
    max-width: 60%;
  }
}
.z-bloque.max-w-70p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-70p {
    max-width: 70%;
  }
}
.z-bloque.max-w-80p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-80p {
    max-width: 80%;
  }
}
.z-bloque.max-w-90p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-90p {
    max-width: 90%;
  }
}
.z-bloque.max-w-100p {
  max-width: 100%;
}
@media only screen and (min-width : 1000px) {
  .z-bloque.max-w-100p {
    max-width: 100%;
  }
}
.z-bloque.min-h-0 {
  min-height: 0px;
}
.z-bloque.min-h-5 {
  min-height: 5px;
}
.z-bloque.min-h-10 {
  min-height: 10px;
}
.z-bloque.min-h-15 {
  min-height: 15px;
}
.z-bloque.min-h-20 {
  min-height: 20px;
}
.z-bloque.min-h-25 {
  min-height: 25px;
}
.z-bloque.min-h-30 {
  min-height: 30px;
}
.z-bloque.min-h-35 {
  min-height: 35px;
}
.z-bloque.min-h-40 {
  min-height: 40px;
}
.z-bloque.min-h-45 {
  min-height: 45px;
}
.z-bloque.min-h-50 {
  min-height: 50px;
}
.z-bloque.min-h-55 {
  min-height: 55px;
}
.z-bloque.min-h-60 {
  min-height: 60px;
}
.z-bloque.min-h-65 {
  min-height: 65px;
}
.z-bloque.min-h-70 {
  min-height: 70px;
}
.z-bloque.min-h-75 {
  min-height: 75px;
}
.z-bloque.min-h-80 {
  min-height: 80px;
}
.z-bloque.min-h-85 {
  min-height: 85px;
}
.z-bloque.min-h-90 {
  min-height: 90px;
}
.z-bloque.min-h-95 {
  min-height: 95px;
}
.z-bloque.min-h-100 {
  min-height: 100px;
}
.z-bloque.min-h-105 {
  min-height: 105px;
}
.z-bloque.min-h-110 {
  min-height: 110px;
}
.z-bloque.min-h-115 {
  min-height: 115px;
}
.z-bloque.min-h-120 {
  min-height: 120px;
}
.z-bloque.min-h-125 {
  min-height: 125px;
}
.z-bloque.min-h-130 {
  min-height: 130px;
}
.z-bloque.min-h-135 {
  min-height: 135px;
}
.z-bloque.min-h-140 {
  min-height: 140px;
}
.z-bloque.min-h-145 {
  min-height: 145px;
}
.z-bloque.min-h-150 {
  min-height: 150px;
}
.z-bloque.min-h-155 {
  min-height: 155px;
}
.z-bloque.min-h-160 {
  min-height: 160px;
}
.z-bloque.min-h-165 {
  min-height: 165px;
}
.z-bloque.min-h-170 {
  min-height: 170px;
}
.z-bloque.min-h-175 {
  min-height: 175px;
}
.z-bloque.min-h-180 {
  min-height: 180px;
}
.z-bloque.min-h-185 {
  min-height: 185px;
}
.z-bloque.min-h-190 {
  min-height: 190px;
}
.z-bloque.min-h-195 {
  min-height: 195px;
}
.z-bloque.min-h-200 {
  min-height: 200px;
}
.z-bloque.min-h-205 {
  min-height: 205px;
}
.z-bloque.min-h-210 {
  min-height: 210px;
}
.z-bloque.min-h-215 {
  min-height: 215px;
}
.z-bloque.min-h-220 {
  min-height: 220px;
}
.z-bloque.min-h-225 {
  min-height: 225px;
}
.z-bloque.min-h-230 {
  min-height: 230px;
}
.z-bloque.min-h-235 {
  min-height: 235px;
}
.z-bloque.min-h-240 {
  min-height: 240px;
}
.z-bloque.min-h-245 {
  min-height: 245px;
}
.z-bloque.min-h-250 {
  min-height: 250px;
}
.z-bloque.min-h-255 {
  min-height: 255px;
}
.z-bloque.min-h-260 {
  min-height: 260px;
}
.z-bloque.min-h-265 {
  min-height: 265px;
}
.z-bloque.min-h-270 {
  min-height: 270px;
}
.z-bloque.min-h-275 {
  min-height: 275px;
}
.z-bloque.min-h-280 {
  min-height: 280px;
}
.z-bloque.min-h-285 {
  min-height: 285px;
}
.z-bloque.min-h-290 {
  min-height: 290px;
}
.z-bloque.min-h-295 {
  min-height: 295px;
}
.z-bloque.min-h-300 {
  min-height: 300px;
}
.z-bloque.min-h-305 {
  min-height: 305px;
}
.z-bloque.min-h-310 {
  min-height: 310px;
}
.z-bloque.min-h-315 {
  min-height: 315px;
}
.z-bloque.min-h-320 {
  min-height: 320px;
}
.z-bloque.min-h-325 {
  min-height: 325px;
}
.z-bloque.min-h-330 {
  min-height: 330px;
}
.z-bloque.min-h-335 {
  min-height: 335px;
}
.z-bloque.min-h-340 {
  min-height: 340px;
}
.z-bloque.min-h-345 {
  min-height: 345px;
}
.z-bloque.min-h-350 {
  min-height: 350px;
}
.z-bloque.min-h-355 {
  min-height: 355px;
}
.z-bloque.min-h-360 {
  min-height: 360px;
}
.z-bloque.min-h-365 {
  min-height: 365px;
}
.z-bloque.min-h-370 {
  min-height: 370px;
}
.z-bloque.min-h-375 {
  min-height: 375px;
}
.z-bloque.min-h-380 {
  min-height: 380px;
}
.z-bloque.min-h-385 {
  min-height: 385px;
}
.z-bloque.min-h-390 {
  min-height: 390px;
}
.z-bloque.min-h-395 {
  min-height: 395px;
}
.z-bloque.min-h-400 {
  min-height: 400px;
}
.z-bloque.min-h-405 {
  min-height: 405px;
}
.z-bloque.min-h-410 {
  min-height: 410px;
}
.z-bloque.min-h-415 {
  min-height: 415px;
}
.z-bloque.min-h-420 {
  min-height: 420px;
}
.z-bloque.min-h-425 {
  min-height: 425px;
}
.z-bloque.min-h-430 {
  min-height: 430px;
}
.z-bloque.min-h-435 {
  min-height: 435px;
}
.z-bloque.min-h-440 {
  min-height: 440px;
}
.z-bloque.min-h-445 {
  min-height: 445px;
}
.z-bloque.min-h-450 {
  min-height: 450px;
}
.z-bloque.min-h-455 {
  min-height: 455px;
}
.z-bloque.min-h-460 {
  min-height: 460px;
}
.z-bloque.min-h-465 {
  min-height: 465px;
}
.z-bloque.min-h-470 {
  min-height: 470px;
}
.z-bloque.min-h-475 {
  min-height: 475px;
}
.z-bloque.min-h-480 {
  min-height: 480px;
}
.z-bloque.min-h-485 {
  min-height: 485px;
}
.z-bloque.min-h-490 {
  min-height: 490px;
}
.z-bloque.min-h-495 {
  min-height: 495px;
}
.z-bloque.min-h-500 {
  min-height: 500px;
}
.z-bloque.min-h-505 {
  min-height: 505px;
}
.z-bloque.min-h-510 {
  min-height: 510px;
}
.z-bloque.min-h-515 {
  min-height: 515px;
}
.z-bloque.min-h-520 {
  min-height: 520px;
}
.z-bloque.min-h-525 {
  min-height: 525px;
}
.z-bloque.min-h-530 {
  min-height: 530px;
}
.z-bloque.min-h-535 {
  min-height: 535px;
}
.z-bloque.min-h-540 {
  min-height: 540px;
}
.z-bloque.min-h-545 {
  min-height: 545px;
}
.z-bloque.min-h-550 {
  min-height: 550px;
}
.z-bloque.min-h-555 {
  min-height: 555px;
}
.z-bloque.min-h-560 {
  min-height: 560px;
}
.z-bloque.min-h-565 {
  min-height: 565px;
}
.z-bloque.min-h-570 {
  min-height: 570px;
}
.z-bloque.min-h-575 {
  min-height: 575px;
}
.z-bloque.min-h-580 {
  min-height: 580px;
}
.z-bloque.min-h-585 {
  min-height: 585px;
}
.z-bloque.min-h-590 {
  min-height: 590px;
}
.z-bloque.min-h-595 {
  min-height: 595px;
}
.z-bloque.min-h-600 {
  min-height: 600px;
}
.z-bloque.min-h-605 {
  min-height: 605px;
}
.z-bloque.min-h-610 {
  min-height: 610px;
}
.z-bloque.min-h-615 {
  min-height: 615px;
}
.z-bloque.min-h-620 {
  min-height: 620px;
}
.z-bloque.min-h-625 {
  min-height: 625px;
}
.z-bloque.min-h-630 {
  min-height: 630px;
}
.z-bloque.min-h-635 {
  min-height: 635px;
}
.z-bloque.min-h-640 {
  min-height: 640px;
}
.z-bloque.min-h-645 {
  min-height: 645px;
}
.z-bloque.min-h-650 {
  min-height: 650px;
}
.z-bloque.min-h-655 {
  min-height: 655px;
}
.z-bloque.min-h-660 {
  min-height: 660px;
}
.z-bloque.min-h-665 {
  min-height: 665px;
}
.z-bloque.min-h-670 {
  min-height: 670px;
}
.z-bloque.min-h-675 {
  min-height: 675px;
}
.z-bloque.min-h-680 {
  min-height: 680px;
}
.z-bloque.min-h-685 {
  min-height: 685px;
}
.z-bloque.min-h-690 {
  min-height: 690px;
}
.z-bloque.min-h-695 {
  min-height: 695px;
}
.z-bloque.min-h-700 {
  min-height: 700px;
}
.z-bloque.min-h-705 {
  min-height: 705px;
}
.z-bloque.min-h-710 {
  min-height: 710px;
}
.z-bloque.min-h-715 {
  min-height: 715px;
}
.z-bloque.min-h-720 {
  min-height: 720px;
}
.z-bloque.min-h-725 {
  min-height: 725px;
}
.z-bloque.min-h-730 {
  min-height: 730px;
}
.z-bloque.min-h-735 {
  min-height: 735px;
}
.z-bloque.min-h-740 {
  min-height: 740px;
}
.z-bloque.min-h-745 {
  min-height: 745px;
}
.z-bloque.min-h-750 {
  min-height: 750px;
}
.z-bloque.min-h-755 {
  min-height: 755px;
}
.z-bloque.min-h-760 {
  min-height: 760px;
}
.z-bloque.min-h-765 {
  min-height: 765px;
}
.z-bloque.min-h-770 {
  min-height: 770px;
}
.z-bloque.min-h-775 {
  min-height: 775px;
}
.z-bloque.min-h-780 {
  min-height: 780px;
}
.z-bloque.min-h-785 {
  min-height: 785px;
}
.z-bloque.min-h-790 {
  min-height: 790px;
}
.z-bloque.min-h-795 {
  min-height: 795px;
}
.z-bloque.min-h-800 {
  min-height: 800px;
}
.z-bloque.min-h-805 {
  min-height: 805px;
}
.z-bloque.min-h-810 {
  min-height: 810px;
}
.z-bloque.min-h-815 {
  min-height: 815px;
}
.z-bloque.min-h-820 {
  min-height: 820px;
}
.z-bloque.min-h-825 {
  min-height: 825px;
}
.z-bloque.min-h-830 {
  min-height: 830px;
}
.z-bloque.min-h-835 {
  min-height: 835px;
}
.z-bloque.min-h-840 {
  min-height: 840px;
}
.z-bloque.min-h-845 {
  min-height: 845px;
}
.z-bloque.min-h-850 {
  min-height: 850px;
}
.z-bloque.min-h-855 {
  min-height: 855px;
}
.z-bloque.min-h-860 {
  min-height: 860px;
}
.z-bloque.min-h-865 {
  min-height: 865px;
}
.z-bloque.min-h-870 {
  min-height: 870px;
}
.z-bloque.min-h-875 {
  min-height: 875px;
}
.z-bloque.min-h-880 {
  min-height: 880px;
}
.z-bloque.min-h-885 {
  min-height: 885px;
}
.z-bloque.min-h-890 {
  min-height: 890px;
}
.z-bloque.min-h-895 {
  min-height: 895px;
}
.z-bloque.min-h-900 {
  min-height: 900px;
}
.z-bloque.min-h-905 {
  min-height: 905px;
}
.z-bloque.min-h-910 {
  min-height: 910px;
}
.z-bloque.min-h-915 {
  min-height: 915px;
}
.z-bloque.min-h-920 {
  min-height: 920px;
}
.z-bloque.min-h-925 {
  min-height: 925px;
}
.z-bloque.min-h-930 {
  min-height: 930px;
}
.z-bloque.min-h-935 {
  min-height: 935px;
}
.z-bloque.min-h-940 {
  min-height: 940px;
}
.z-bloque.min-h-945 {
  min-height: 945px;
}
.z-bloque.min-h-950 {
  min-height: 950px;
}
.z-bloque.min-h-955 {
  min-height: 955px;
}
.z-bloque.min-h-960 {
  min-height: 960px;
}
.z-bloque.min-h-965 {
  min-height: 965px;
}
.z-bloque.min-h-970 {
  min-height: 970px;
}
.z-bloque.min-h-975 {
  min-height: 975px;
}
.z-bloque.min-h-980 {
  min-height: 980px;
}
.z-bloque.min-h-985 {
  min-height: 985px;
}
.z-bloque.min-h-990 {
  min-height: 990px;
}
.z-bloque.min-h-995 {
  min-height: 995px;
}
.z-bloque.min-h-1000 {
  min-height: 1000px;
}
.z-bloque > img {
  width: 100%;
  height: auto;
}
.z-bloque.fixed {
  height: calc(100% - 60px);
  width: 100%;
  display: block;
  position: fixed;
  left: 0;
  top: 60px;
  overflow: hidden auto;
}
.z-bloque.fixed::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.z-bloque.fixed::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.z-bloque.fixed::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-principal-negro);
  cursor: pointer;
}
.z-bloque.background.r-0 {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}
.z-bloque.background.r-0.no-padding > article:first-child {
  -webkit-border-radius: 0px 0px 0 0;
  -moz-border-radius: 0px 0px 0 0;
  -ms-border-radius: 0px 0px 0 0;
  border-radius: 0px 0px 0 0;
}
.z-bloque.background.r-0.no-padding > article:last-child {
  -webkit-border-radius: 0 0 0px 0px;
  -moz-border-radius: 0 0 0px 0px;
  -ms-border-radius: 0 0 0px 0px;
  border-radius: 0 0 0px 0px;
}
.z-bloque.background.r-1 {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  border-radius: 1px;
}
.z-bloque.background.r-1.no-padding > article:first-child {
  -webkit-border-radius: 1px 1px 0 0;
  -moz-border-radius: 1px 1px 0 0;
  -ms-border-radius: 1px 1px 0 0;
  border-radius: 1px 1px 0 0;
}
.z-bloque.background.r-1.no-padding > article:last-child {
  -webkit-border-radius: 0 0 1px 1px;
  -moz-border-radius: 0 0 1px 1px;
  -ms-border-radius: 0 0 1px 1px;
  border-radius: 0 0 1px 1px;
}
.z-bloque.background.r-2 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
.z-bloque.background.r-2.no-padding > article:first-child {
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.z-bloque.background.r-2.no-padding > article:last-child {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}
.z-bloque.background.r-3 {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.z-bloque.background.r-3.no-padding > article:first-child {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.z-bloque.background.r-3.no-padding > article:last-child {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
.z-bloque.background.r-4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.z-bloque.background.r-4.no-padding > article:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.z-bloque.background.r-4.no-padding > article:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.z-bloque.background.r-5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.z-bloque.background.r-5.no-padding > article:first-child {
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.z-bloque.background.r-5.no-padding > article:last-child {
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}
.z-bloque.background.r-6 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}
.z-bloque.background.r-6.no-padding > article:first-child {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  -ms-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.z-bloque.background.r-6.no-padding > article:last-child {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  -ms-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.z-bloque.background.r-7 {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.z-bloque.background.r-7.no-padding > article:first-child {
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
  -ms-border-radius: 7px 7px 0 0;
  border-radius: 7px 7px 0 0;
}
.z-bloque.background.r-7.no-padding > article:last-child {
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  -ms-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
}
.z-bloque.background.r-8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.z-bloque.background.r-8.no-padding > article:first-child {
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -ms-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}
.z-bloque.background.r-8.no-padding > article:last-child {
  -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  -ms-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
}
.z-bloque.background.r-9 {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  border-radius: 9px;
}
.z-bloque.background.r-9.no-padding > article:first-child {
  -webkit-border-radius: 9px 9px 0 0;
  -moz-border-radius: 9px 9px 0 0;
  -ms-border-radius: 9px 9px 0 0;
  border-radius: 9px 9px 0 0;
}
.z-bloque.background.r-9.no-padding > article:last-child {
  -webkit-border-radius: 0 0 9px 9px;
  -moz-border-radius: 0 0 9px 9px;
  -ms-border-radius: 0 0 9px 9px;
  border-radius: 0 0 9px 9px;
}
.z-bloque.background.r-10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.z-bloque.background.r-10.no-padding > article:first-child {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.z-bloque.background.r-10.no-padding > article:last-child {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.z-bloque.background.r-11 {
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  -ms-border-radius: 11px;
  border-radius: 11px;
}
.z-bloque.background.r-11.no-padding > article:first-child {
  -webkit-border-radius: 11px 11px 0 0;
  -moz-border-radius: 11px 11px 0 0;
  -ms-border-radius: 11px 11px 0 0;
  border-radius: 11px 11px 0 0;
}
.z-bloque.background.r-11.no-padding > article:last-child {
  -webkit-border-radius: 0 0 11px 11px;
  -moz-border-radius: 0 0 11px 11px;
  -ms-border-radius: 0 0 11px 11px;
  border-radius: 0 0 11px 11px;
}
.z-bloque.background.r-12 {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}
.z-bloque.background.r-12.no-padding > article:first-child {
  -webkit-border-radius: 12px 12px 0 0;
  -moz-border-radius: 12px 12px 0 0;
  -ms-border-radius: 12px 12px 0 0;
  border-radius: 12px 12px 0 0;
}
.z-bloque.background.r-12.no-padding > article:last-child {
  -webkit-border-radius: 0 0 12px 12px;
  -moz-border-radius: 0 0 12px 12px;
  -ms-border-radius: 0 0 12px 12px;
  border-radius: 0 0 12px 12px;
}
.z-bloque.background.r-13 {
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  border-radius: 13px;
}
.z-bloque.background.r-13.no-padding > article:first-child {
  -webkit-border-radius: 13px 13px 0 0;
  -moz-border-radius: 13px 13px 0 0;
  -ms-border-radius: 13px 13px 0 0;
  border-radius: 13px 13px 0 0;
}
.z-bloque.background.r-13.no-padding > article:last-child {
  -webkit-border-radius: 0 0 13px 13px;
  -moz-border-radius: 0 0 13px 13px;
  -ms-border-radius: 0 0 13px 13px;
  border-radius: 0 0 13px 13px;
}
.z-bloque.background.r-14 {
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px;
}
.z-bloque.background.r-14.no-padding > article:first-child {
  -webkit-border-radius: 14px 14px 0 0;
  -moz-border-radius: 14px 14px 0 0;
  -ms-border-radius: 14px 14px 0 0;
  border-radius: 14px 14px 0 0;
}
.z-bloque.background.r-14.no-padding > article:last-child {
  -webkit-border-radius: 0 0 14px 14px;
  -moz-border-radius: 0 0 14px 14px;
  -ms-border-radius: 0 0 14px 14px;
  border-radius: 0 0 14px 14px;
}
.z-bloque.background.r-15 {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}
.z-bloque.background.r-15.no-padding > article:first-child {
  -webkit-border-radius: 15px 15px 0 0;
  -moz-border-radius: 15px 15px 0 0;
  -ms-border-radius: 15px 15px 0 0;
  border-radius: 15px 15px 0 0;
}
.z-bloque.background.r-15.no-padding > article:last-child {
  -webkit-border-radius: 0 0 15px 15px;
  -moz-border-radius: 0 0 15px 15px;
  -ms-border-radius: 0 0 15px 15px;
  border-radius: 0 0 15px 15px;
}
.z-bloque.background.r-16 {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
}
.z-bloque.background.r-16.no-padding > article:first-child {
  -webkit-border-radius: 16px 16px 0 0;
  -moz-border-radius: 16px 16px 0 0;
  -ms-border-radius: 16px 16px 0 0;
  border-radius: 16px 16px 0 0;
}
.z-bloque.background.r-16.no-padding > article:last-child {
  -webkit-border-radius: 0 0 16px 16px;
  -moz-border-radius: 0 0 16px 16px;
  -ms-border-radius: 0 0 16px 16px;
  border-radius: 0 0 16px 16px;
}
.z-bloque.background.r-17 {
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  border-radius: 17px;
}
.z-bloque.background.r-17.no-padding > article:first-child {
  -webkit-border-radius: 17px 17px 0 0;
  -moz-border-radius: 17px 17px 0 0;
  -ms-border-radius: 17px 17px 0 0;
  border-radius: 17px 17px 0 0;
}
.z-bloque.background.r-17.no-padding > article:last-child {
  -webkit-border-radius: 0 0 17px 17px;
  -moz-border-radius: 0 0 17px 17px;
  -ms-border-radius: 0 0 17px 17px;
  border-radius: 0 0 17px 17px;
}
.z-bloque.background.r-18 {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  border-radius: 18px;
}
.z-bloque.background.r-18.no-padding > article:first-child {
  -webkit-border-radius: 18px 18px 0 0;
  -moz-border-radius: 18px 18px 0 0;
  -ms-border-radius: 18px 18px 0 0;
  border-radius: 18px 18px 0 0;
}
.z-bloque.background.r-18.no-padding > article:last-child {
  -webkit-border-radius: 0 0 18px 18px;
  -moz-border-radius: 0 0 18px 18px;
  -ms-border-radius: 0 0 18px 18px;
  border-radius: 0 0 18px 18px;
}
.z-bloque.background.r-19 {
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  -ms-border-radius: 19px;
  border-radius: 19px;
}
.z-bloque.background.r-19.no-padding > article:first-child {
  -webkit-border-radius: 19px 19px 0 0;
  -moz-border-radius: 19px 19px 0 0;
  -ms-border-radius: 19px 19px 0 0;
  border-radius: 19px 19px 0 0;
}
.z-bloque.background.r-19.no-padding > article:last-child {
  -webkit-border-radius: 0 0 19px 19px;
  -moz-border-radius: 0 0 19px 19px;
  -ms-border-radius: 0 0 19px 19px;
  border-radius: 0 0 19px 19px;
}
.z-bloque.background.r-20 {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
}
.z-bloque.background.r-20.no-padding > article:first-child {
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  border-radius: 20px 20px 0 0;
}
.z-bloque.background.r-20.no-padding > article:last-child {
  -webkit-border-radius: 0 0 20px 20px;
  -moz-border-radius: 0 0 20px 20px;
  -ms-border-radius: 0 0 20px 20px;
  border-radius: 0 0 20px 20px;
}
.z-bloque.background.blanco {
  background-color: var(--bs--color-principal-blanco);
}
.z-bloque.background.gris, .z-bloque.background.gris-10 {
  background-color: var(--bs--color-grises-gris-10);
}
.z-bloque.background.gris-20 {
  background-color: var(--bs--color-grises-gris-20);
}
.z-bloque.background.naranja {
  background-color: var(--bs--color-principal-naranja);
}
.z-bloque.background.naranja-20 {
  background-color: var(--bs--color-secundario-naranja-20);
}
.z-bloque.background.naranja-10 {
  background-color: var(--bs--color-secundario-naranja-10);
}

.center-objects {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.center-objects p,
.center-objects h1,
.center-objects h2,
.center-objects h3,
.center-objects h4,
.center-objects h5,
.center-objects h6 {
  flex: 0 0 100%;
}
.center-objects > section,
.center-objects > article,
.center-objects > div,
.center-objects > figure {
  flex: 0 0 100%;
}
.center-objects.mensaje > article {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
}

.m-0 {
  margin: 0px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.m-t-1 {
  margin-top: 1px !important;
}

.m-b-1 {
  margin-bottom: 1px !important;
}

.m-l-1 {
  margin-left: 1px !important;
}

.m-r-1 {
  margin-right: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-b-2 {
  margin-bottom: 2px !important;
}

.m-l-2 {
  margin-left: 2px !important;
}

.m-r-2 {
  margin-right: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-b-3 {
  margin-bottom: 3px !important;
}

.m-l-3 {
  margin-left: 3px !important;
}

.m-r-3 {
  margin-right: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-t-6 {
  margin-top: 6px !important;
}

.m-b-6 {
  margin-bottom: 6px !important;
}

.m-l-6 {
  margin-left: 6px !important;
}

.m-r-6 {
  margin-right: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-b-7 {
  margin-bottom: 7px !important;
}

.m-l-7 {
  margin-left: 7px !important;
}

.m-r-7 {
  margin-right: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.m-t-9 {
  margin-top: 9px !important;
}

.m-b-9 {
  margin-bottom: 9px !important;
}

.m-l-9 {
  margin-left: 9px !important;
}

.m-r-9 {
  margin-right: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-11 {
  margin: 11px !important;
}

.m-t-11 {
  margin-top: 11px !important;
}

.m-b-11 {
  margin-bottom: 11px !important;
}

.m-l-11 {
  margin-left: 11px !important;
}

.m-r-11 {
  margin-right: 11px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-l-12 {
  margin-left: 12px !important;
}

.m-r-12 {
  margin-right: 12px !important;
}

.m-13 {
  margin: 13px !important;
}

.m-t-13 {
  margin-top: 13px !important;
}

.m-b-13 {
  margin-bottom: 13px !important;
}

.m-l-13 {
  margin-left: 13px !important;
}

.m-r-13 {
  margin-right: 13px !important;
}

.m-14 {
  margin: 14px !important;
}

.m-t-14 {
  margin-top: 14px !important;
}

.m-b-14 {
  margin-bottom: 14px !important;
}

.m-l-14 {
  margin-left: 14px !important;
}

.m-r-14 {
  margin-right: 14px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.m-17 {
  margin: 17px !important;
}

.m-t-17 {
  margin-top: 17px !important;
}

.m-b-17 {
  margin-bottom: 17px !important;
}

.m-l-17 {
  margin-left: 17px !important;
}

.m-r-17 {
  margin-right: 17px !important;
}

.m-18 {
  margin: 18px !important;
}

.m-t-18 {
  margin-top: 18px !important;
}

.m-b-18 {
  margin-bottom: 18px !important;
}

.m-l-18 {
  margin-left: 18px !important;
}

.m-r-18 {
  margin-right: 18px !important;
}

.m-19 {
  margin: 19px !important;
}

.m-t-19 {
  margin-top: 19px !important;
}

.m-b-19 {
  margin-bottom: 19px !important;
}

.m-l-19 {
  margin-left: 19px !important;
}

.m-r-19 {
  margin-right: 19px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-21 {
  margin: 21px !important;
}

.m-t-21 {
  margin-top: 21px !important;
}

.m-b-21 {
  margin-bottom: 21px !important;
}

.m-l-21 {
  margin-left: 21px !important;
}

.m-r-21 {
  margin-right: 21px !important;
}

.m-22 {
  margin: 22px !important;
}

.m-t-22 {
  margin-top: 22px !important;
}

.m-b-22 {
  margin-bottom: 22px !important;
}

.m-l-22 {
  margin-left: 22px !important;
}

.m-r-22 {
  margin-right: 22px !important;
}

.m-23 {
  margin: 23px !important;
}

.m-t-23 {
  margin-top: 23px !important;
}

.m-b-23 {
  margin-bottom: 23px !important;
}

.m-l-23 {
  margin-left: 23px !important;
}

.m-r-23 {
  margin-right: 23px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-26 {
  margin: 26px !important;
}

.m-t-26 {
  margin-top: 26px !important;
}

.m-b-26 {
  margin-bottom: 26px !important;
}

.m-l-26 {
  margin-left: 26px !important;
}

.m-r-26 {
  margin-right: 26px !important;
}

.m-27 {
  margin: 27px !important;
}

.m-t-27 {
  margin-top: 27px !important;
}

.m-b-27 {
  margin-bottom: 27px !important;
}

.m-l-27 {
  margin-left: 27px !important;
}

.m-r-27 {
  margin-right: 27px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-t-28 {
  margin-top: 28px !important;
}

.m-b-28 {
  margin-bottom: 28px !important;
}

.m-l-28 {
  margin-left: 28px !important;
}

.m-r-28 {
  margin-right: 28px !important;
}

.m-29 {
  margin: 29px !important;
}

.m-t-29 {
  margin-top: 29px !important;
}

.m-b-29 {
  margin-bottom: 29px !important;
}

.m-l-29 {
  margin-left: 29px !important;
}

.m-r-29 {
  margin-right: 29px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-1 {
  padding: 1px !important;
}

.p-t-1 {
  padding-top: 1px !important;
}

.p-b-1 {
  padding-bottom: 1px !important;
}

.p-r-1 {
  padding-right: 1px !important;
}

.p-l-1 {
  padding-left: 1px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-b-2 {
  padding-bottom: 2px !important;
}

.p-r-2 {
  padding-right: 2px !important;
}

.p-l-2 {
  padding-left: 2px !important;
}

.p-3 {
  padding: 3px !important;
}

.p-t-3 {
  padding-top: 3px !important;
}

.p-b-3 {
  padding-bottom: 3px !important;
}

.p-r-3 {
  padding-right: 3px !important;
}

.p-l-3 {
  padding-left: 3px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.p-r-4 {
  padding-right: 4px !important;
}

.p-l-4 {
  padding-left: 4px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-6 {
  padding: 6px !important;
}

.p-t-6 {
  padding-top: 6px !important;
}

.p-b-6 {
  padding-bottom: 6px !important;
}

.p-r-6 {
  padding-right: 6px !important;
}

.p-l-6 {
  padding-left: 6px !important;
}

.p-7 {
  padding: 7px !important;
}

.p-t-7 {
  padding-top: 7px !important;
}

.p-b-7 {
  padding-bottom: 7px !important;
}

.p-r-7 {
  padding-right: 7px !important;
}

.p-l-7 {
  padding-left: 7px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.p-9 {
  padding: 9px !important;
}

.p-t-9 {
  padding-top: 9px !important;
}

.p-b-9 {
  padding-bottom: 9px !important;
}

.p-r-9 {
  padding-right: 9px !important;
}

.p-l-9 {
  padding-left: 9px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-11 {
  padding: 11px !important;
}

.p-t-11 {
  padding-top: 11px !important;
}

.p-b-11 {
  padding-bottom: 11px !important;
}

.p-r-11 {
  padding-right: 11px !important;
}

.p-l-11 {
  padding-left: 11px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.p-r-12 {
  padding-right: 12px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}

.p-13 {
  padding: 13px !important;
}

.p-t-13 {
  padding-top: 13px !important;
}

.p-b-13 {
  padding-bottom: 13px !important;
}

.p-r-13 {
  padding-right: 13px !important;
}

.p-l-13 {
  padding-left: 13px !important;
}

.p-14 {
  padding: 14px !important;
}

.p-t-14 {
  padding-top: 14px !important;
}

.p-b-14 {
  padding-bottom: 14px !important;
}

.p-r-14 {
  padding-right: 14px !important;
}

.p-l-14 {
  padding-left: 14px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.p-17 {
  padding: 17px !important;
}

.p-t-17 {
  padding-top: 17px !important;
}

.p-b-17 {
  padding-bottom: 17px !important;
}

.p-r-17 {
  padding-right: 17px !important;
}

.p-l-17 {
  padding-left: 17px !important;
}

.p-18 {
  padding: 18px !important;
}

.p-t-18 {
  padding-top: 18px !important;
}

.p-b-18 {
  padding-bottom: 18px !important;
}

.p-r-18 {
  padding-right: 18px !important;
}

.p-l-18 {
  padding-left: 18px !important;
}

.p-19 {
  padding: 19px !important;
}

.p-t-19 {
  padding-top: 19px !important;
}

.p-b-19 {
  padding-bottom: 19px !important;
}

.p-r-19 {
  padding-right: 19px !important;
}

.p-l-19 {
  padding-left: 19px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-21 {
  padding: 21px !important;
}

.p-t-21 {
  padding-top: 21px !important;
}

.p-b-21 {
  padding-bottom: 21px !important;
}

.p-r-21 {
  padding-right: 21px !important;
}

.p-l-21 {
  padding-left: 21px !important;
}

.p-22 {
  padding: 22px !important;
}

.p-t-22 {
  padding-top: 22px !important;
}

.p-b-22 {
  padding-bottom: 22px !important;
}

.p-r-22 {
  padding-right: 22px !important;
}

.p-l-22 {
  padding-left: 22px !important;
}

.p-23 {
  padding: 23px !important;
}

.p-t-23 {
  padding-top: 23px !important;
}

.p-b-23 {
  padding-bottom: 23px !important;
}

.p-r-23 {
  padding-right: 23px !important;
}

.p-l-23 {
  padding-left: 23px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-26 {
  padding: 26px !important;
}

.p-t-26 {
  padding-top: 26px !important;
}

.p-b-26 {
  padding-bottom: 26px !important;
}

.p-r-26 {
  padding-right: 26px !important;
}

.p-l-26 {
  padding-left: 26px !important;
}

.p-27 {
  padding: 27px !important;
}

.p-t-27 {
  padding-top: 27px !important;
}

.p-b-27 {
  padding-bottom: 27px !important;
}

.p-r-27 {
  padding-right: 27px !important;
}

.p-l-27 {
  padding-left: 27px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-t-28 {
  padding-top: 28px !important;
}

.p-b-28 {
  padding-bottom: 28px !important;
}

.p-r-28 {
  padding-right: 28px !important;
}

.p-l-28 {
  padding-left: 28px !important;
}

.p-29 {
  padding: 29px !important;
}

.p-t-29 {
  padding-top: 29px !important;
}

.p-b-29 {
  padding-bottom: 29px !important;
}

.p-r-29 {
  padding-right: 29px !important;
}

.p-l-29 {
  padding-left: 29px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.z-border-all {
  border: 3px solid var(--bs--color-grises-gris-10);
}
.z-border-all.gris-10 {
  border-color: var(--bs--color-grises-gris-10);
}
.z-border-all.gris-15 {
  border-color: var(--bs--color-grises-gris-15);
}
.z-border-all.gris-20 {
  border-color: var(--bs--color-grises-gris-20);
}
.z-border-all.gris-40 {
  border-color: var(--bs--color-grises-gris-40);
}
.z-border-all.gris-50 {
  border-color: var(--bs--color-grises-gris-50);
}
.z-border-all.gris-80 {
  border-color: var(--bs--color-grises-gris-80);
}
@media only screen and (max-width : 810px) {
  .z-border-all.no-border-tablet {
    border: none;
  }
}
@media only screen and (max-width : 500px) {
  .z-border-all.no-border-mobile {
    border: none;
  }
}

.z-border-left {
  border-left: 2px solid var(--bs--color-grises-gris-20);
}
.z-border-right {
  border-right: 2px solid var(--bs--color-grises-gris-20);
}
.z-border-top {
  border-top: 2px solid var(--bs--color-grises-gris-20);
}
.z-border-bottom {
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
}
@media only screen and (max-width : 810px) {
  .z-border-left.no-border-tablet, .z-border-right.no-border-tablet, .z-border-top.no-border-tablet, .z-border-bottom.no-border-tablet {
    border: none;
  }
}
@media only screen and (max-width : 500px) {
  .z-border-left.no-border-mobile, .z-border-right.no-border-mobile, .z-border-top.no-border-mobile, .z-border-bottom.no-border-mobile {
    border: none;
  }
}
.z-border-left.gris-10, .z-border-right.gris-10, .z-border-top.gris-10, .z-border-bottom.gris-10 {
  border-color: var(--bs--color-grises-gris-10);
}
.z-border-left.gris-15, .z-border-right.gris-15, .z-border-top.gris-15, .z-border-bottom.gris-15 {
  border-color: var(--bs--color-grises-gris-15);
}
.z-border-left.gris-20, .z-border-right.gris-20, .z-border-top.gris-20, .z-border-bottom.gris-20 {
  border-color: var(--bs--color-grises-gris-20);
}
.z-border-left.gris-40, .z-border-right.gris-40, .z-border-top.gris-40, .z-border-bottom.gris-40 {
  border-color: var(--bs--color-grises-gris-40);
}
.z-border-left.gris-50, .z-border-right.gris-50, .z-border-top.gris-50, .z-border-bottom.gris-50 {
  border-color: var(--bs--color-grises-gris-50);
}
.z-border-left.gris-80, .z-border-right.gris-80, .z-border-top.gris-80, .z-border-bottom.gris-80 {
  border-color: var(--bs--color-grises-gris-80);
}
@media only screen and (max-width : 810px) {
  .z-border.no-border-tablet {
    border: none;
  }
}
@media only screen and (max-width : 500px) {
  .z-border.no-border-mobile {
    border: none;
  }
}

img.border-4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
img.border-8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
img.border-12 {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}
img.border-20 {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
}
img.z-fotografia {
  height: auto;
  width: 100%;
  object-fit: cover;
  background-size: cover;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

div.z-btn,
button.z-btn,
a.z-btn {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1;
  height: 48px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  border-radius: 24px;
  text-align: center;
  padding: 0 16px;
  min-width: 160px;
  cursor: pointer;
  font-size: var(--bs--font-size-button);
  font-family: "overpassbold", sans-serif;
  text-transform: uppercase;
  margin: 6px;
  position: relative;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  text-decoration: none;
  width: 100%;
}
@media only screen and (min-width : 600px) {
  div.z-btn,
button.z-btn,
a.z-btn {
    width: auto;
  }
}
div.z-btn.no-padding,
button.z-btn.no-padding,
a.z-btn.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media only screen and (max-width : 500px) {
  div.z-btn.mobile-compact,
button.z-btn.mobile-compact,
a.z-btn.mobile-compact {
    padding-left: 12px;
    padding-right: 12px;
    min-width: 10px;
  }
}
div.z-btn.center,
button.z-btn.center,
a.z-btn.center {
  margin: 0 auto !important;
  text-align: center;
  display: block;
}
div.z-btn.extendido,
button.z-btn.extendido,
a.z-btn.extendido {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
@media only screen and (max-width : 500px) {
  div.z-btn.extendido,
button.z-btn.extendido,
a.z-btn.extendido {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}
div.z-btn.min,
button.z-btn.min,
a.z-btn.min {
  min-width: 220px;
}
div.z-btn.min.w-0,
button.z-btn.min.w-0,
a.z-btn.min.w-0 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-0,
button.z-btn.min.w-0,
a.z-btn.min.w-0 {
    min-width: 0px;
  }
}
div.z-btn.min.w-10,
button.z-btn.min.w-10,
a.z-btn.min.w-10 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-10,
button.z-btn.min.w-10,
a.z-btn.min.w-10 {
    min-width: 10px;
  }
}
div.z-btn.min.w-20,
button.z-btn.min.w-20,
a.z-btn.min.w-20 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-20,
button.z-btn.min.w-20,
a.z-btn.min.w-20 {
    min-width: 20px;
  }
}
div.z-btn.min.w-30,
button.z-btn.min.w-30,
a.z-btn.min.w-30 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-30,
button.z-btn.min.w-30,
a.z-btn.min.w-30 {
    min-width: 30px;
  }
}
div.z-btn.min.w-40,
button.z-btn.min.w-40,
a.z-btn.min.w-40 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-40,
button.z-btn.min.w-40,
a.z-btn.min.w-40 {
    min-width: 40px;
  }
}
div.z-btn.min.w-50,
button.z-btn.min.w-50,
a.z-btn.min.w-50 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-50,
button.z-btn.min.w-50,
a.z-btn.min.w-50 {
    min-width: 50px;
  }
}
div.z-btn.min.w-60,
button.z-btn.min.w-60,
a.z-btn.min.w-60 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-60,
button.z-btn.min.w-60,
a.z-btn.min.w-60 {
    min-width: 60px;
  }
}
div.z-btn.min.w-70,
button.z-btn.min.w-70,
a.z-btn.min.w-70 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-70,
button.z-btn.min.w-70,
a.z-btn.min.w-70 {
    min-width: 70px;
  }
}
div.z-btn.min.w-80,
button.z-btn.min.w-80,
a.z-btn.min.w-80 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-80,
button.z-btn.min.w-80,
a.z-btn.min.w-80 {
    min-width: 80px;
  }
}
div.z-btn.min.w-90,
button.z-btn.min.w-90,
a.z-btn.min.w-90 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-90,
button.z-btn.min.w-90,
a.z-btn.min.w-90 {
    min-width: 90px;
  }
}
div.z-btn.min.w-100,
button.z-btn.min.w-100,
a.z-btn.min.w-100 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-100,
button.z-btn.min.w-100,
a.z-btn.min.w-100 {
    min-width: 100px;
  }
}
div.z-btn.min.w-110,
button.z-btn.min.w-110,
a.z-btn.min.w-110 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-110,
button.z-btn.min.w-110,
a.z-btn.min.w-110 {
    min-width: 110px;
  }
}
div.z-btn.min.w-120,
button.z-btn.min.w-120,
a.z-btn.min.w-120 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-120,
button.z-btn.min.w-120,
a.z-btn.min.w-120 {
    min-width: 120px;
  }
}
div.z-btn.min.w-130,
button.z-btn.min.w-130,
a.z-btn.min.w-130 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-130,
button.z-btn.min.w-130,
a.z-btn.min.w-130 {
    min-width: 130px;
  }
}
div.z-btn.min.w-140,
button.z-btn.min.w-140,
a.z-btn.min.w-140 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-140,
button.z-btn.min.w-140,
a.z-btn.min.w-140 {
    min-width: 140px;
  }
}
div.z-btn.min.w-150,
button.z-btn.min.w-150,
a.z-btn.min.w-150 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-150,
button.z-btn.min.w-150,
a.z-btn.min.w-150 {
    min-width: 150px;
  }
}
div.z-btn.min.w-160,
button.z-btn.min.w-160,
a.z-btn.min.w-160 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-160,
button.z-btn.min.w-160,
a.z-btn.min.w-160 {
    min-width: 160px;
  }
}
div.z-btn.min.w-170,
button.z-btn.min.w-170,
a.z-btn.min.w-170 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-170,
button.z-btn.min.w-170,
a.z-btn.min.w-170 {
    min-width: 170px;
  }
}
div.z-btn.min.w-180,
button.z-btn.min.w-180,
a.z-btn.min.w-180 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-180,
button.z-btn.min.w-180,
a.z-btn.min.w-180 {
    min-width: 180px;
  }
}
div.z-btn.min.w-190,
button.z-btn.min.w-190,
a.z-btn.min.w-190 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-190,
button.z-btn.min.w-190,
a.z-btn.min.w-190 {
    min-width: 190px;
  }
}
div.z-btn.min.w-200,
button.z-btn.min.w-200,
a.z-btn.min.w-200 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-200,
button.z-btn.min.w-200,
a.z-btn.min.w-200 {
    min-width: 200px;
  }
}
div.z-btn.min.w-210,
button.z-btn.min.w-210,
a.z-btn.min.w-210 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-210,
button.z-btn.min.w-210,
a.z-btn.min.w-210 {
    min-width: 210px;
  }
}
div.z-btn.min.w-220,
button.z-btn.min.w-220,
a.z-btn.min.w-220 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-220,
button.z-btn.min.w-220,
a.z-btn.min.w-220 {
    min-width: 220px;
  }
}
div.z-btn.min.w-230,
button.z-btn.min.w-230,
a.z-btn.min.w-230 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-230,
button.z-btn.min.w-230,
a.z-btn.min.w-230 {
    min-width: 230px;
  }
}
div.z-btn.min.w-240,
button.z-btn.min.w-240,
a.z-btn.min.w-240 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-240,
button.z-btn.min.w-240,
a.z-btn.min.w-240 {
    min-width: 240px;
  }
}
div.z-btn.min.w-250,
button.z-btn.min.w-250,
a.z-btn.min.w-250 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-250,
button.z-btn.min.w-250,
a.z-btn.min.w-250 {
    min-width: 250px;
  }
}
div.z-btn.min.w-260,
button.z-btn.min.w-260,
a.z-btn.min.w-260 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-260,
button.z-btn.min.w-260,
a.z-btn.min.w-260 {
    min-width: 260px;
  }
}
div.z-btn.min.w-270,
button.z-btn.min.w-270,
a.z-btn.min.w-270 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-270,
button.z-btn.min.w-270,
a.z-btn.min.w-270 {
    min-width: 270px;
  }
}
div.z-btn.min.w-280,
button.z-btn.min.w-280,
a.z-btn.min.w-280 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-280,
button.z-btn.min.w-280,
a.z-btn.min.w-280 {
    min-width: 280px;
  }
}
div.z-btn.min.w-290,
button.z-btn.min.w-290,
a.z-btn.min.w-290 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-290,
button.z-btn.min.w-290,
a.z-btn.min.w-290 {
    min-width: 290px;
  }
}
div.z-btn.min.w-300,
button.z-btn.min.w-300,
a.z-btn.min.w-300 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-300,
button.z-btn.min.w-300,
a.z-btn.min.w-300 {
    min-width: 300px;
  }
}
div.z-btn.min.w-310,
button.z-btn.min.w-310,
a.z-btn.min.w-310 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-310,
button.z-btn.min.w-310,
a.z-btn.min.w-310 {
    min-width: 310px;
  }
}
div.z-btn.min.w-320,
button.z-btn.min.w-320,
a.z-btn.min.w-320 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-320,
button.z-btn.min.w-320,
a.z-btn.min.w-320 {
    min-width: 320px;
  }
}
div.z-btn.min.w-330,
button.z-btn.min.w-330,
a.z-btn.min.w-330 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-330,
button.z-btn.min.w-330,
a.z-btn.min.w-330 {
    min-width: 330px;
  }
}
div.z-btn.min.w-340,
button.z-btn.min.w-340,
a.z-btn.min.w-340 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-340,
button.z-btn.min.w-340,
a.z-btn.min.w-340 {
    min-width: 340px;
  }
}
div.z-btn.min.w-350,
button.z-btn.min.w-350,
a.z-btn.min.w-350 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-350,
button.z-btn.min.w-350,
a.z-btn.min.w-350 {
    min-width: 350px;
  }
}
div.z-btn.min.w-360,
button.z-btn.min.w-360,
a.z-btn.min.w-360 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-360,
button.z-btn.min.w-360,
a.z-btn.min.w-360 {
    min-width: 360px;
  }
}
div.z-btn.min.w-370,
button.z-btn.min.w-370,
a.z-btn.min.w-370 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-370,
button.z-btn.min.w-370,
a.z-btn.min.w-370 {
    min-width: 370px;
  }
}
div.z-btn.min.w-380,
button.z-btn.min.w-380,
a.z-btn.min.w-380 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-380,
button.z-btn.min.w-380,
a.z-btn.min.w-380 {
    min-width: 380px;
  }
}
div.z-btn.min.w-390,
button.z-btn.min.w-390,
a.z-btn.min.w-390 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-390,
button.z-btn.min.w-390,
a.z-btn.min.w-390 {
    min-width: 390px;
  }
}
div.z-btn.min.w-400,
button.z-btn.min.w-400,
a.z-btn.min.w-400 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-400,
button.z-btn.min.w-400,
a.z-btn.min.w-400 {
    min-width: 400px;
  }
}
div.z-btn.min.w-410,
button.z-btn.min.w-410,
a.z-btn.min.w-410 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-410,
button.z-btn.min.w-410,
a.z-btn.min.w-410 {
    min-width: 410px;
  }
}
div.z-btn.min.w-420,
button.z-btn.min.w-420,
a.z-btn.min.w-420 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-420,
button.z-btn.min.w-420,
a.z-btn.min.w-420 {
    min-width: 420px;
  }
}
div.z-btn.min.w-430,
button.z-btn.min.w-430,
a.z-btn.min.w-430 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-430,
button.z-btn.min.w-430,
a.z-btn.min.w-430 {
    min-width: 430px;
  }
}
div.z-btn.min.w-440,
button.z-btn.min.w-440,
a.z-btn.min.w-440 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-440,
button.z-btn.min.w-440,
a.z-btn.min.w-440 {
    min-width: 440px;
  }
}
div.z-btn.min.w-450,
button.z-btn.min.w-450,
a.z-btn.min.w-450 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-450,
button.z-btn.min.w-450,
a.z-btn.min.w-450 {
    min-width: 450px;
  }
}
div.z-btn.min.w-460,
button.z-btn.min.w-460,
a.z-btn.min.w-460 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-460,
button.z-btn.min.w-460,
a.z-btn.min.w-460 {
    min-width: 460px;
  }
}
div.z-btn.min.w-470,
button.z-btn.min.w-470,
a.z-btn.min.w-470 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-470,
button.z-btn.min.w-470,
a.z-btn.min.w-470 {
    min-width: 470px;
  }
}
div.z-btn.min.w-480,
button.z-btn.min.w-480,
a.z-btn.min.w-480 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-480,
button.z-btn.min.w-480,
a.z-btn.min.w-480 {
    min-width: 480px;
  }
}
div.z-btn.min.w-490,
button.z-btn.min.w-490,
a.z-btn.min.w-490 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-490,
button.z-btn.min.w-490,
a.z-btn.min.w-490 {
    min-width: 490px;
  }
}
div.z-btn.min.w-500,
button.z-btn.min.w-500,
a.z-btn.min.w-500 {
  min-width: auto;
}
@media only screen and (min-width : 600px) {
  div.z-btn.min.w-500,
button.z-btn.min.w-500,
a.z-btn.min.w-500 {
    min-width: 500px;
  }
}
div.z-btn-primary,
button.z-btn-primary,
a.z-btn-primary {
  background-color: var(--bs--color-principal-naranja);
  color: var(--bs--color-principal-blanco);
}
div.z-btn-primary:hover,
button.z-btn-primary:hover,
a.z-btn-primary:hover {
  background-color: var(--bs--color-secundario-naranja-hover);
}
div.z-btn-primary:active,
button.z-btn-primary:active,
a.z-btn-primary:active {
  background-color: var(--bs--color-secundario-naranja-hover);
}
div.z-btn-primary[disabled],
button.z-btn-primary[disabled],
a.z-btn-primary[disabled] {
  cursor: not-allowed;
  background-color: var(--bs--color-secundario-naranja-20);
}
div.z-btn-primary[disabled]:hover,
button.z-btn-primary[disabled]:hover,
a.z-btn-primary[disabled]:hover {
  background-color: var(--bs--color-secundario-naranja-20);
}
div.z-btn-primary[disabled].icon figure img,
button.z-btn-primary[disabled].icon figure img,
a.z-btn-primary[disabled].icon figure img {
  opacity: 0.8;
}
div.z-btn-primary-negro,
button.z-btn-primary-negro,
a.z-btn-primary-negro {
  background-color: var(--bs--color-principal-negro);
  color: var(--bs--color-principal-blanco);
}
div.z-btn-primary-negro:hover,
button.z-btn-primary-negro:hover,
a.z-btn-primary-negro:hover {
  background-color: var(--bs--color-secundario-negro-hover);
}
div.z-btn-primary-negro:active,
button.z-btn-primary-negro:active,
a.z-btn-primary-negro:active {
  background-color: var(--bs--color-secundario-negro-hover);
}
div.z-btn-primary-negro[disabled],
button.z-btn-primary-negro[disabled],
a.z-btn-primary-negro[disabled] {
  cursor: not-allowed;
  background-color: var(--bs--color-grises-gris-50);
  color: var(--bs--color-grises-gris-40);
}
div.z-btn-primary-negro[disabled]:hover,
button.z-btn-primary-negro[disabled]:hover,
a.z-btn-primary-negro[disabled]:hover {
  color: var(--bs--color-grises-gris-40);
  background-color: var(--bs--color-grises-gris-50);
}
div.z-btn-primary-negro[disabled].icon figure img,
button.z-btn-primary-negro[disabled].icon figure img,
a.z-btn-primary-negro[disabled].icon figure img {
  opacity: 0.3;
}
div.z-btn-primary-blanco,
button.z-btn-primary-blanco,
a.z-btn-primary-blanco {
  background-color: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-naranja);
}
div.z-btn-primary-blanco:hover, div.z-btn-primary-blanco:active,
button.z-btn-primary-blanco:hover,
button.z-btn-primary-blanco:active,
a.z-btn-primary-blanco:hover,
a.z-btn-primary-blanco:active {
  color: var(--bs--color-secundario-naranja-hover);
}
div.z-btn.full,
button.z-btn.full,
a.z-btn.full {
  width: 100%;
}
div.z-btn.regular,
button.z-btn.regular,
a.z-btn.regular {
  font-family: "overpassregular", sans-serif;
}
div.z-btn-download,
button.z-btn-download,
a.z-btn-download {
  color: var(--bs--color-principal-naranja);
  background: none;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
div.z-btn-download span,
button.z-btn-download span,
a.z-btn-download span {
  flex: 1;
  text-align: left;
}
div.z-btn-download svg,
button.z-btn-download svg,
a.z-btn-download svg {
  fill: var(--bs--color-principal-naranja);
}
div.z-btn-download:hover,
button.z-btn-download:hover,
a.z-btn-download:hover {
  color: var(--bs--color-secundario-naranja-hover);
}
div.z-btn-download:hover svg,
button.z-btn-download:hover svg,
a.z-btn-download:hover svg {
  fill: var(--bs--color-secundario-naranja-hover);
}
div.z-btn-download:active,
button.z-btn-download:active,
a.z-btn-download:active {
  color: var(--bs--color-secundario-naranja-hover);
  border: 2px solid var(--bs--color-secundario-naranja-hover);
}
div.z-btn-download figure,
button.z-btn-download figure,
a.z-btn-download figure {
  margin: 0;
  padding: 0;
  height: 24px;
}
div.z-btn-download figure:first-child,
button.z-btn-download figure:first-child,
a.z-btn-download figure:first-child {
  margin-right: 6px;
}
@media only screen and (min-width : 600px) {
  div.z-btn-download figure:first-child,
button.z-btn-download figure:first-child,
a.z-btn-download figure:first-child {
    margin-right: 10px;
  }
}
div.z-btn-download figure:last-child,
button.z-btn-download figure:last-child,
a.z-btn-download figure:last-child {
  margin-left: 6px;
}
@media only screen and (min-width : 600px) {
  div.z-btn-download figure:last-child,
button.z-btn-download figure:last-child,
a.z-btn-download figure:last-child {
    margin-left: 10px;
  }
}
div.z-btn-download figure svg,
button.z-btn-download figure svg,
a.z-btn-download figure svg {
  height: 24px;
  width: auto;
}
div.z-btn-download[disabled],
button.z-btn-download[disabled],
a.z-btn-download[disabled] {
  cursor: not-allowed;
  color: var(--bs--color-secundario-naranja-20);
  background: none !important;
}
div.z-btn-download[disabled] svg,
button.z-btn-download[disabled] svg,
a.z-btn-download[disabled] svg {
  fill: var(--bs--color-secundario-naranja-20);
}
div.z-btn-download[disabled]:hover,
button.z-btn-download[disabled]:hover,
a.z-btn-download[disabled]:hover {
  color: var(--bs--color-secundario-naranja-20);
  background-color: var(--bs--color-secundario-naranja-20);
}
div.z-btn-download[disabled] figure img,
button.z-btn-download[disabled] figure img,
a.z-btn-download[disabled] figure img {
  opacity: 0.3;
}
div.z-btn-secondary,
button.z-btn-secondary,
a.z-btn-secondary {
  color: var(--bs--color-principal-naranja);
  border: 2px solid var(--bs--color-principal-naranja);
  background: none;
}
div.z-btn-secondary.no-border,
button.z-btn-secondary.no-border,
a.z-btn-secondary.no-border {
  border-color: rgba(1, 1, 1, 0);
}
div.z-btn-secondary.no-border:hover,
button.z-btn-secondary.no-border:hover,
a.z-btn-secondary.no-border:hover {
  border-color: rgba(1, 1, 1, 0);
}
div.z-btn-secondary:hover,
button.z-btn-secondary:hover,
a.z-btn-secondary:hover {
  color: var(--bs--color-secundario-naranja-hover);
  border: 2px solid var(--bs--color-secundario-naranja-hover);
}
div.z-btn-secondary:active,
button.z-btn-secondary:active,
a.z-btn-secondary:active {
  color: var(--bs--color-secundario-naranja-hover);
  border: 2px solid var(--bs--color-secundario-naranja-hover);
}
div.z-btn-secondary[disabled],
button.z-btn-secondary[disabled],
a.z-btn-secondary[disabled] {
  cursor: not-allowed;
  color: var(--bs--color-secundario-naranja-20);
  border: 2px solid var(--bs--color-secundario-naranja-20);
  background: none !important;
}
div.z-btn-secondary[disabled]:hover,
button.z-btn-secondary[disabled]:hover,
a.z-btn-secondary[disabled]:hover {
  color: var(--bs--color-secundario-naranja-20);
  background-color: var(--bs--color-secundario-naranja-20);
}
div.z-btn-secondary[disabled].icon figure img,
button.z-btn-secondary[disabled].icon figure img,
a.z-btn-secondary[disabled].icon figure img {
  opacity: 0.3;
}
div.z-btn-secondary-negro,
button.z-btn-secondary-negro,
a.z-btn-secondary-negro {
  color: var(--bs--color-principal-negro);
  border: 2px solid var(--bs--color-principal-negro);
}
div.z-btn-secondary-negro:hover,
button.z-btn-secondary-negro:hover,
a.z-btn-secondary-negro:hover {
  color: var(--bs--color-secundario-negro-hover);
  border: 2px solid var(--bs--color-secundario-negro-hover);
}
div.z-btn-secondary-negro:active,
button.z-btn-secondary-negro:active,
a.z-btn-secondary-negro:active {
  color: var(--bs--color-secundario-negro-hover);
  border: 2px solid var(--bs--color-secundario-negro-hover);
}
div.z-btn-secondary-negro[disabled],
button.z-btn-secondary-negro[disabled],
a.z-btn-secondary-negro[disabled] {
  cursor: not-allowed;
  color: var(--bs--color-grises-gris-40);
  border: 2px solid var(--bs--color-grises-gris-40);
  background: none !important;
}
div.z-btn-secondary-negro[disabled]:hover,
button.z-btn-secondary-negro[disabled]:hover,
a.z-btn-secondary-negro[disabled]:hover {
  color: var(--bs--color-grises-gris-40);
  background-color: var(--bs--color-grises-gris-40);
}
div.z-btn-secondary-negro[disabled].icon figure img,
button.z-btn-secondary-negro[disabled].icon figure img,
a.z-btn-secondary-negro[disabled].icon figure img {
  opacity: 0.3;
}
div.z-btn.icon,
button.z-btn.icon,
a.z-btn.icon {
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  min-width: 60px;
}
@media only screen and (min-width : 600px) {
  div.z-btn.icon,
button.z-btn.icon,
a.z-btn.icon {
    justify-content: center;
    align-items: center;
  }
}
div.z-btn.icon span,
button.z-btn.icon span,
a.z-btn.icon span {
  margin: 0 4px;
  text-align: center;
  flex: 1;
  padding-right: 36px;
}
@media only screen and (min-width : 600px) {
  div.z-btn.icon span,
button.z-btn.icon span,
a.z-btn.icon span {
    margin: 0 8px;
    flex: none;
    text-align: left;
    padding-right: 0px;
  }
}
div.z-btn.icon figure,
button.z-btn.icon figure,
a.z-btn.icon figure {
  margin: 0;
  padding: 0;
  height: 24px;
}
div.z-btn.icon figure:first-child,
button.z-btn.icon figure:first-child,
a.z-btn.icon figure:first-child {
  margin-right: 6px;
}
@media only screen and (min-width : 600px) {
  div.z-btn.icon figure:first-child,
button.z-btn.icon figure:first-child,
a.z-btn.icon figure:first-child {
    margin-right: 10px;
  }
}
div.z-btn.icon figure:last-child,
button.z-btn.icon figure:last-child,
a.z-btn.icon figure:last-child {
  margin-left: 6px;
}
@media only screen and (min-width : 600px) {
  div.z-btn.icon figure:last-child,
button.z-btn.icon figure:last-child,
a.z-btn.icon figure:last-child {
    margin-left: 10px;
  }
}
div.z-btn.icon figure img,
button.z-btn.icon figure img,
a.z-btn.icon figure img {
  height: 24px;
  width: auto;
}
div.z-btn.icon.mobile-icon-reduction,
button.z-btn.icon.mobile-icon-reduction,
a.z-btn.icon.mobile-icon-reduction {
  min-width: 20px;
  padding: 0 8px;
  margin: 0px;
}
div.z-btn.icon.mobile-icon-reduction figure,
button.z-btn.icon.mobile-icon-reduction figure,
a.z-btn.icon.mobile-icon-reduction figure {
  height: 28px;
}
@media only screen and (min-width : 1000px) {
  div.z-btn.icon.mobile-icon-reduction figure,
button.z-btn.icon.mobile-icon-reduction figure,
a.z-btn.icon.mobile-icon-reduction figure {
    height: 24px;
  }
}
div.z-btn.icon.mobile-icon-reduction figure img,
button.z-btn.icon.mobile-icon-reduction figure img,
a.z-btn.icon.mobile-icon-reduction figure img {
  height: 28px;
}
@media only screen and (min-width : 1000px) {
  div.z-btn.icon.mobile-icon-reduction figure img,
button.z-btn.icon.mobile-icon-reduction figure img,
a.z-btn.icon.mobile-icon-reduction figure img {
    height: 24px;
  }
}
div.z-btn.icon.mobile-icon-reduction figure:first-child,
button.z-btn.icon.mobile-icon-reduction figure:first-child,
a.z-btn.icon.mobile-icon-reduction figure:first-child {
  margin-right: 0px;
}
@media only screen and (min-width : 1000px) {
  div.z-btn.icon.mobile-icon-reduction figure:first-child,
button.z-btn.icon.mobile-icon-reduction figure:first-child,
a.z-btn.icon.mobile-icon-reduction figure:first-child {
    margin-right: 6px;
  }
}
div.z-btn.icon.mobile-icon-reduction figure:last-child,
button.z-btn.icon.mobile-icon-reduction figure:last-child,
a.z-btn.icon.mobile-icon-reduction figure:last-child {
  margin-left: 0px;
}
@media only screen and (min-width : 1000px) {
  div.z-btn.icon.mobile-icon-reduction figure:last-child,
button.z-btn.icon.mobile-icon-reduction figure:last-child,
a.z-btn.icon.mobile-icon-reduction figure:last-child {
    margin-left: 10px;
  }
}
div.z-btn.icon.mobile-icon-reduction span,
button.z-btn.icon.mobile-icon-reduction span,
a.z-btn.icon.mobile-icon-reduction span {
  display: none;
}
@media only screen and (min-width : 1000px) {
  div.z-btn.icon.mobile-icon-reduction,
button.z-btn.icon.mobile-icon-reduction,
a.z-btn.icon.mobile-icon-reduction {
    margin: 6px;
    padding: 0 16px;
    min-width: 60px;
  }
  div.z-btn.icon.mobile-icon-reduction span,
button.z-btn.icon.mobile-icon-reduction span,
a.z-btn.icon.mobile-icon-reduction span {
    display: inline-block;
  }
}
div.z-btn-form,
button.z-btn-form,
a.z-btn-form {
  height: 48px;
  background-color: var(--bs--color-principal-naranja);
  outline: var(--bs--color-principal-naranja) 1px solid;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  margin: 0;
  padding: 0 16px;
  min-width: 0px;
  width: auto !important;
  max-width: 120px;
  color: var(--bs--color-principal-blanco);
  -webkit-transition: all 0s linear;
  -moz-transition: all 0s linear;
  -ms-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
}
div.z-btn-form.auto,
button.z-btn-form.auto,
a.z-btn-form.auto {
  max-width: max-content;
}
div.z-btn-form.icons,
button.z-btn-form.icons,
a.z-btn-form.icons {
  cursor: pointer;
}
div.z-btn-form.icons figure,
button.z-btn-form.icons figure,
a.z-btn-form.icons figure {
  margin: 0;
}
div.z-btn-form.no-bg,
button.z-btn-form.no-bg,
a.z-btn-form.no-bg {
  background: none;
  outline: none;
}
div.z-btn-form.no-bg:hover,
button.z-btn-form.no-bg:hover,
a.z-btn-form.no-bg:hover {
  background-color: none !important;
  opacity: 0.5;
}
div.z-btn-form.float,
button.z-btn-form.float,
a.z-btn-form.float {
  position: absolute;
  top: 0;
  right: 0px;
}
div.z-btn-form.numbers,
button.z-btn-form.numbers,
a.z-btn-form.numbers {
  position: absolute;
  height: 38.4px !important;
  width: 38.4px !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: block;
  margin: 0;
  padding: 0;
  top: 4.8px;
}
div.z-btn-form.numbers:after,
button.z-btn-form.numbers:after,
a.z-btn-form.numbers:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 50%;
  background-color: white;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
div.z-btn-form.numbers.less,
button.z-btn-form.numbers.less,
a.z-btn-form.numbers.less {
  z-index: 12;
  left: 6px;
}
div.z-btn-form.numbers.plus,
button.z-btn-form.numbers.plus,
a.z-btn-form.numbers.plus {
  z-index: 13;
  right: 6px;
}
div.z-btn-form.numbers.plus:before,
button.z-btn-form.numbers.plus:before,
a.z-btn-form.numbers.plus:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 4px;
  background-color: white;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
div.z-btn-form.password,
button.z-btn-form.password,
a.z-btn-form.password {
  background: url(~src/assets/img/svg/icon-watch-off.svg) center center no-repeat;
}
div.z-btn-form.password.active,
button.z-btn-form.password.active,
a.z-btn-form.password.active {
  background: url(~src/assets/img/svg/icon-watch.svg) center center no-repeat;
}
div.z-btn-form:hover:not(.audio):not(.password):not(.no-bg),
button.z-btn-form:hover:not(.audio):not(.password):not(.no-bg),
a.z-btn-form:hover:not(.audio):not(.password):not(.no-bg) {
  background-color: var(--bs--color-secundario-naranja-hover);
  outline: var(--bs--color-secundario-naranja-hover) 1px solid;
}
div.z-btn-form[disabled],
button.z-btn-form[disabled],
a.z-btn-form[disabled] {
  cursor: not-allowed;
  background-color: var(--bs--color-secundario-naranja-20);
  outline: var(--bs--color-secundario-naranja-20) 1px solid;
}
div.z-btn-form[disabled]:hover:not(.audio):not(.password):not(.no-bg),
button.z-btn-form[disabled]:hover:not(.audio):not(.password):not(.no-bg),
a.z-btn-form[disabled]:hover:not(.audio):not(.password):not(.no-bg) {
  background-color: var(--bs--color-secundario-naranja-20);
  outline: var(--bs--color-secundario-naranja-20) 1px solid;
}
div.z-btn-form[disabled].icon figure img,
button.z-btn-form[disabled].icon figure img,
a.z-btn-form[disabled].icon figure img {
  opacity: 0.8;
}
div.z-btn-link,
button.z-btn-link,
a.z-btn-link {
  height: auto;
  margin: 0 !important;
  text-transform: none;
  min-width: auto !important;
  width: auto !important;
  padding: 0;
  cursor: pointer;
  color: var(--bs--color-principal-naranja);
  text-decoration: underline;
  font-family: "overpassbold", sans-serif;
}
div.z-btn-link:hover,
button.z-btn-link:hover,
a.z-btn-link:hover {
  color: var(--bs--color-secundario-naranja-hover);
}
div.z-btn-link[disabled],
button.z-btn-link[disabled],
a.z-btn-link[disabled] {
  cursor: not-allowed;
  color: var(--bs--color-secundario-naranja-20);
}
div.z-btn-link.blanco,
button.z-btn-link.blanco,
a.z-btn-link.blanco {
  color: var(--bs--color-principal-blanco);
}
div.z-btn-link.blanco:hover,
button.z-btn-link.blanco:hover,
a.z-btn-link.blanco:hover {
  color: var(--bs--color-grises-gris-20);
}
div.z-btn-link.negro,
button.z-btn-link.negro,
a.z-btn-link.negro {
  color: var(--bs--color-principal-negro);
}
div.z-btn-link.negro:hover,
button.z-btn-link.negro:hover,
a.z-btn-link.negro:hover {
  color: var(--bs--color-grises-gris-40);
}
div.z-btn.compacto,
button.z-btn.compacto,
a.z-btn.compacto {
  min-width: min-content;
  height: auto;
  min-height: 26px;
  padding: 0 8px;
}

.z-informacion-lateral {
  width: 100%;
  height: 100%;
  display: block;
}
.z-informacion-lateral figure {
  flex: 0 0 100%;
}
.z-informacion-lateral figure img {
  height: 30px;
}
@media only screen and (min-width : 1000px) {
  .z-informacion-lateral figure img {
    height: auto;
  }
}
.z-informacion-lateral h1 {
  font-family: "overpassregular", sans-serif;
  font-size: 2.2rem;
  text-align: left;
  line-height: 1;
  color: var(--bs--color-principal-negro);
}
@media only screen and (min-width : 600px) {
  .z-informacion-lateral h1 {
    font-size: 2.6rem;
  }
}
@media only screen and (min-width : 1000px) {
  .z-informacion-lateral h1 {
    line-height: 1.3;
    font-size: 3rem;
  }
}
.z-informacion-lateral h1 span {
  font-family: "overpassbold", sans-serif;
  display: block;
  font-size: 3rem;
}
@media only screen and (min-width : 600px) {
  .z-informacion-lateral h1 span {
    font-size: 4rem;
  }
}
@media only screen and (min-width : 1270px) {
  .z-informacion-lateral h1 span {
    font-size: 5rem;
  }
}
.z-informacion-lateral.claro h1 {
  color: var(--bs--color-principal-blanco);
}

.z-simple-data {
  width: 100%;
  display: block;
  margin-bottom: 16px;
}
.z-simple-data p,
.z-simple-data h3 {
  color: var(--bs--color-principal-negro);
  line-height: normal;
}
.z-simple-data.naranja p,
.z-simple-data.naranja h3,
.z-simple-data.naranja p.h3,
.z-simple-data.naranja p.h4 {
  color: var(--bs--color-principal-naranja);
}
.z-simple-data.gris-80 p,
.z-simple-data.gris-80 h3,
.z-simple-data.gris-80 p.h3,
.z-simple-data.gris-80 p.h4 {
  color: var(--bs--color-grises-gris-80);
}
.z-simple-data:not(.invertido) h3,
.z-simple-data:not(.invertido) p.h3,
.z-simple-data:not(.invertido) p.h4,
.z-simple-data:not(.invertido) h4 {
  font-family: "overpassregular", sans-serif;
  font-size: 1.4rem;
}
.z-simple-data:not(.invertido) h3 + p,
.z-simple-data:not(.invertido) p.h3 + p,
.z-simple-data:not(.invertido) p.h4 + p,
.z-simple-data:not(.invertido) h4 + p {
  font-family: "overpassbold", sans-serif;
  font-size: 1.6rem;
}
.z-simple-data.invertido p {
  font-family: "overpassbold", sans-serif;
  font-size: 1.3rem;
}
.z-simple-data.invertido h3,
.z-simple-data.invertido p.h3,
.z-simple-data.invertido p.h4,
.z-simple-data.invertido h4 {
  font-family: "overpassregular", sans-serif;
  font-size: 1.6rem;
}

.z-forms {
  width: 100%;
  display: block;
  margin-bottom: 8px;
  position: relative;
}
.z-forms .prefix {
  position: absolute;
  left: 0px;
  line-height: 48px;
  width: auto;
  min-width: min-content;
  border-right: 1px solid var(--bs--color-grises-gris-50) !important;
  color: var(--bs--color-grises-gris-80) !important;
  z-index: 13;
  height: 48px;
  padding: 0 8px;
  user-select: none;
}
.z-forms .prefix + input {
  padding-left: 36px;
}
.z-forms [disabled] ~ .prefix {
  cursor: not-allowed;
  border-right: 1px solid var(--bs--color-grises-gris-40) !important;
  color: var(--bs--color-grises-gris-40) !important;
}
.z-forms .mix {
  position: relative;
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.z-forms .mix input.p-inputtext {
  flex: 1;
  padding-right: 40px;
}
.z-forms .mix.number input.p-inputtext {
  text-align: center !important;
  padding-right: 0px;
}
.z-forms .mix .btn {
  margin-bottom: 6px;
}
.z-forms .mix p-checkbox {
  flex: 0 0 100%;
}
@media only screen and (min-width : 600px) {
  .z-forms .mix p-checkbox {
    flex: 1;
  }
}
.z-forms p-checkbox {
  width: 100%;
}

figure.z-iconos {
  height: 64px !important;
  width: 64px !important;
  margin: 0;
  padding: 0;
  /*Sistema de tamaños, la caja */
  display: inline-block;
}
figure.z-iconos.plain {
  height: auto !important;
  width: auto !important;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
figure.z-iconos img {
  width: 100%;
  height: auto;
}
figure.z-iconos.block {
  display: block;
}
figure.z-iconos.z-center {
  text-align: center;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
figure.z-iconos.z-center img {
  display: inline-block;
  margin: 0 auto;
}
figure.z-iconos.w-5 {
  height: 5px !important;
  width: 5px !important;
}
figure.z-iconos.w-5 img {
  height: 5px !important;
  width: 5px !important;
}
figure.z-iconos.w-10 {
  height: 10px !important;
  width: 10px !important;
}
figure.z-iconos.w-10 img {
  height: 10px !important;
  width: 10px !important;
}
figure.z-iconos.w-15 {
  height: 15px !important;
  width: 15px !important;
}
figure.z-iconos.w-15 img {
  height: 15px !important;
  width: 15px !important;
}
figure.z-iconos.w-20 {
  height: 20px !important;
  width: 20px !important;
}
figure.z-iconos.w-20 img {
  height: 20px !important;
  width: 20px !important;
}
figure.z-iconos.w-25 {
  height: 25px !important;
  width: 25px !important;
}
figure.z-iconos.w-25 img {
  height: 25px !important;
  width: 25px !important;
}
figure.z-iconos.w-30 {
  height: 30px !important;
  width: 30px !important;
}
figure.z-iconos.w-30 img {
  height: 30px !important;
  width: 30px !important;
}
figure.z-iconos.w-35 {
  height: 35px !important;
  width: 35px !important;
}
figure.z-iconos.w-35 img {
  height: 35px !important;
  width: 35px !important;
}
figure.z-iconos.w-40 {
  height: 40px !important;
  width: 40px !important;
}
figure.z-iconos.w-40 img {
  height: 40px !important;
  width: 40px !important;
}
figure.z-iconos.w-45 {
  height: 45px !important;
  width: 45px !important;
}
figure.z-iconos.w-45 img {
  height: 45px !important;
  width: 45px !important;
}
figure.z-iconos.w-50 {
  height: 50px !important;
  width: 50px !important;
}
figure.z-iconos.w-50 img {
  height: 50px !important;
  width: 50px !important;
}
figure.z-iconos.w-55 {
  height: 55px !important;
  width: 55px !important;
}
figure.z-iconos.w-55 img {
  height: 55px !important;
  width: 55px !important;
}
figure.z-iconos.w-60 {
  height: 60px !important;
  width: 60px !important;
}
figure.z-iconos.w-60 img {
  height: 60px !important;
  width: 60px !important;
}
figure.z-iconos.w-65 {
  height: 65px !important;
  width: 65px !important;
}
figure.z-iconos.w-65 img {
  height: 65px !important;
  width: 65px !important;
}
figure.z-iconos.w-70 {
  height: 70px !important;
  width: 70px !important;
}
figure.z-iconos.w-70 img {
  height: 70px !important;
  width: 70px !important;
}
figure.z-iconos.w-75 {
  height: 75px !important;
  width: 75px !important;
}
figure.z-iconos.w-75 img {
  height: 75px !important;
  width: 75px !important;
}
figure.z-iconos.w-80 {
  height: 80px !important;
  width: 80px !important;
}
figure.z-iconos.w-80 img {
  height: 80px !important;
  width: 80px !important;
}
figure.z-iconos.w-85 {
  height: 85px !important;
  width: 85px !important;
}
figure.z-iconos.w-85 img {
  height: 85px !important;
  width: 85px !important;
}
figure.z-iconos.w-90 {
  height: 90px !important;
  width: 90px !important;
}
figure.z-iconos.w-90 img {
  height: 90px !important;
  width: 90px !important;
}
figure.z-iconos.w-95 {
  height: 95px !important;
  width: 95px !important;
}
figure.z-iconos.w-95 img {
  height: 95px !important;
  width: 95px !important;
}
figure.z-iconos.w-100 {
  height: 100px !important;
  width: 100px !important;
}
figure.z-iconos.w-100 img {
  height: 100px !important;
  width: 100px !important;
}
figure.z-iconos.w-105 {
  height: 105px !important;
  width: 105px !important;
}
figure.z-iconos.w-105 img {
  height: 105px !important;
  width: 105px !important;
}
figure.z-iconos.w-110 {
  height: 110px !important;
  width: 110px !important;
}
figure.z-iconos.w-110 img {
  height: 110px !important;
  width: 110px !important;
}
figure.z-iconos.w-115 {
  height: 115px !important;
  width: 115px !important;
}
figure.z-iconos.w-115 img {
  height: 115px !important;
  width: 115px !important;
}
figure.z-iconos.w-120 {
  height: 120px !important;
  width: 120px !important;
}
figure.z-iconos.w-120 img {
  height: 120px !important;
  width: 120px !important;
}
figure.z-iconos.w-125 {
  height: 125px !important;
  width: 125px !important;
}
figure.z-iconos.w-125 img {
  height: 125px !important;
  width: 125px !important;
}
figure.z-iconos.w-130 {
  height: 130px !important;
  width: 130px !important;
}
figure.z-iconos.w-130 img {
  height: 130px !important;
  width: 130px !important;
}
figure.z-iconos.w-135 {
  height: 135px !important;
  width: 135px !important;
}
figure.z-iconos.w-135 img {
  height: 135px !important;
  width: 135px !important;
}
figure.z-iconos.w-140 {
  height: 140px !important;
  width: 140px !important;
}
figure.z-iconos.w-140 img {
  height: 140px !important;
  width: 140px !important;
}
figure.z-iconos.w-145 {
  height: 145px !important;
  width: 145px !important;
}
figure.z-iconos.w-145 img {
  height: 145px !important;
  width: 145px !important;
}
figure.z-iconos.w-150 {
  height: 150px !important;
  width: 150px !important;
}
figure.z-iconos.w-150 img {
  height: 150px !important;
  width: 150px !important;
}
figure.z-iconos.w-155 {
  height: 155px !important;
  width: 155px !important;
}
figure.z-iconos.w-155 img {
  height: 155px !important;
  width: 155px !important;
}
figure.z-iconos.w-16 {
  height: 16px !important;
  width: 16px !important;
}
figure.z-iconos.w-16 img {
  height: 16px !important;
  width: 16px !important;
}
figure.z-iconos.w-24 {
  height: 24px !important;
  width: 24px !important;
}
figure.z-iconos.w-24 img {
  height: 24px !important;
  width: 24px !important;
}
figure.z-iconos.w-32 {
  height: 32px !important;
  width: 32px !important;
}
figure.z-iconos.w-32 img {
  height: 32px !important;
  width: 32px !important;
}
figure.z-iconos.w-48 {
  height: 48px !important;
  width: 48px !important;
}
figure.z-iconos.w-48 img {
  height: 48px !important;
  width: 48px !important;
}
figure.z-iconos.w-64 {
  height: 64px !important;
  width: 64px !important;
}
figure.z-iconos.w-64 img {
  height: 64px !important;
  width: 64px !important;
}
figure.z-iconos.w-72 {
  height: 72px !important;
  width: 72px !important;
}
figure.z-iconos.w-72 img {
  height: 72px !important;
  width: 72px !important;
}
figure.z-iconos.w-96 {
  height: 96px !important;
  width: 96px !important;
}
figure.z-iconos.w-96 img {
  height: 96px !important;
  width: 96px !important;
}
figure.z-iconos.w-144 {
  height: 144px !important;
  width: 144px !important;
}
figure.z-iconos.w-144 img {
  height: 144px !important;
  width: 144px !important;
}

figure.z-imagen img {
  width: 100%;
  height: auto;
}

figure {
  display: inline-block;
}
figure.block {
  display: block;
}
figure.z-ilustraciones.w-64 {
  height: auto;
  width: 64px !important;
}
figure.z-ilustraciones.w-64 img {
  height: auto;
  width: 64px !important;
}
figure.z-ilustraciones.w-128 {
  height: auto;
  width: 128px !important;
}
figure.z-ilustraciones.w-128 img {
  height: auto;
  width: 128px !important;
}
figure.z-ilustraciones.w-256 {
  height: auto;
  width: 256px !important;
}
figure.z-ilustraciones.w-256 img {
  height: auto;
  width: 256px !important;
}

.z-selector {
  position: relative;
  min-height: 80px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 3px solid var(--bs--color-grises-gris-10);
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  margin: 6px 6px 6px 0;
  text-decoration: none;
  color: var(--bs--color-principal-negro);
  background-color: var(--bs--color-principal-blanco);
}
.z-selector.sombra {
  border: 3px solid var(--bs--color-principal-blanco);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.z-selector:hover {
  border: 3px solid var(--bs--color-secundario-naranja-50);
}
.z-selector.active.simple {
  background-color: var(--bs--color-principal-naranja);
  border: 3px solid var(--bs--color-principal-naranja);
}
.z-selector.active.simple .label {
  color: var(--bs--color-principal-blanco);
}
.z-selector.simple {
  width: auto;
  min-width: 50px;
  max-width: 200px;
  min-height: 50px;
}
.z-selector.simple span.label {
  line-height: 1;
}
@media only screen and (min-width : 1000px) {
  .z-selector {
    width: auto;
    max-width: 800px;
    min-width: 220px;
  }
}
.z-selector.w-100p {
  width: 100%;
}
.z-selector.w-5p {
  width: 5%;
}
.z-selector.w-10p {
  width: 10%;
}
.z-selector.w-15p {
  width: 15%;
}
.z-selector.w-20p {
  width: 20%;
}
.z-selector.w-25p {
  width: 25%;
}
.z-selector.w-30p {
  width: 30%;
}
.z-selector.w-35p {
  width: 35%;
}
.z-selector.w-40p {
  width: 40%;
}
.z-selector.w-45p {
  width: 45%;
}
.z-selector.w-50p {
  width: 50%;
}
.z-selector.w-55p {
  width: 55%;
}
.z-selector.w-60p {
  width: 60%;
}
.z-selector.w-65p {
  width: 65%;
}
.z-selector.w-70p {
  width: 70%;
}
.z-selector.w-75p {
  width: 75%;
}
.z-selector.w-80p {
  width: 80%;
}
.z-selector.w-85p {
  width: 85%;
}
.z-selector.w-90p {
  width: 90%;
}
.z-selector.w-95p {
  width: 95%;
}
.z-selector.w-100p {
  width: 100%;
}
.z-selector.suspended figure {
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.z-selector.suspended span.label, .z-selector.suspended span.preface, .z-selector.suspended span.caption {
  color: var(--bs--color-grises-gris-50) !important;
}
.z-selector.suspended span.arrow, .z-selector.suspended span.delete {
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.z-selector[disabled] {
  border: 3px solid var(--bs--color-grises-gris-10);
  cursor: not-allowed;
}
.z-selector[disabled] span.caption, .z-selector[disabled] span.label, .z-selector[disabled] span.preface {
  color: var(--bs--color-grises-gris-50);
  color: var(--bs--color-grises-gris-50);
}
.z-selector[disabled] span.arrow, .z-selector[disabled] span.delete {
  opacity: 0.5;
}
.z-selector > figure {
  margin-right: 16px;
  width: 30px;
  height: 30px;
  display: inline-block;
}
.z-selector > figure img {
  width: 100%;
  height: auto;
}
.z-selector.grande > figure {
  height: auto;
  max-height: 48px;
  width: 48px;
}
.z-selector.superior {
  justify-content: space-between;
  align-items: flex-start;
}
.z-selector.superior span.arrow {
  margin-top: 20px;
}
.z-selector div.datos-informacion {
  width: 100%;
  flex: 0 0 100%;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
.z-selector div.datos-informacion .dato span {
  display: block;
  color: var(--bs--color-grises-gris-50);
  font-size: 1.4rem;
  font-family: "overpassregular", sans-serif;
  line-height: 1.3;
}
.z-selector div.datos-informacion .dato h4 {
  display: block;
  color: var(--bs--color-principal-azul);
  font-size: 1.8rem;
  font-family: "overpassbold", sans-serif;
}
.z-selector div.datos-informacion .dato:last-child > span,
.z-selector div.datos-informacion .dato:last-child > h4 {
  text-align: right;
}
.z-selector div.datos-informacion .dato.estado {
  flex: 1;
}
.z-selector div.datos-informacion .dato.monto {
  flex: 1;
}
.z-selector span {
  text-align: left;
}
.z-selector span.arrow {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url(~src/assets/img/svg/icon-arrow-next.svg) center center no-repeat;
  margin-left: 16px;
}
.z-selector span.delete {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url(~src/assets/img/svg/icon_close_naranja.svg) center center no-repeat;
  background-size: auto 18px;
  margin-left: 16px;
}
.z-selector span.label {
  font-family: "overpassbold", sans-serif;
  font-size: 1.5rem;
  color: var(--bs--color-principal-negro);
  flex: 1;
  line-height: normal;
}
@media only screen and (min-width : 1000px) {
  .z-selector span.label {
    line-height: 2.2rem;
  }
}
.z-selector span.label + .caption, .z-selector span.label + .z-caption {
  margin-left: 16px;
}
.z-selector span.z-title {
  display: block;
  font-family: "overpassbold", sans-serif;
  font-size: 1.5rem;
  color: var(--bs--color-principal-negro);
  line-height: normal;
}
.z-selector span.z-description {
  display: block;
  font-family: "overpassregular", sans-serif;
  font-size: 1rem;
  color: var(--bs--color-grises-gris-80);
  line-height: normal;
}
@media only screen and (min-width : 600px) {
  .z-selector span.z-description {
    font-size: 1.1rem;
  }
}
.z-selector span.preface {
  display: block;
  font-family: "overpassregular", sans-serif;
  font-size: 1.3rem;
  color: var(--bs--color-principal-naranja);
  line-height: normal;
}
.z-selector span.z-caption {
  display: block;
  font-family: "overpassregular", sans-serif;
  font-size: 1.3rem;
  color: var(--bs--color-grises-gris-80);
  line-height: normal;
}
.z-selector span.z-caption strong {
  color: var(--bs--color-principal-naranja);
  font-family: "overpassbold", sans-serif;
}
.z-selector.expandible {
  width: 100%;
}
.z-selector.expandible[open] > summary.cta {
  padding: 0px 0px 10px 0;
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
}
.z-selector.expandible[open] span.arrow {
  background: url(~src/assets/img/svg/icon-arrow-down.svg) center center no-repeat;
}
.z-selector.expandible > summary::-webkit-details-marker {
  display: none;
}
.z-selector.expandible .data {
  margin-top: 16px;
}
.z-selector.expandible > summary.cta {
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  cursor: pointer;
  width: 100%;
  min-height: 50px;
  padding: 0px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
.z-selector.expandible > summary.cta > figure {
  margin-right: 16px;
  width: 30px;
  height: 30px;
  display: inline-block;
}
.z-selector.expandible > summary.cta > figure img {
  width: 100%;
  height: auto;
}
.z-selector.expandible.grande > summary.cta > figure {
  height: auto;
  max-height: 48px;
  width: 48px;
}
.z-selector.visited {
  border: 3px solid var(--bs--color-secundario-naranja-50);
}
.z-selector.visited span.arrow {
  background-image: url(~src/assets/img/svg/checkbox_naranja_check.svg);
}

.z-desplegable-resumen {
  width: 100%;
  display: block;
  margin: 4px 0 0;
  /**/
}
.z-desplegable-resumen .titular {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  padding: 4px 0;
  background: var(--bs--color-grises-gris-5);
  position: relative;
}
.z-desplegable-resumen .titular::before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 29px);
  left: 19px;
  bottom: 0;
  display: block;
  background: var(--bs--color-principal-naranja);
  z-index: 1;
}
@media only screen and (max-width : 500px) {
  .z-desplegable-resumen .titular::before {
    left: 10px;
  }
}
@media only screen and (max-width : 500px) {
  .z-desplegable-resumen .titular {
    background: none;
  }
}
.z-desplegable-resumen .titular figure {
  z-index: 10;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  height: 40px;
  width: 40px;
}
.z-desplegable-resumen .titular figure img {
  margin-top: 8px;
  height: 22px;
  width: auto;
  z-index: 10;
}
@media only screen and (max-width : 500px) {
  .z-desplegable-resumen .titular figure {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.z-desplegable-resumen .titular figcaption h2 {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: var(--bs--color-principal-naranja);
  font-family: "overpassbold", sans-serif;
  text-transform: uppercase;
}
.z-desplegable-resumen .titular figcaption h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}
.z-desplegable-resumen .titular figcaption h3 span {
  font-family: "overpassregular", sans-serif;
}
.z-desplegable-resumen .deploy .data {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  padding-right: 8px;
  position: relative;
}
@media only screen and (max-width : 500px) {
  .z-desplegable-resumen .deploy .data {
    padding-right: 0;
  }
}
.z-desplegable-resumen .deploy .data::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  left: 19px;
  top: 0;
  display: block;
  background: var(--bs--color-principal-naranja);
}
@media only screen and (max-width : 500px) {
  .z-desplegable-resumen .deploy .data::before {
    left: 10px;
  }
}
.z-desplegable-resumen .deploy .data span {
  width: 36px;
  height: 40px;
  position: relative;
}
.z-desplegable-resumen .deploy .data span::after {
  display: block;
  height: 18px;
  width: 18px;
  border: 2px solid var(--bs--color-principal-naranja);
  content: "";
  position: absolute;
  left: 9px;
  top: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--bs--color-principal-blanco) center center no-repeat;
}
@media only screen and (max-width : 500px) {
  .z-desplegable-resumen .deploy .data span::after {
    left: 0;
  }
}
.z-desplegable-resumen .deploy .data .data-item {
  flex: 1;
  min-height: 4rem;
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
}
.z-desplegable-resumen .deploy .data.completed span::after {
  background-image: url(~src/assets/img/svg/checkbox_naranja_check.svg);
}
.z-desplegable-resumen .deploy .data.completed button.data-interaction {
  line-height: 4rem;
  color: var(--bs--color-principal-negro);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  padding: 0 0 0 4px;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  text-align: left;
  background: url(~src/assets/img/svg/icon-arrow-down.svg) calc(100% - 10px) center no-repeat;
  border: none;
}
.z-desplegable-resumen .deploy .data.completed button.data-interaction.active {
  background-image: url(~src/assets/img/svg/icon-arrow-up.svg);
  color: var(--bs--color-principal-negro);
}
.z-desplegable-resumen .deploy .data.completed button.data-interaction.active:hover {
  color: var(--bs--color-grises-gris-80);
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.z-desplegable-resumen .deploy .data.completed button.data-interaction:hover {
  color: var(--bs--color-grises-gris-50);
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.z-desplegable-resumen .deploy .data.completed .data-item .extra {
  padding-left: 16px;
}
.z-desplegable-resumen .deploy .data.current::before {
  height: 50%;
}
.z-desplegable-resumen .deploy .data.current .data-item {
  background: var(--bs--color-secundario-naranja-10);
  padding: 0 4px 0 8px;
}
.z-desplegable-resumen .deploy .data.current .data-item h2 {
  color: var(--bs--color-principal-naranja);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  line-height: 4rem;
}

.z-pasos-indice {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.z-pasos-indice p-progressbar {
  margin-top: 4px;
  flex: 0 0 100%;
}
.z-pasos-indice .index {
  height: 24px;
  width: 24px;
  display: grid;
  place-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--bs--color-grises-gris-50);
  border: 2px solid var(--bs--color-grises-gris-50);
  font-size: 1.4rem;
  font-family: "overpassbold", sans-serif;
  text-align: center;
  padding: 0;
  margin: 0 8px 0 0;
}
.z-pasos-indice .pasos {
  color: var(--bs--color-grises-gris-80);
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: "overpassregular", sans-serif;
  text-align: right;
}
.z-pasos-indice h2.z-contenido {
  font-family: "overpassbold", sans-serif;
  flex: 1;
  color: var(--bs--color-grises-gris-50);
}
.z-pasos-indice.active .index {
  color: var(--bs--color-principal-naranja);
  border: 2px solid var(--bs--color-principal-naranja);
}
.z-pasos-indice.active h2 {
  color: var(--bs--color-principal-negro);
}
.z-pasos-indice.active h2 span {
  color: var(--bs--color-principal-naranja);
}
.z-pasos-indice.completed .index {
  color: rgba(0, 0, 0, 0);
  border: 2px solid var(--bs--color-principal-naranja);
  background: url(~src/assets/img/svg/checkbox_blanco_check.svg) var(--bs--color-principal-naranja) center center no-repeat;
  background-size: auto 12px;
}
.z-pasos-indice.completed h2 {
  color: var(--bs--color-principal-naranja);
}

.bloque-planes {
  background-color: var(--bs--color-principal-blanco);
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
}
.bloque-planes > .h3 {
  margin: 8px 0;
}
.bloque-planes > .h4 + .h1 {
  line-height: 1.3 !important;
}
.bloque-planes > .h1 {
  line-height: 2 !important;
}
.bloque-planes > section {
  width: 100%;
  padding: 4px 0;
  background-color: var(--bs--color-secundario-naranja-10);
}
.bloque-planes .z-btn-link {
  margin: 8px 0 !important;
  width: 100% !important;
  display: inline-block;
}
.bloque-planes > article {
  width: 100%;
  display: block;
  margin: 8px;
  border-top: var(--bs--color-grises-gris-20) 1px solid;
  border-bottom: var(--bs--color-grises-gris-20) 1px solid;
}
.bloque-planes > article > div {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  padding: 4px 6px;
}
.bloque-planes > article > div > p {
  font-size: 1.2rem;
  flex: 2;
  text-align: left;
}
.bloque-planes > article > div > p:last-child {
  flex: 1;
  text-align: right;
}
.bloque-planes p {
  width: 100%;
}
.z-page-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  animation-name: fadeIn;
  animation-duration: 0.1s;
}
.z-page-loader p {
  margin-top: 4px;
  flex: 0 0 100%;
  text-align: center;
  line-height: 6rem;
  text-transform: uppercase;
  font-family: "overpassbold", sans-serif !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.z-loader-html {
  display: block;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin: 0 auto;
}
.z-loader-html.inline-block {
  display: inline-block;
}
.z-loader-html::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid rgba(244, 244, 244, 0.4);
  border-top: 3px solid var(--bs--color-principal-naranja);
}
.z-loader-html.w-4 {
  height: 4px !important;
  width: 4px !important;
}
.z-loader-html.w-8 {
  height: 8px !important;
  width: 8px !important;
}
.z-loader-html.w-12 {
  height: 12px !important;
  width: 12px !important;
}
.z-loader-html.w-16 {
  height: 16px !important;
  width: 16px !important;
}
.z-loader-html.w-20 {
  height: 20px !important;
  width: 20px !important;
}
.z-loader-html.w-24 {
  height: 24px !important;
  width: 24px !important;
}
.z-loader-html.w-28 {
  height: 28px !important;
  width: 28px !important;
}
.z-loader-html.w-32 {
  height: 32px !important;
  width: 32px !important;
}
.z-loader-html.w-36 {
  height: 36px !important;
  width: 36px !important;
}
.z-loader-html.w-40 {
  height: 40px !important;
  width: 40px !important;
}
.z-loader-html.w-44 {
  height: 44px !important;
  width: 44px !important;
}
.z-loader-html.w-48 {
  height: 48px !important;
  width: 48px !important;
}
.z-loader-html.w-52 {
  height: 52px !important;
  width: 52px !important;
}
.z-loader-html.w-56 {
  height: 56px !important;
  width: 56px !important;
}
.z-loader-html.w-60 {
  height: 60px !important;
  width: 60px !important;
}
.z-loader-html.w-64 {
  height: 64px !important;
  width: 64px !important;
}
.z-loader-html.w-68 {
  height: 68px !important;
  width: 68px !important;
}
.z-loader-html.w-72 {
  height: 72px !important;
  width: 72px !important;
}
.z-loader-html.w-76 {
  height: 76px !important;
  width: 76px !important;
}
.z-loader-html.w-80 {
  height: 80px !important;
  width: 80px !important;
}
.z-loader-html.w-84 {
  height: 84px !important;
  width: 84px !important;
}
.z-loader-html.w-88 {
  height: 88px !important;
  width: 88px !important;
}
.z-loader-html.w-92 {
  height: 92px !important;
  width: 92px !important;
}
.z-loader-html.w-96 {
  height: 96px !important;
  width: 96px !important;
}
.z-loader-html.w-100 {
  height: 100px !important;
  width: 100px !important;
}
.z-loader-html.w-104 {
  height: 104px !important;
  width: 104px !important;
}
.z-loader-html.w-108 {
  height: 108px !important;
  width: 108px !important;
}
.z-loader-html.w-112 {
  height: 112px !important;
  width: 112px !important;
}
.z-loader-html.w-116 {
  height: 116px !important;
  width: 116px !important;
}
.z-loader-html.w-120 {
  height: 120px !important;
  width: 120px !important;
}
.z-loader-html.w-124 {
  height: 124px !important;
  width: 124px !important;
}
.z-loader-html.w-128 {
  height: 128px !important;
  width: 128px !important;
}
.z-loader-html.w-132 {
  height: 132px !important;
  width: 132px !important;
}
.z-loader-html.w-136 {
  height: 136px !important;
  width: 136px !important;
}
.z-loader-html.w-140 {
  height: 140px !important;
  width: 140px !important;
}
.z-loader-html.w-144 {
  height: 144px !important;
  width: 144px !important;
}
.z-loader-html.w-148 {
  height: 148px !important;
  width: 148px !important;
}
.z-loader-html.w-152 {
  height: 152px !important;
  width: 152px !important;
}
.z-loader-html.w-156 {
  height: 156px !important;
  width: 156px !important;
}
.z-loader-html.w-160 {
  height: 160px !important;
  width: 160px !important;
}
.z-loader-html.w-164 {
  height: 164px !important;
  width: 164px !important;
}
.z-loader-html.w-168 {
  height: 168px !important;
  width: 168px !important;
}
.z-loader-html.w-172 {
  height: 172px !important;
  width: 172px !important;
}
.z-loader-html.w-176 {
  height: 176px !important;
  width: 176px !important;
}
.z-loader-html.w-180 {
  height: 180px !important;
  width: 180px !important;
}
.z-loader-html.w-184 {
  height: 184px !important;
  width: 184px !important;
}
.z-loader-html.w-188 {
  height: 188px !important;
  width: 188px !important;
}
.z-loader-html.w-192 {
  height: 192px !important;
  width: 192px !important;
}
.z-loader-html.w-196 {
  height: 196px !important;
  width: 196px !important;
}
.z-loader-html.w-200 {
  height: 200px !important;
  width: 200px !important;
}
.z-loader-html.w-204 {
  height: 204px !important;
  width: 204px !important;
}
.z-loader-html.w-208 {
  height: 208px !important;
  width: 208px !important;
}
.z-loader-html.w-212 {
  height: 212px !important;
  width: 212px !important;
}
.z-loader-html.w-216 {
  height: 216px !important;
  width: 216px !important;
}
.z-loader-html.w-220 {
  height: 220px !important;
  width: 220px !important;
}
.z-loader-html.w-224 {
  height: 224px !important;
  width: 224px !important;
}
.z-loader-html.w-228 {
  height: 228px !important;
  width: 228px !important;
}
.z-loader-html.w-232 {
  height: 232px !important;
  width: 232px !important;
}
.z-loader-html.w-236 {
  height: 236px !important;
  width: 236px !important;
}
.z-loader-html.w-240 {
  height: 240px !important;
  width: 240px !important;
}
.z-loader-html.w-244 {
  height: 244px !important;
  width: 244px !important;
}
.z-loader-html.w-248 {
  height: 248px !important;
  width: 248px !important;
}
.z-loader-html.w-252 {
  height: 252px !important;
  width: 252px !important;
}
.z-loader-html.w-256 {
  height: 256px !important;
  width: 256px !important;
}
.z-loader-html.w-260 {
  height: 260px !important;
  width: 260px !important;
}
.z-loader-html.w-264 {
  height: 264px !important;
  width: 264px !important;
}
.z-loader-html.w-268 {
  height: 268px !important;
  width: 268px !important;
}
.z-loader-html.w-272 {
  height: 272px !important;
  width: 272px !important;
}
.z-loader-html.w-276 {
  height: 276px !important;
  width: 276px !important;
}
.z-loader-html.w-280 {
  height: 280px !important;
  width: 280px !important;
}
.z-loader-html.w-284 {
  height: 284px !important;
  width: 284px !important;
}
.z-loader-html.w-288 {
  height: 288px !important;
  width: 288px !important;
}
.z-loader-html.w-292 {
  height: 292px !important;
  width: 292px !important;
}
.z-loader-html.w-296 {
  height: 296px !important;
  width: 296px !important;
}
.z-loader-html.w-300 {
  height: 300px !important;
  width: 300px !important;
}
.z-loader-html.w-304 {
  height: 304px !important;
  width: 304px !important;
}
.z-loader-html.w-308 {
  height: 308px !important;
  width: 308px !important;
}
.z-loader-html.w-312 {
  height: 312px !important;
  width: 312px !important;
}
.z-loader-html.w-316 {
  height: 316px !important;
  width: 316px !important;
}
.z-loader-html.w-320 {
  height: 320px !important;
  width: 320px !important;
}
.z-loader-html.w-324 {
  height: 324px !important;
  width: 324px !important;
}
.z-loader-html.w-328 {
  height: 328px !important;
  width: 328px !important;
}
.z-loader-html.w-332 {
  height: 332px !important;
  width: 332px !important;
}
.z-loader-html.w-336 {
  height: 336px !important;
  width: 336px !important;
}
.z-loader-html.w-340 {
  height: 340px !important;
  width: 340px !important;
}
.z-loader-html.w-344 {
  height: 344px !important;
  width: 344px !important;
}
.z-loader-html.w-348 {
  height: 348px !important;
  width: 348px !important;
}
.z-loader-html.w-352 {
  height: 352px !important;
  width: 352px !important;
}
.z-loader-html.w-356 {
  height: 356px !important;
  width: 356px !important;
}
.z-loader-html.w-360 {
  height: 360px !important;
  width: 360px !important;
}
.z-loader-html.w-364 {
  height: 364px !important;
  width: 364px !important;
}
.z-loader-html.w-368 {
  height: 368px !important;
  width: 368px !important;
}
.z-loader-html.w-372 {
  height: 372px !important;
  width: 372px !important;
}
.z-loader-html.w-376 {
  height: 376px !important;
  width: 376px !important;
}
.z-loader-html.w-380 {
  height: 380px !important;
  width: 380px !important;
}
.z-loader-html.w-384 {
  height: 384px !important;
  width: 384px !important;
}
.z-loader-html.w-388 {
  height: 388px !important;
  width: 388px !important;
}
.z-loader-html.w-392 {
  height: 392px !important;
  width: 392px !important;
}
.z-loader-html.w-396 {
  height: 396px !important;
  width: 396px !important;
}
.z-loader-html.w-400 {
  height: 400px !important;
  width: 400px !important;
}
.z-loader-html.w-404 {
  height: 404px !important;
  width: 404px !important;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.z-loader-container {
  width: auto;
  height: auto;
  display: block;
}

.z-tabla-comparativa {
  width: 100%;
  overflow: auto hidden;
}
.z-tabla-comparativa::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.z-tabla-comparativa::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.z-tabla-comparativa::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
.z-tabla-comparativa section {
  display: block;
  width: 100%;
  min-width: 290px;
  padding-top: 40px;
  padding: 40px 0px 8px 0px;
}
.z-tabla-comparativa section .cabecera .columna {
  border-top: 3px solid var(--bs--color-grises-gris-20);
}
.z-tabla-comparativa section .cabecera .columna:first-child {
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 3px solid rgba(0, 0, 0, 0);
}
.z-tabla-comparativa section .cabecera,
.z-tabla-comparativa section .contenido {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  min-height: 10px;
  position: relative;
}
.z-tabla-comparativa section .cabecera.top,
.z-tabla-comparativa section .contenido.top {
  border-top: 3px solid var(--bs--color-grises-gris-20);
}
.z-tabla-comparativa section .cabecera .columna,
.z-tabla-comparativa section .contenido .columna {
  flex: 1;
  min-width: 64px;
  max-width: 320px;
  position: relative;
  min-height: 96px;
  border-bottom: 3px solid var(--bs--color-grises-gris-20);
  border-left: 3px solid var(--bs--color-grises-gris-20);
}
@media only screen and (min-width : 600px) {
  .z-tabla-comparativa section .cabecera .columna,
.z-tabla-comparativa section .contenido .columna {
    min-height: 64px;
    padding-top: 0px;
  }
}
.z-tabla-comparativa section .cabecera .columna:last-child,
.z-tabla-comparativa section .contenido .columna:last-child {
  border-right: 3px solid var(--bs--color-grises-gris-20);
}
.z-tabla-comparativa section .cabecera .columna.no-border::before,
.z-tabla-comparativa section .contenido .columna.no-border::before {
  border-top: none;
}
.z-tabla-comparativa section .cabecera .columna > p,
.z-tabla-comparativa section .contenido .columna > p {
  flex: 0 0 100%;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}
@media only screen and (min-width : 600px) {
  .z-tabla-comparativa section .cabecera .columna > p,
.z-tabla-comparativa section .contenido .columna > p {
    font-size: 1.5rem;
  }
}
.z-tabla-comparativa section .cabecera .columna > span.ok, .z-tabla-comparativa section .cabecera .columna > span.nok, .z-tabla-comparativa section .cabecera .columna > span.min,
.z-tabla-comparativa section .contenido .columna > span.ok,
.z-tabla-comparativa section .contenido .columna > span.nok,
.z-tabla-comparativa section .contenido .columna > span.min {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 6px;
}
.z-tabla-comparativa section .cabecera .columna > span.ok,
.z-tabla-comparativa section .contenido .columna > span.ok {
  background-image: url(~src/assets/img/svg/checkbox_naranja_check.svg);
  background-size: auto 20px;
}
.z-tabla-comparativa section .cabecera .columna > span.nok,
.z-tabla-comparativa section .contenido .columna > span.nok {
  background-image: url(~src/assets/img/svg/icon_close_naranja.svg);
  background-size: auto 20px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.z-tabla-comparativa section .cabecera .columna > span.min,
.z-tabla-comparativa section .contenido .columna > span.min {
  background-image: url(~src/assets/img/svg/minus.svg);
  background-size: auto 6px;
}
.z-tabla-comparativa section .cabecera .columna figure,
.z-tabla-comparativa section .contenido .columna figure {
  margin: 0;
  padding: 0;
}
.z-tabla-comparativa section .cabecera .columna:not(.titulo):not(.descriptivo):not(.subtitulo),
.z-tabla-comparativa section .contenido .columna:not(.titulo):not(.descriptivo):not(.subtitulo) {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  -webkit-align-content: flex-end;
  -moz-flex-direction: flex-end;
  -ms-flex-direction: flex-end;
}
.z-tabla-comparativa section .cabecera .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure,
.z-tabla-comparativa section .contenido .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure {
  width: 30px;
}
.z-tabla-comparativa section .cabecera .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure img,
.z-tabla-comparativa section .contenido .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure img {
  width: 100%;
  height: auto;
}
.z-tabla-comparativa section .cabecera .columna.subtitulo,
.z-tabla-comparativa section .contenido .columna.subtitulo {
  min-height: 30px;
  background: var(--bs--color-grises-gris-10);
  position: static;
  max-width: none;
  padding-top: 0px;
}
.z-tabla-comparativa section .cabecera .columna.subtitulo h3,
.z-tabla-comparativa section .contenido .columna.subtitulo h3 {
  color: var(--bs--color-principal-azul);
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 3rem;
  text-indent: 16px;
}
.z-tabla-comparativa section .cabecera .columna.descriptivo,
.z-tabla-comparativa section .contenido .columna.descriptivo {
  width: 0px;
  min-width: 0;
  flex: 0 0 0px;
}
.z-tabla-comparativa section .cabecera .columna.descriptivo p,
.z-tabla-comparativa section .contenido .columna.descriptivo p {
  position: absolute;
  top: 16px;
  left: 16px;
  white-space: nowrap;
  text-align: left;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-negro);
}
@media only screen and (max-width : 500px) {
  .z-tabla-comparativa section .cabecera .columna.descriptivo,
.z-tabla-comparativa section .contenido .columna.descriptivo {
    border: none;
  }
}
@media only screen and (min-width : 600px) {
  .z-tabla-comparativa section .cabecera .columna.descriptivo,
.z-tabla-comparativa section .contenido .columna.descriptivo {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    -webkit-align-content: center;
    -moz-flex-direction: center;
    -ms-flex-direction: center;
    flex: 1;
    min-width: 60px;
    max-width: 320px;
  }
  .z-tabla-comparativa section .cabecera .columna.descriptivo p,
.z-tabla-comparativa section .contenido .columna.descriptivo p {
    position: static;
    font-size: 1.5rem;
    text-indent: 16px;
  }
}
.z-tabla-comparativa section .cabecera .columna.titulo,
.z-tabla-comparativa section .contenido .columna.titulo {
  background: var(--bs--color-principal-azul);
  min-height: 80px;
  padding-top: 0px;
}
.z-tabla-comparativa section .cabecera .columna.titulo figure,
.z-tabla-comparativa section .contenido .columna.titulo figure {
  display: none;
  width: 50px;
  position: absolute;
  left: calc(50% - 25px);
  top: -30px;
  z-index: 10;
}
.z-tabla-comparativa section .cabecera .columna.titulo figure img,
.z-tabla-comparativa section .contenido .columna.titulo figure img {
  width: 100%;
  height: auto;
}
@media only screen and (min-width : 600px) {
  .z-tabla-comparativa section .cabecera .columna.titulo figure,
.z-tabla-comparativa section .contenido .columna.titulo figure {
    background: var(--bs--color-principal-blanco);
    display: grid;
    place-items: center;
  }
}
@media only screen and (min-width : 600px) {
  .z-tabla-comparativa section .cabecera .columna.titulo,
.z-tabla-comparativa section .contenido .columna.titulo {
    background: none;
  }
}
.z-tabla-comparativa section .cabecera .columna.titulo h1,
.z-tabla-comparativa section .contenido .columna.titulo h1 {
  color: var(--bs--color-principal-blanco);
  font-size: 1.3rem;
  font-family: "overpassregular", sans-serif;
  line-height: 1.5;
  margin: 16px auto 8px;
  text-align: center;
}
@media only screen and (min-width : 600px) {
  .z-tabla-comparativa section .cabecera .columna.titulo h1,
.z-tabla-comparativa section .contenido .columna.titulo h1 {
    margin: 24px auto 8px;
    color: var(--bs--color-principal-negro);
    font-size: clamp(1.5rem, 1vw + 0.5rem, 1.8rem);
    font-family: "overpassbold", sans-serif;
  }
}

.z-paginador {
  margin-top: 20px;
  min-height: 40px;
  width: 100%;
  display: block;
  flex: 0 0 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  position: relative;
}
.z-paginador .p-paginator {
  flex: 0 0 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  align-items: center;
}
.z-paginador .custom-nav {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-paginador .custom-nav section {
  flex: 1;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-paginador .custom-nav .elipsis {
  font-size: 2rem;
  height: 28px;
  margin: 0 4px;
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassbold", sans-serif;
  text-align: center;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  -webkit-align-content: flex-end;
  -moz-flex-direction: flex-end;
  -ms-flex-direction: flex-end;
  line-height: 2.4rem;
  margin: 0 2px;
}
@media only screen and (min-width : 600px) {
  .z-paginador .custom-nav .elipsis {
    height: 32px;
  }
}
.z-paginador .custom-nav button {
  font-size: 1.5rem;
  height: 28px;
  margin-right: 0px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;
}
@media only screen and (min-width : 600px) {
  .z-paginador .custom-nav button {
    height: 32px;
    margin: 0 2px;
  }
}
.z-paginador .custom-nav button.disabled {
  background: none;
  color: var(--bs--color-grises-gris-50);
  cursor: not-allowed;
  opacity: 0.3;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.z-paginador .custom-nav button.number {
  padding: 0 4px;
  min-width: 24px;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
}
@media only screen and (min-width : 600px) {
  .z-paginador .custom-nav button.number {
    min-width: 32px;
  }
}
.z-paginador .custom-nav button.number.activo {
  font-family: "overpassbold", sans-serif;
  color: #ffffff;
  background-color: var(--bs--color-principal-naranja);
}
.z-paginador .custom-nav button.number:hover {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-naranja);
}
.z-paginador .custom-nav button.number:hover.activo {
  color: #ffffff;
  background-color: var(--bs--color-secundario-naranja-hover);
}
.z-paginador .custom-nav button.nav {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 20px;
  width: 24px;
}
@media only screen and (min-width : 600px) {
  .z-paginador .custom-nav button.nav {
    width: 48px;
  }
}
.z-paginador .custom-nav button.nav.nav-first {
  background-image: url(~src/assets/img/svg/icon-arrow-first.svg);
  background-size: auto 16px;
}
.z-paginador .custom-nav button.nav.nav-last {
  background-size: auto 16px;
  background-image: url(~src/assets/img/svg/icon-arrow-last.svg);
}
.z-paginador .custom-nav button.nav.nav-next {
  background-image: url(~src/assets/img/svg/icon-arrow-prev.svg);
}
.z-paginador .custom-nav button.nav.nav-back {
  background-image: url(~src/assets/img/svg/icon-arrow-prev.svg);
}
.z-paginador .custom-nav button.nav:hover {
  background-color: var(--bs--color-grises-gris-10) !important;
}
.z-paginador .custom-nav button.nav:hover.disabled {
  background-color: var(--bs--color-principal-blanco) !important;
}

.z-accordeon .accordeon-item {
  border: 1px solid var(--bs--color-grises-gris-10);
  margin-bottom: 10px;
}
.z-accordeon .accordeon-item input {
  display: none;
}
.z-accordeon .accordeon-item input:checked ~ .accordeon-item-content {
  height: auto;
  display: block;
}
.z-accordeon .accordeon-item input:checked ~ .accordeon-item-header figure::before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.z-accordeon .accordeon-item input:checked ~ .accordeon-item-header span {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-naranja);
}
.z-accordeon .accordeon-item .accordeon-item-header {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  width: 100%;
  cursor: pointer;
  min-height: 50px;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  position: relative;
  border-bottom: 1px solid var(--bs--color-grises-gris-10);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.z-accordeon .accordeon-item .accordeon-item-header figure {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  background: none;
}
@media only screen and (min-width : 600px) {
  .z-accordeon .accordeon-item .accordeon-item-header figure {
    width: 60px;
  }
}
.z-accordeon .accordeon-item .accordeon-item-header figure::before {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: block;
  content: "";
  height: 18px;
  width: 18px;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  background: url(~src/assets/img/svg/icon-arrow-up.svg) center center no-repeat;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
}
.z-accordeon .accordeon-item .accordeon-item-header span {
  margin-left: 10px;
  width: calc(100% - 10px);
  padding-left: 20px;
}
@media only screen and (min-width : 600px) {
  .z-accordeon .accordeon-item .accordeon-item-header span {
    margin-left: 20px;
    width: calc(100% - 20px);
    padding-left: 10px;
  }
}
.z-accordeon .accordeon-item .accordeon-item-content {
  display: none;
  padding: 10px 30px;
}

.z-menu {
  position: fixed;
  background-color: var(--bs--color-grises-gris-10);
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 600px;
  height: 100vh;
  z-index: 1024;
  -webkit-box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.2);
  overflow: auto;
}
.z-menu::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.z-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.z-menu::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
@media only screen and (min-width : 600px) {
  .z-menu {
    max-width: 360px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.z-menu a.close {
  cursor: pointer;
  position: relative;
  width: 100%;
  text-decoration: none;
  min-height: 48px;
  background: url(~src/assets/img/svg/icon-zenit-cruz.svg) 0px center no-repeat;
  background-size: auto 40px;
  opacity: 0.4;
}
.z-menu a.close span {
  -webkit-transition: all 0.08s linear;
  -moz-transition: all 0.08s linear;
  -ms-transition: all 0.08s linear;
  -o-transition: all 0.08s linear;
  transition: all 0.08s linear;
  opacity: 1;
  color: var(--bs--color-grises-gris-80);
  font-size: 1.6rem;
}
.z-menu a.close:hover {
  opacity: 0.8;
}
.z-menu figure {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  height: 20px;
  width: 16px;
  display: inline-block;
  margin: 0 24px 0 12px;
  padding: 0;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  animation-duration: 0.4s;
  animation-name: menuExpand;
  margin: 0 12px;
}
.z-menu figure img {
  display: inline-block;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width : 600px) {
  .z-menu figure {
    margin: 0 24px 0 12px;
  }
}
.z-menu button {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  cursor: pointer;
}
.z-menu button span {
  opacity: 1;
  color: var(--bs--color-grises-gris-80);
  font-size: 1.6rem;
  line-height: 1;
  font-family: "overpassbold", sans-serif;
  -webkit-transition: all 0.08s linear;
  -moz-transition: all 0.08s linear;
  -ms-transition: all 0.08s linear;
  -o-transition: all 0.08s linear;
  transition: all 0.08s linear;
}
.z-menu button:hover figure {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.z-menu button:hover span {
  color: var(--bs--color-principal-naranja);
}
.z-menu > ul li {
  list-style: none;
  padding: 0;
}
.z-menu > ul li a {
  cursor: pointer;
}
.z-menu > ul li.header {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-menu > ul li.header a figure {
  filter: grayscale(0%) !important;
  -webkit-filter: grayscale(0%) !important;
}
.z-menu > ul > li:not(.header) {
  border-bottom: 1px solid rgba(234, 234, 234, 0.8);
}
.z-menu > ul > li:not(.header):nth-child(2) {
  border-top: 1px solid rgba(234, 234, 234, 0.8);
}
.z-menu > ul > li:not(.header):last-child {
  border-top: none;
  border-bottom: none;
}
.z-menu > ul > li:not(.header) > .sub-menu {
  padding-left: 40px;
}
.z-menu > ul > li:not(.header) > .sub-menu > li {
  min-height: 24px;
}
.z-menu > ul > li:not(.header) > .sub-menu > li a {
  flex: 0 0 100%;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
  text-decoration: none;
  font-size: 1.6rem;
  min-height: 24px;
  line-height: 1;
  cursor: pointer;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  -webkit-transition: all 0.08s linear;
  -moz-transition: all 0.08s linear;
  -ms-transition: all 0.08s linear;
  -o-transition: all 0.08s linear;
  transition: all 0.08s linear;
}
.z-menu > ul > li:not(.header) > .sub-menu > li a:hover {
  color: var(--bs--color-principal-naranja);
}
.z-menu > ul > li:not(.header) > .sub-menu > li span {
  opacity: 1;
}
.z-menu > ul > li:not(.header) > .sub-menu > li > .sub-menu {
  flex: 0 0 100%;
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.z-menu > ul > li:not(.header) > .sub-menu > li > .sub-menu li {
  min-height: 24px;
}
.z-menu > ul > li:not(.header) > .sub-menu > li > .sub-menu li a {
  line-height: 1;
  min-height: 24px;
  font-size: 1.3rem;
}
.z-menu > ul > li:not(.header) > .sub-menu > li > .sub-menu .sub-menu {
  display: none;
}
.z-menu > ul > li:not(.header) > a + ul {
  display: none;
}
.z-menu > ul > li:not(.header) > a.activo + ul {
  opacity: 0.8;
  background: var(--bs--color-principal-blanco);
  display: block;
}
.z-menu > ul > li:not(.header) > a.padre {
  background: url(~src/assets/img/svg/icon-arrow-next.svg) calc(100% - 8px) center no-repeat;
  background-size: auto 16px;
}
.z-menu > ul > li:not(.header):hover > a {
  opacity: 0.8;
  position: relative;
  background: var(--bs--color-principal-blanco);
}
.z-menu > ul > li:not(.header):hover > a.padre {
  background: url(~src/assets/img/svg/icon-arrow-down.svg) var(--bs--color-principal-blanco) calc(100% - 8px) center no-repeat;
  background-size: 16px auto;
}
.z-menu > ul > li:not(.header):hover > a > span {
  font-family: "overpassbold", sans-serif;
}
.z-menu > ul > li:not(.header):hover > a.activo {
  opacity: 1;
}
.z-menu > ul > li:not(.header):hover > a.activo > span {
  font-family: "overpassbold", sans-serif;
}
.z-menu > ul > li:not(.header):hover > a + ul {
  opacity: 0.8;
  background: var(--bs--color-principal-blanco);
  display: block;
}
.z-menu > ul > li:not(.header) > a {
  font-family: "overpassregular", sans-serif;
  cursor: pointer;
  position: relative;
  width: 100%;
  text-decoration: none;
  min-height: 48px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-menu > ul > li:not(.header) > a span {
  -webkit-transition: all 0.08s linear;
  -moz-transition: all 0.08s linear;
  -ms-transition: all 0.08s linear;
  -o-transition: all 0.08s linear;
  transition: all 0.08s linear;
  opacity: 1;
  color: var(--bs--color-grises-gris-80);
  font-size: 1.6rem;
}
@media only screen and (min-width : 1000px) {
  .z-menu > ul > li:not(.header) > a {
    min-height: 64px;
  }
}
.z-menu > ul > li:not(.header) > a:hover figure {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.z-menu > ul > li:not(.header) > a:hover span {
  color: var(--bs--color-principal-naranja);
}
.z-menu > ul > li:not(.header) > a:hover span, .z-menu > ul > li:not(.header) > a.activo span {
  color: var(--bs--color-principal-naranja);
}
.z-menu > ul > li:not(.header) > a:hover figure, .z-menu > ul > li:not(.header) > a.activo figure {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.z-menu > ul > li:not(.header) > a.activo {
  z-index: 101;
  position: relative;
  font-family: "overpassbold", sans-serif;
}
.z-menu > ul > li:not(.header) > a.activo:after, .z-menu > ul > li:not(.header) > a.activo:before {
  height: 12px;
  width: 12px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
}

.z-menu-back {
  display: none;
}
@media only screen and (min-width : 600px) {
  .z-menu-back {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
}

.z-badges {
  display: inline-block;
  background-color: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-blanco);
  margin-right: 10px;
  position: relative;
  margin: 0 12px 12px 0;
  padding: 0px 32px 0px 12px;
  font-size: 1.4rem;
  text-transform: uppercase;
  vertical-align: top;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: "overpassregular", sans-serif;
  box-sizing: border-box;
  height: 24px;
  -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.09);
  -ms-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.z-badges span {
  user-select: none;
  height: 24px;
  line-height: 22px;
  font-family: "overpassbold", sans-serif;
  text-transform: uppercase;
  font-size: 1.2rem;
  white-space: nowrap;
  vertical-align: middle;
  display: grid;
  place-content: self-start center;
  text-align: left;
}
@media only screen and (min-width : 600px) {
  .z-badges span {
    height: 28px;
    line-height: 26px;
    font-size: 1.4rem;
  }
}
.z-badges button {
  margin: 0 12px 0 0;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}
.z-badges button svg {
  fill: var(--bs--color-grises-gris-10);
  position: absolute;
  height: 16px;
  width: 16px;
  top: calc(50% - 8px);
  right: 8px;
}
.z-badges.simple {
  padding-right: 12px;
}
.z-badges:not(.simple):hover {
  opacity: 0.5;
}
@media only screen and (min-width : 600px) {
  .z-badges {
    height: 28px;
  }
}
.z-badges.lleno {
  color: var(--bs--color-principal-blanco);
}
.z-badges.lleno button svg {
  fill: var(--bs--color-principal-blanco);
}
.z-badges.lleno.gris-10 {
  background-color: var(--bs--color-grises-gris-10);
}
.z-badges.lleno.gris-10 span {
  color: var(--bs--color-principal-negro) !important;
}
.z-badges.lleno.gris-10 button svg {
  fill: var(--bs--color-grises-gris-80);
}
.z-badges.lleno.gris-40 {
  background-color: var(--bs--color-grises-gris-40);
}
.z-badges.lleno.gris-40 span {
  color: var(--bs--color-principal-negro) !important;
}
.z-badges.lleno.gris-80 {
  background-color: var(--bs--color-grises-gris-80);
}
.z-badges.lleno.rojo {
  background-color: var(--bs--color-secundario-rojo);
}
.z-badges.lleno.negro {
  background-color: var(--bs--color-principal-negro);
}
.z-badges.lleno.naranja {
  background-color: var(--bs--color-principal-naranja);
}
.z-badges.borde {
  border-width: 2px;
  border-style: solid;
}
.z-badges.borde.gris-10 {
  border-color: var(--bs--color-grises-gris-10);
  color: var(--bs--color-grises-gris-80);
}
.z-badges.borde.gris-10 span {
  color: var(--bs--color-grises-gris-80) !important;
}
.z-badges.borde.gris-10 button svg {
  fill: var(--bs--color-grises-gris-80);
}
.z-badges.borde.gris-40 {
  border-color: var(--bs--color-grises-gris-40);
  color: var(--bs--color-grises-gris-40);
}
.z-badges.borde.gris-40 button svg {
  fill: var(--bs--color-grises-gris-40);
}
.z-badges.borde.gris-80 {
  border-color: var(--bs--color-grises-gris-80);
  color: var(--bs--color-grises-gris-80);
}
.z-badges.borde.gris-80 button svg {
  fill: var(--bs--color-grises-gris-80);
}
.z-badges.borde.rojo {
  border-color: var(--bs--color-secundario-rojo);
  color: var(--bs--color-secundario-rojo);
}
.z-badges.borde.rojo button svg {
  fill: var(--bs--color-secundario-rojo);
}
.z-badges.borde.negro {
  border-color: var(--bs--color-principal-negro);
  color: var(--bs--color-principal-negro);
}
.z-badges.borde.negro button svg {
  fill: var(--bs--color-principal-negro);
}
.z-badges.borde.naranja {
  border-color: var(--bs--color-principal-naranja);
  color: var(--bs--color-principal-naranja);
}
.z-badges.borde.naranja button svg {
  fill: var(--bs--color-principal-naranja);
}

.z-badges-group {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.z-badges-group .z-badges {
  flex: 1;
}
@media only screen and (min-width : 600px) {
  .z-badges-group .z-badges {
    flex: none;
  }
}
.z-badges-group.flex .z-badges {
  flex: 1 !important;
}

.z-mensaje-emergente {
  display: block;
  background-color: var(--bs--color-principal-blanco);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  position: relative;
}
.z-mensaje-emergente article {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.z-mensaje-emergente.fijo {
  height: auto;
  position: relative;
  z-index: 0;
  margin: 4px auto 16px;
}
.z-mensaje-emergente.flotante {
  position: fixed;
  z-index: 10000000;
  width: 96%;
  max-width: 480px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.z-mensaje-emergente.flotante.superior {
  top: 60px;
}
@media only screen and (min-width : 600px) {
  .z-mensaje-emergente.flotante.superior {
    top: 70px;
  }
}
.z-mensaje-emergente.flotante.inferior {
  bottom: 10px;
}
.z-mensaje-emergente.flotante.derecha {
  left: 50%;
  transform: translate(-50%, 0);
}
@media only screen and (min-width : 600px) {
  .z-mensaje-emergente.flotante.derecha {
    transform: translate(0, 0);
    left: inherit;
    right: 10px;
  }
}
.z-mensaje-emergente.flotante.centro {
  left: 50%;
  transform: translate(-50%, 0);
}
.z-mensaje-emergente.flotante.izquierda {
  left: 50%;
  transform: translate(-50%, 0);
}
@media only screen and (min-width : 600px) {
  .z-mensaje-emergente.flotante.izquierda {
    transform: translate(0, 0);
    left: 10px;
  }
}
.z-mensaje-emergente article {
  margin: 0;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  position: relative;
  background-color: var(--bs--color-principal-blanco);
  /*&:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 4px;
          height: 100%;
          display: block;
          content: '';
          background-color: var(--bs--color-grises-gris-10);
      }*/
}
.z-mensaje-emergente article figure {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 48px;
}
.z-mensaje-emergente article figure,
.z-mensaje-emergente article button {
  flex: 0 0 48px;
  height: 48px;
  display: block;
}
.z-mensaje-emergente article figcaption {
  flex: 1;
  margin: 12px 0 8px;
}
.z-mensaje-emergente article figcaption .botonera {
  margin-top: 32px;
}
.z-mensaje-emergente article figcaption .botonera.simple a {
  margin-right: 16px !important;
}
.z-mensaje-emergente article button {
  cursor: pointer;
}
.z-mensaje-emergente article button:hover {
  opacity: 1;
}
.z-mensaje-emergente.informativo button svg {
  fill: var(--bs--color-principal-blanco);
}
.z-mensaje-emergente.informativo button:hover {
  opacity: 0.5;
}
.z-mensaje-emergente.informativo article {
  background-color: var(--bs--color-principal-naranja);
}
.z-mensaje-emergente.informativo article figure {
  background-image: url(~src/assets/img/svg/icon_mensaje_emergente_info.svg);
}
.z-mensaje-emergente.informativo article figcaption h5,
.z-mensaje-emergente.informativo article figcaption p {
  color: var(--bs--color-principal-blanco);
}
.z-mensaje-emergente.informativo article:before {
  background-color: var(--bs--color-principal-azul);
}
.z-mensaje-emergente.error button svg, .z-mensaje-emergente.exito button svg, .z-mensaje-emergente.advertencia button svg {
  fill: var(--bs--color-grises-gris-40);
}
.z-mensaje-emergente.error button:hover svg, .z-mensaje-emergente.exito button:hover svg, .z-mensaje-emergente.advertencia button:hover svg {
  fill: var(--bs--color-grises-gris-80);
}
.z-mensaje-emergente.error article {
  /*&:before {
            background-color: var(--bs--color-secundario-rojo);
        }*/
}
.z-mensaje-emergente.error article figure {
  background-image: url(~src/assets/img/svg/icon_mensaje_emergente_error.svg);
}
.z-mensaje-emergente.exito article {
  /*&:before {
            background-color: var(--bs--color-principal-verde);
        }*/
}
.z-mensaje-emergente.exito article figure {
  background-image: url(~src/assets/img/svg/icon_mensaje_emergente_ok.svg);
}
.z-mensaje-emergente.advertencia article {
  /*&:before {
            background-color: var(--bs--color-principal-blanco);
        }*/
}
.z-mensaje-emergente.advertencia article figure {
  background-image: url(~src/assets/img/svg/icon_mensaje_emergente_advertencia.svg);
}
.z-mensaje-emergente.mascara {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
}
.z-mensaje-emergente.mascara > a.mascara {
  cursor: grab;
  position: absolute;
  z-index: 0;
  top: -200px;
  left: -10000px;
  width: 100%;
  height: 100%;
  display: block;
  border: rgba(255, 255, 255, 0.7) 10000px solid;
  box-sizing: border-box;
}
.z-mensaje-emergente.mascara > a.mascara + article {
  z-index: 10;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.z-modulo-carga-imagenes {
  outline: 2px dashed var(--bs--color-grises-gris-50);
  min-height: 172px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 4px 10px;
  width: calc(100% - 20px);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  -webkit-align-content: stretch;
  -moz-flex-direction: stretch;
  -ms-flex-direction: stretch;
}
.z-modulo-carga-imagenes .ver-imagenes {
  flex: 0 0 100% !important;
  height: 24px;
}
.z-modulo-carga-imagenes .ver-imagenes a {
  color: var(--bs--color-grises-gris-50);
  cursor: pointer;
  width: 100%;
  line-height: 24px;
  text-align: center;
  font-size: 1.4rem;
  display: inline-block;
}
.z-modulo-carga-imagenes .ver-imagenes a:hover {
  color: var(--bs--color-principal-naranja);
}
.z-modulo-carga-imagenes.invertido .cargas {
  order: 1;
}
.z-modulo-carga-imagenes.invertido .informacion {
  order: 2;
  border: none;
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
.z-modulo-carga-imagenes .cargas {
  flex: 0 0 100%;
  min-height: 120px;
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  .z-modulo-carga-imagenes .cargas {
    min-height: 150px;
  }
}
.z-modulo-carga-imagenes .cargas .elemento-cargado {
  flex: 1;
  max-width: 84px;
  margin: 0 10px 0 0;
  position: relative;
}
.z-modulo-carga-imagenes .cargas .elemento-cargado a.imagen {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -ms-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}
.z-modulo-carga-imagenes .cargas .elemento-cargado a.imagen figure {
  display: inline-block;
  width: 100%;
}
.z-modulo-carga-imagenes .cargas .elemento-cargado a.imagen figure img {
  width: 100%;
  height: 72px;
  object-fit: cover;
}
.z-modulo-carga-imagenes .cargas .elemento-cargado a.imagen:hover {
  opacity: 0.5;
}
.z-modulo-carga-imagenes .cargas .elemento-cargado a.eliminar {
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: var(--bs--color-secundario-rojo) url(~src/assets/img/svg/icon-modulo-imagen-del.svg) center center no-repeat;
  background-size: auto 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}
.z-modulo-carga-imagenes .cargas .elemento-cargado a.eliminar:hover {
  background-color: var(--bs--color-secundario-rojo-hover);
}
.z-modulo-carga-imagenes .cargas a.carga {
  max-width: 124px;
  height: 130px;
  display: inline-block;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  cursor: pointer;
}
.z-modulo-carga-imagenes .cargas a.carga:hover figure {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.z-modulo-carga-imagenes .cargas a.carga:hover span {
  color: var(--bs--color-grises-gris-80);
}
.z-modulo-carga-imagenes .cargas a.carga.full span {
  display: none;
}
.z-modulo-carga-imagenes .cargas a.carga figure {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(~src/assets/img/svg/circle-sum.svg) center center no-repeat;
  background-size: 28px auto;
}
.z-modulo-carga-imagenes .cargas a.carga span {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  text-align: center;
  font-size: 1.5rem;
  color: var(--bs--color-grises-gris-50);
  text-align: center;
}
.z-modulo-carga-imagenes .informacion {
  border: none;
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
  flex: 0 0 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  height: 50px;
}
.z-modulo-carga-imagenes .informacion h4 {
  flex: 1;
  text-align: center;
  color: var(--bs--color-principal-naranja);
  font-family: "overpassbold", sans-serif;
  padding-left: 25px;
  line-height: 1.4;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.z-modulo-carga-imagenes .informacion h4.no-padding {
  padding-left: 0;
}

span.z-tooltip {
  height: 24px;
  width: 24px;
  display: inline-block;
  background: url(~src/assets/img/svg/tooltip-file.svg) center center no-repeat;
  background-size: 24px 24px;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  opacity: 0.7;
}
span.z-tooltip.input {
  height: 20px;
  width: 24px;
  background-size: 20px auto;
  position: absolute;
  right: 0;
  top: 0;
}
span.z-tooltip.input .mensaje {
  min-width: 150px;
  min-width: fit-content;
  max-width: max-content;
}
span.z-tooltip .mensaje {
  z-index: 401;
  font-family: "overpassbold", sans-serif;
  bottom: inherit;
  top: 110%;
  display: none;
  position: absolute;
  background-color: var(--bs--color-secundario-naranja-10);
  color: var(--bs--color-principal-naranja);
  padding: 10px;
  font-size: 1.6rem;
  text-align: right;
  width: 170px;
  line-height: 1.4;
  right: 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
span.z-tooltip .mensaje.uppercase {
  text-transform: uppercase;
}
span.z-tooltip .mensaje::after {
  position: absolute;
  content: "▲";
  bottom: inherit;
  top: -16px;
  right: 12px;
  font-size: 2rem;
  color: var(--bs--color-secundario-naranja-10);
  height: 8px;
  width: 10px;
}
span.z-tooltip:hover {
  opacity: 1;
}
span.z-tooltip:hover .mensaje {
  display: block;
}
.invertido span.z-tooltip .mensaje {
  bottom: 110%;
  top: inherit;
}
.invertido span.z-tooltip .mensaje::after {
  content: "▼";
  bottom: 0px;
  top: inherit;
}

.z-publicidad-banner {
  position: relative;
  overflow: hidden;
  background-color: var(--bs--color-grises-gris-10);
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.z-publicidad-banner.horizontal {
  height: 480px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}
@media only screen and (min-width : 600px) {
  .z-publicidad-banner.horizontal {
    height: 320px;
  }
}
.z-publicidad-banner.horizontal figure {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(0%, 0%);
  width: 100%;
  display: block;
  height: 60%;
}
@media only screen and (min-width : 600px) {
  .z-publicidad-banner.horizontal figure {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 60%;
    height: 320px;
  }
}
@media only screen and (min-width : 1000px) {
  .z-publicidad-banner.horizontal figure {
    width: 55%;
  }
}
.z-publicidad-banner.horizontal figure .blob.top-left {
  position: absolute;
  top: -2%;
  left: 0%;
  transform: translate(0%, 2%);
  background: url(~src/assets/img/svg/Blob-top-left.svg) bottom right no-repeat;
  height: 80px;
  width: 80px;
}
.z-publicidad-banner.horizontal figure .blob.top-right {
  position: absolute;
  top: -2%;
  left: 100%;
  transform: translate(-100%, 2%);
  background: url(~src/assets/img/svg/Blob-top-right.svg) left bottom no-repeat;
  height: 120px;
  width: 120px;
}
.z-publicidad-banner.horizontal figure .blob.bottom-center {
  position: absolute;
  top: 103%;
  left: 50%;
  transform: translate(-50%, -103%);
  background: url(~src/assets/img/svg/Blob-center-bottom.svg) center center no-repeat;
  height: 80px;
  width: 100%;
  display: none;
}
@media only screen and (min-width : 1000px) {
  .z-publicidad-banner.horizontal figure .blob.bottom-center {
    display: block;
  }
}
.z-publicidad-banner.horizontal figure img {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 320px;
}
.z-publicidad-banner.horizontal figcaption {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  height: 40%;
  width: 100%;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  background: url(~src/assets/img/svg/curve-grad-horizontal.svg) center top no-repeat;
  background-size: 110% auto;
}
@media only screen and (min-width : 600px) {
  .z-publicidad-banner.horizontal figcaption {
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translate(-100%, 0%);
    background: url(~src/assets/img/svg/curve-grad-vertical.svg) left center no-repeat;
    background-size: auto 110%;
    width: 60%;
    height: 320px;
  }
}
@media only screen and (min-width : 1000px) {
  .z-publicidad-banner.horizontal figcaption {
    width: 55%;
  }
}
.z-publicidad-banner.horizontal figcaption section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 16px;
}
@media only screen and (max-width : 500px) {
  .z-publicidad-banner.horizontal figcaption section h1.z-titulo,
.z-publicidad-banner.horizontal figcaption section h2.z-titulo {
    text-align: center !important;
  }
}
@media only screen and (min-width : 600px) {
  .z-publicidad-banner.horizontal figcaption section {
    padding: 0 16px 0 120px;
  }
}
@media only screen and (min-width : 1000px) {
  .z-publicidad-banner.horizontal figcaption section {
    padding: 0 32px 0 120px;
  }
}
.z-publicidad-banner.horizontal.invertido {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
@media only screen and (min-width : 600px) {
  .z-publicidad-banner.horizontal.invertido figure {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
  }
}
@media only screen and (min-width : 600px) {
  .z-publicidad-banner.horizontal.invertido figcaption {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    background-image: url(~src/assets/img/svg/curve-grad-horizontal-inv.svg);
    background-position: right center;
  }
}
.z-publicidad-banner.horizontal.invertido figcaption section {
  padding: 0 16px;
}
@media only screen and (min-width : 600px) {
  .z-publicidad-banner.horizontal.invertido figcaption section {
    padding: 0 120px 0 16px;
  }
}
@media only screen and (min-width : 1000px) {
  .z-publicidad-banner.horizontal.invertido figcaption section {
    padding: 0 120px 0 32px;
  }
}
.z-publicidad-banner.vertical {
  display: block;
  max-width: 550px;
  height: 690px;
}
.z-publicidad-banner.vertical figure {
  height: 690px;
  width: 100%;
  display: block;
}
.z-publicidad-banner.vertical figure img {
  height: 690px;
  width: 100%;
  object-fit: cover;
}
.z-publicidad-banner.vertical figcaption {
  width: 100%;
  min-height: 100px;
  display: block;
  margin: 0;
}
.z-publicidad-banner.vertical figcaption .z-btn {
  width: 100%;
}
.z-publicidad-banner.vertical figcaption.top {
  padding: 16px 16px 32px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  background: url(~src/assets/img/svg/Blob-center-top-big.svg) center bottom no-repeat;
  background-size: cover;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-publicidad-banner.vertical figcaption.bottom {
  padding: 32px 16px 8px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  background: url(~src/assets/img/svg/Blob-center-bottom-big.svg) center top no-repeat;
  background-size: cover;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}

.z-botonera {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  flex: 0 0 100%;
}
.z-botonera.derecha {
  justify-content: flex-end;
  align-items: center;
}
.z-botonera.centro {
  justify-content: center;
  align-items: center;
}
.z-botonera.no-padding {
  padding: 0;
}
.z-botonera:not(.no-border) {
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
.z-botonera .dummy {
  flex: 1;
}
@media only screen and (max-width : 500px) {
  .z-botonera .z-btn-primary {
    order: 1;
  }
  .z-botonera .z-btn-secondary {
    order: 3;
  }
  .z-botonera .z-btn-secondary-negro {
    order: 2;
  }
}

input.p-inputtext {
  padding: 0;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  border: none;
  line-height: 48px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.5rem;
  text-indent: 15px;
  margin: 0 0 6px 0;
  color: var(--bs--color-principal-negro);
  position: relative;
  width: 100%;
  font-family: "overpassbold", sans-serif;
  /* &.ng-invalid,
  & */
}
input.p-inputtext:focus {
  outline: 2px solid var(--bs--color-principal-negro) !important;
}
input.p-inputtext:focus + .z-btn-form {
  outline-width: 2px;
}
input.p-inputtext.no-border {
  outline: none !important;
}
input.p-inputtext::-webkit-input-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
  font-size: 1.5rem;
}
input.p-inputtext:-moz-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
  font-size: 1.5rem;
}
input.p-inputtext::-moz-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
  font-size: 1.5rem;
}
input.p-inputtext:-ms-input-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
  font-size: 1.5rem;
}
.mix input.p-inputtext :not(.icon) {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
input.p-inputtext .is-invalid {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif;
}
input.p-inputtext .is-invalid::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext .is-invalid:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext .is-invalid::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext .is-invalid:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext .is-invalid:focus {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}
input.p-inputtext .is-invalid + .z-btn-form {
  outline-width: 2px;
}
input.p-inputtext .is-invalid.no-border {
  outline: none !important;
}
input.p-inputtext[disabled] {
  outline: 1px solid var(--bs--color-grises-gris-40) !important;
  cursor: not-allowed;
  color: rgba(172, 173, 172, 0.8);
  opacity: 1;
}
input.p-inputtext[disabled] + .z-btn-form {
  margin-left: 2px;
}
input.p-inputtext[disabled].no-border {
  outline: none !important;
}
input.p-inputtext[disabled]::-webkit-input-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled]:-moz-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled]::-moz-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled]:-ms-input-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled] + .z-caption, input.p-inputtext[disabled] + .z-caption.z-error {
  color: rgba(172, 173, 172, 0.8);
}

p-calendar {
  width: 100%;
  /* &.ng-invalid, */
}
p-calendar .p-calendar {
  width: 100%;
}
p-calendar .p-calendar input.p-inputttext {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
p-calendar .p-calendar .p-button-icon-only {
  background: url(~src/assets/img/svg/icon-calendar.svg) center center no-repeat;
  width: 55px;
  height: 48px;
  border: none;
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-100%, 0%);
  margin: 0;
  padding: 0;
  cursor: pointer;
}
p-calendar .p-calendar .p-button-icon-only:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
p-calendar .p-calendar .p-button-icon-only span {
  display: none;
}
p-calendar .p-calendar .p-button-icon-only:hover, p-calendar .p-calendar .p-button-icon-only:active {
  background: url(~src/assets/img/svg/icon-calendar.svg) center center no-repeat;
  opacity: 0.5;
}
p-calendar .p-calendar .p-button-icon-only[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
p-calendar.is-invalid .p-button-icon-only {
  background-image: url(~src/assets/img/svg/icon-calendar-red.svg);
}
p-calendar.is-invalid input.p-inputttext {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif;
}
p-calendar.is-invalid input.p-inputttext::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.is-invalid input.p-inputttext:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.is-invalid input.p-inputttext::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.is-invalid input.p-inputttext:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.is-invalid input.p-inputttext:focus {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}

.p-calendar-w-btn .p-inputtext {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  border-radius: 4px !important;
  /* .ng-invalid &, */
}
.is-invalid .p-calendar-w-btn .p-inputtext {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif;
}
.is-invalid .p-calendar-w-btn .p-inputtext::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext:focus {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}

.p-timepicker {
  padding: 4px 0;
}
.p-timepicker .p-separator {
  width: 10px;
}
.p-timepicker .p-ampm-picker > span,
.p-timepicker .p-second-picker > span,
.p-timepicker .p-minute-picker > span,
.p-timepicker .p-hour-picker > span {
  display: grid;
  place-items: center;
  place-content: center;
  font-family: "overpassbold", sans-serif;
  text-align: center;
  font-size: 1.4rem;
}
.p-timepicker .p-ampm-picker .p-link span:before,
.p-timepicker .p-second-picker .p-link span:before,
.p-timepicker .p-minute-picker .p-link span:before,
.p-timepicker .p-hour-picker .p-link span:before {
  display: none;
}
.p-timepicker .p-ampm-picker .p-link:first-child:hover span, .p-timepicker .p-ampm-picker .p-link:last-child:hover span,
.p-timepicker .p-second-picker .p-link:first-child:hover span,
.p-timepicker .p-second-picker .p-link:last-child:hover span,
.p-timepicker .p-minute-picker .p-link:first-child:hover span,
.p-timepicker .p-minute-picker .p-link:last-child:hover span,
.p-timepicker .p-hour-picker .p-link:first-child:hover span,
.p-timepicker .p-hour-picker .p-link:last-child:hover span {
  opacity: 0.5;
}
.p-timepicker .p-ampm-picker .p-link:first-child span, .p-timepicker .p-ampm-picker .p-link:last-child span,
.p-timepicker .p-second-picker .p-link:first-child span,
.p-timepicker .p-second-picker .p-link:last-child span,
.p-timepicker .p-minute-picker .p-link:first-child span,
.p-timepicker .p-minute-picker .p-link:last-child span,
.p-timepicker .p-hour-picker .p-link:first-child span,
.p-timepicker .p-hour-picker .p-link:last-child span {
  width: 32px;
  height: 20px;
}
.p-timepicker .p-ampm-picker .p-link:first-child span,
.p-timepicker .p-second-picker .p-link:first-child span,
.p-timepicker .p-minute-picker .p-link:first-child span,
.p-timepicker .p-hour-picker .p-link:first-child span {
  background: url(~src/assets/img/svg/icon-arrow-up.svg) center center no-repeat;
}
.p-timepicker .p-ampm-picker .p-link:last-child span,
.p-timepicker .p-second-picker .p-link:last-child span,
.p-timepicker .p-minute-picker .p-link:last-child span,
.p-timepicker .p-hour-picker .p-link:last-child span {
  background: url(~src/assets/img/svg/icon-arrow-down.svg) center center no-repeat;
}

.p-datepicker-group-container + .p-timepicker {
  border-top: 1px solid var(--bs--color-grises-gris-20);
  background-color: var(--bs--color-grises-gris-10);
}

.p-datepicker {
  -webkit-border-radius: 2px 2px 10px 10px;
  -moz-border-radius: 2px 2px 10px 10px;
  -ms-border-radius: 2px 2px 10px 10px;
  border-radius: 2px 2px 10px 10px;
  background-color: var(--bs--color-principal-blanco);
  border: 1px solid var(--bs--color-grises-gris-20);
}
.p-datepicker .p-datepicker-header {
  border: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 10px 0 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  min-width: 0px;
  width: auto;
  outline: none;
  min-width: 0px !important;
  flex: 1 !important;
  line-height: 48px;
  height: 36px;
  margin-bottom: 4px;
  margin-top: 4px;
  background: url(~src/assets/img/svg/icon-arrow-down.svg) calc(100% - 4px) center no-repeat;
  border: 1px solid var(--bs--color-grises-gris-50);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 0.2rem;
  text-indent: 12px;
  font-size: 1.6rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select::before, .p-datepicker .p-datepicker-header .p-datepicker-title select::after {
  box-sizing: border-box;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select::-ms-expand {
  display: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select.p-datepicker-year {
  width: 80px !important;
  max-width: 80px !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select.p-datepicker-month {
  margin-right: 10px;
  width: 120px !important;
  max-width: 120px !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:not(select) {
  margin-right: 5px;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:not(select),
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:not(select),
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  font-size: 1.5rem;
  flex: 1;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:not(select):hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:not(select):hover,
.p-datepicker .p-datepicker-header .p-datepicker-title select:hover {
  color: var(--bs--color-principal-naranja-hover) !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev {
  background: url(~src/assets/img/svg/icon-arrow-prev.svg) center center no-repeat;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:hover {
  opacity: 0.5;
  background: url(~src/assets/img/svg/icon-arrow-prev.svg) center center no-repeat !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-next {
  background: url(~src/assets/img/svg/icon-arrow-next.svg) center center no-repeat;
}
.p-datepicker .p-datepicker-header .p-datepicker-next:hover {
  opacity: 0.5;
  background: url(~src/assets/img/svg/icon-arrow-next.svg) center center no-repeat !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  height: 30px;
  width: 30px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev span,
.p-datepicker .p-datepicker-header .p-datepicker-next span {
  display: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table td {
  padding: 0.3rem 0.5rem;
}
.p-datepicker table td span {
  width: 30px;
  height: 30px;
}
.p-datepicker table td > span:focus {
  box-shadow: none !important;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--bs--color-grises-gris-50);
  color: var(--bs--color-principal-negro);
}
.p-datepicker:not(.p-disabled) table td.p-datepicker-today span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--bs--color-secundario-naranja-dark);
  color: var(--bs--color-principal-blanco);
}

.p-datepicker-calendar thead th {
  background-color: var(--bs--color-grises-gris-10);
  height: 30px;
  padding: 0.5rem;
}
.p-datepicker-calendar thead th span {
  font-size: 1.2rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  text-transform: uppercase;
}
.p-datepicker-calendar tbody tr td {
  font-size: 1.6rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
  height: 20px;
}
.p-datepicker-calendar tbody tr td span {
  font-size: 1.6rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
}
.p-datepicker-calendar tbody tr td span.p-highlight {
  background: var(--bs--color-principal-negro);
  color: white;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.p-datepicker-calendar tbody tr td span.p-disabled {
  opacity: 0.3;
}
.p-datepicker-calendar tbody tr td span:hover, .p-datepicker-calendar tbody tr td span:focus {
  background: var(--bs--color-principal-blanco) !important;
  color: var(--bs--color-principal-naranja) !important;
  font-family: "overpassbold", sans-serif !important;
}
.p-datepicker-calendar tbody tr td.p-datepicker-today span {
  background: var(--bs--color-principal-naranja);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  color: white;
}
.p-datepicker-calendar tbody tr td.p-datepicker-today span.p-highlight {
  background: var(--bs--color-principal-naranja);
  color: var(--bs--color-principal-blanco);
}
.p-datepicker-calendar tbody tr td.p-datepicker-today span:hover, .p-datepicker-calendar tbody tr td.p-datepicker-today span:focus {
  background: var(--bs--color-secundario-naranja-hover);
  color: var(--bs--color-principal-blanco);
}

.p-dropdown {
  width: 100%;
  border: none;
  outline: none;
  line-height: 48px;
  background: var(--bs--color-principal-blanco);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.4rem !important;
  text-indent: 15px;
  margin: 0 0 6px 0;
  color: var(--bs--color-principal-negro);
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  /* .ng-invalid &, */
}
.p-dropdown.p-dropdown-open {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.p-dropdown.p-dropdown-open .p-inputtext:not(.p-dropdown-filter) {
  font-family: "overpassbold", sans-serif !important;
  color: var(--bs--color-principal-negro) !important;
}
.p-dropdown .p-dropdown-empty-message {
  font-family: "overpassbold", sans-serif;
  font-size: 1.4rem;
  color: var(--bs--color-principal-negro) !important;
  line-height: 48px;
}
.p-dropdown .p-inputtext:not(.p-dropdown-filter) {
  outline: none !important;
  color: var(--bs--color-grises-gris-80) !important;
  font-family: "overpassregular", sans-serif !important;
}
.p-inputwrapper-filled .p-dropdown .p-inputtext:not(.p-dropdown-filter) {
  font-family: "overpassbold", sans-serif !important;
  color: var(--bs--color-principal-negro) !important;
}
.p-dropdown:not(.p-disabled):hover {
  outline: 1px solid var(--bs--color-principal-negro) !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #F4F4F4 !important;
  outline: 1px solid var(--bs--color-principal-negro) !important;
}
.p-dropdown .p-dropdown-label {
  font-size: 1.4rem !important;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  font-family: "overpassregular", sans-serif;
}
.p-dropdown .p-dropdown-open {
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
}
.p-dropdown .p-dropdown-trigger {
  width: 40px;
  background: url(~src/assets/img/svg/icon-arrow-down.svg) center center no-repeat;
  background-size: 16px auto;
}
.p-dropdown .p-dropdown-trigger span {
  display: none;
}
.p-dropdown .p-dropdown-trigger:hover {
  opacity: 0.5;
}
.p-dropdown .p-placeholder {
  color: var(--bs--color-grises-gris-80) !important;
  font-family: "overpassregular", sans-serif !important;
}
.p-dropdown .p-dropdown-panel {
  background-color: var(--bs--color-principal-blanco);
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.p-dropdown .p-dropdown-item {
  font-size: 1.6rem;
  line-height: 48px;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "overpassbold", sans-serif !important;
}
.p-dropdown .p-dropdown-item:hover {
  background-color: var(--bs--color-grises-gris-10) !important;
}
.p-dropdown .p-dropdown-item.p-highlight {
  background-color: var(--bs--color-grises-gris-20) !important;
}
.p-dropdown .p-dropdown-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: var(--bs--color-grises-gris-10);
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter-icon {
  height: 48px;
  width: 30px;
  top: 0px;
  margin: 8px 0;
  right: 16px;
  background: url(~src/assets/img/svg/icon-search.svg) center center no-repeat;
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter-icon::before {
  display: none;
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter {
  flex: 1;
  margin: 8px !important;
  width: auto !important;
}
.is-invalid .p-dropdown {
  outline: 1px solid var(--bs--color-secundario-rojo) !important;
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown .p-placeholder {
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif !important;
}
.is-invalid .p-dropdown:hover {
  outline: 1px solid var(--bs--color-secundario-rojo-hover) !important;
}
.is-invalid .p-dropdown:focus {
  outline: 1px solid var(--bs--color-secundario-rojo) !important;
}
.is-invalid .p-dropdown .p-dropdown-trigger {
  background-image: url(~src/assets/img/svg/icon-arrow-down-r.svg);
}
.p-dropdown.p-disabled {
  opacity: 1 !important;
  outline-color: var(--bs--color-grises-gris-40) !important;
  cursor: not-allowed !important;
}
.p-dropdown.p-disabled .p-dropdown-label {
  cursor: not-allowed !important;
}
.p-dropdown.p-disabled .p-inputtext:not(.p-dropdown-filter) {
  cursor: not-allowed;
  font-family: "overpassbold", sans-serif !important;
  color: var(--bs--color-grises-gris-50) !important;
}
.p-dropdown.p-disabled .p-dropdown-trigger {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.p-dropdown.p-disabled .p-inputtext {
  cursor: not-allowed !important;
  color: rgba(172, 173, 172, 0.8) !important;
}

p-dropdown.p-inputwrapper-focus .p-dropdown {
  outline: 2px solid var(--bs--color-principal-negro) !important;
}

p-checkbox {
  margin-bottom: 8px;
  align-items: flex-start !important;
}
.compacto p-checkbox {
  margin-bottom: 0px;
}
.z-tabla-datos p-checkbox {
  margin-bottom: 0px;
}

.p-checkbox {
  margin: 0 10px 0 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.z-tabla-datos .p-checkbox {
  margin: 0 10px 0 0;
}
.p-checkbox.p-checkbox-focused .p-checkbox-box {
  border: 1px solid var(--bs--color-grises-gris-80) !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.p-checkbox.p-checkbox-checked .p-checkbox-box {
  position: relative;
}
.p-checkbox.p-checkbox-checked .p-checkbox-box:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(~src/assets/img/svg/checkbox_naranja_check.svg) center center no-repeat !important;
}
.p-checkbox .p-checkbox-box {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  background-color: var(--bs--color-principal-blanco);
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  height: 24px;
  width: 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid var(--bs--color-grises-gris-80) !important;
  /* .ng-invalid &, */
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  display: none;
}
.p-checkbox .p-checkbox-box:hover {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.p-checkbox .p-checkbox-box span {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.p-checkbox .p-checkbox-box:focus {
  border: 1px solid var(--bs--color-grises-gris-80) !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--bs--color-principal-blanco) !important;
  border-color: var(--bs--color-principal-negro) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.p-checkbox .p-checkbox-box.p-highlight span {
  color: var(--bs--color-principal-negro);
  font-weight: 700;
  font-size: 1.2rem;
}
.p-checkbox .p-checkbox-box.p-highlight:hover {
  background: var(--bs--color-principal-negro);
  border-color: var(--bs--color-principal-negro);
}
.p-checkbox .p-checkbox-box.p-disabled {
  cursor: not-allowed;
  border-color: var(--bs--color-grises-gris-50) !important;
  opacity: 1 !important;
}
.p-checkbox .p-checkbox-box.p-disabled:after {
  opacity: 0.5 !important;
}
.is-invalid .p-checkbox .p-checkbox-box {
  border-color: var(--bs--color-validacion-rojo-50) !important;
}
.p-checkbox + .p-checkbox-label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-negro);
  font-size: var(--bs--font-size-label-checkbox);
  margin-left: 0 !important;
  line-height: 2.4rem;
}
.p-checkbox.p-checkbox-disabled {
  cursor: not-allowed;
}
.p-checkbox.p-checkbox-disabled .p-checkbox-box {
  cursor: not-allowed;
}
.p-checkbox.p-checkbox-disabled + .p-checkbox-label {
  cursor: not-allowed;
  color: var(--bs--color-grises-gris-80);
}

.p-multiselect .p-checkbox-box.p-highlight {
  position: relative;
}
.p-multiselect .p-checkbox-box.p-highlight:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(~src/assets/img/svg/checkbox_naranja_check.svg) center center no-repeat !important;
}

p-radiobutton {
  margin-bottom: 16px;
  align-items: flex-start !important;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
  margin: 0 4px 0 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
@media only screen and (min-width : 600px) {
  .p-radiobutton {
    margin: 0 10px 0;
  }
}
.p-radiobutton.p-radiobutton-disabled {
  cursor: not-allowed;
}
.p-radiobutton.p-radiobutton-disabled + .p-radiobutton-label {
  color: var(--bs--color-grises-gris-50);
}
.p-radiobutton.p-radiobutton-disabled.p-radiobutton-checked + .p-radiobutton-label {
  color: var(--bs--color-principal-naranja);
  font-family: "overpassbold", sans-serif;
  opacity: 0.5;
}
.p-radiobutton .p-radiobutton-box {
  border: 1px solid var(--bs--color-grises-gris-80);
  background: var(--bs--color-principal-blanco);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: var(--bs--color-principal-naranja);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 11px;
  height: 11px;
  transition-duration: 0.2s;
  background-color: var(--bs--color-principal-naranja);
}
.p-radiobutton .p-radiobutton-box.p-disabled {
  border: 1px solid var(--bs--color-grises-gris-50);
}
.p-radiobutton .p-radiobutton-box.p-disabled .p-radiobutton-icon {
  opacity: 0.5;
  background-color: var(--bs--color-principal-naranja);
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--bs--color-grises-gris-80);
  background: var(--bs--color-principal-blanco);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--bs--color-grises-gris-80);
  background: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-blanco);
}
.p-radiobutton.p-radiobutton-checked + .p-radiobutton-label {
  color: var(--bs--color-principal-naranja);
  font-family: "overpassbold", sans-serif;
}

p-radiobutton.ng-dirty {
  /* &.is-invalid,
  & */
}
p-radiobutton.ng-dirty .ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: var(--bs--color-validacion-rojo-50);
}
p-radiobutton.ng-dirty .ng-invalid > .p-radiobutton > .p-radiobutton-box .p-radiobutton-icon {
  background-color: var(--bs--color-secundario-rojo);
}
p-radiobutton.ng-dirty .ng-invalid > .p-radiobutton > .p-radiobutton-box.p-highlight {
  border-color: var(--bs--color-validacion-rojo-50);
}
p-radiobutton.ng-dirty .ng-invalid > .p-radiobutton > .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--bs--color-validacion-rojo-50) !important;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: var(--bs--color-principal-naranja);
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--bs--color-principal-naranja);
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: var(--bs--color-principal-blanco);
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--bs--color-principal-naranja);
}

.p-radiobutton-label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-right: 16px;
  line-height: 2rem;
  text-transform: uppercase;
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-negro);
  font-size: var(--bs--font-size-label-checkbox);
}

p-multiselect {
  width: 100%;
  /* &.ng-invalid,
    & */
}
p-multiselect .p-multiselect {
  width: 100%;
  margin-bottom: 6px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  line-height: 48px;
  background: var(--bs--color-principal-blanco);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
}
p-multiselect .p-multiselect .p-multiselect-filter-icon {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 99%;
  transform: translate(-99%, -50%);
  height: 10px;
  width: 22px;
}
p-multiselect .p-multiselect .p-multiselect-token {
  background-color: var(--bs--color-principal-naranja);
}
p-multiselect .p-multiselect .p-multiselect-token .p-multiselect-token-label {
  color: var(--bs--color-principal-blanco);
  font-family: "overpassbold", sans-serif;
}
p-multiselect .p-multiselect .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 10px;
  color: var(--bs--color-principal-blanco);
  height: 48px;
  width: 30px;
  top: 0px;
  margin: 8px 0;
  right: 16px;
  background: url(~src/assets/img/svg/icon-search.svg) center center no-repeat;
}
p-multiselect .p-multiselect .p-multiselect-token .p-multiselect-token-icon:before {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  height: 48px;
  width: 40px;
  top: 58%;
  left: 98%;
  right: 20px;
  background: url(~src/assets/img/svg/icon-search.svg) center center no-repeat;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon::before {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter {
  position: relative;
  flex: 1;
  margin: 8px !important;
  width: auto !important;
}
p-multiselect .p-multiselect .p-multiselect-panel {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  background-color: var(--bs--color-principal-blanco);
}
p-multiselect .p-multiselect .p-multiselect-item {
  font-size: 1.6rem;
  line-height: 48px;
  padding: 0 !important;
  margin: 0 0 16px 0 !important;
  font-family: "overpassbold", sans-serif !important;
}
p-multiselect .p-multiselect .p-multiselect-item:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
p-multiselect .p-multiselect .p-multiselect-item:hover {
  background: var(--bs--color-grises-gris-10) !important;
}
p-multiselect .p-multiselect .p-multiselect-item .p-checkbox .p-checkbox-box {
  margin-left: 10px;
}
p-multiselect .p-multiselect .p-multiselect-item span:not(.p-checkbox-icon) {
  cursor: pointer;
  text-transform: uppercase;
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-negro);
  font-size: var(--bs--font-size-label-checkbox);
  margin-left: 0 !important;
  line-height: 2.4rem;
}
p-multiselect .p-multiselect .p-multiselect-trigger {
  width: 40px;
  background: url(~src/assets/img/svg/icon-arrow-down.svg) center center no-repeat;
  background-size: 16px auto;
}
p-multiselect .p-multiselect .p-multiselect-trigger span {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-header {
  background-color: var(--bs--color-grises-gris-10);
}
p-multiselect .p-multiselect .p-multiselect-header .p-checkbox {
  margin: 0 10px 0 10px;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container {
  width: 100%;
}
p-multiselect .p-multiselect .p-multiselect-empty-message {
  font-family: "overpassbold", sans-serif;
  color: #EF3742;
  font-size: 1.5rem;
}
p-multiselect .p-multiselect .p-multiselect-close {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-close span {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-label {
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: var(--bs--color-principal-negro) !important;
  font-size: 1.4rem !important;
  font-family: "overpassbold", sans-serif !important;
  text-indent: 16px;
}
p-multiselect .p-multiselect .p-multiselect-label.p-placeholder {
  font-family: "overpassregular", sans-serif !important;
  color: var(--bs--color-grises-gris-50) !important;
}
p-multiselect .p-multiselect .p-multiselect-items-wrapper {
  padding-top: 4px;
  padding-bottom: 4px;
}
p-multiselect.p-inputwrapper-focus .p-multiselect {
  outline: 2px solid var(--bs--color-principal-negro) !important;
}
p-multiselect .is-invalid .p-multiselect {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}
p-multiselect .is-invalid .p-multiselect .p-multiselect-label {
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif !important;
}
p-multiselect .is-invalid .p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--bs--color-secundario-rojo) !important;
}
p-multiselect .is-invalid .p-multiselect .p-multiselect-trigger {
  background-image: url(~src/assets/img/svg/icon-arrow-down-r.svg);
}

.z-modal .p-dialog-mask {
  z-index: 10000001 !important;
}
.z-modal.w-40 .p-dialog {
  width: 98%;
  max-width: 40px;
}
.z-modal.w-50 .p-dialog {
  width: 98%;
  max-width: 50px;
}
.z-modal.w-60 .p-dialog {
  width: 98%;
  max-width: 60px;
}
.z-modal.w-70 .p-dialog {
  width: 98%;
  max-width: 70px;
}
.z-modal.w-80 .p-dialog {
  width: 98%;
  max-width: 80px;
}
.z-modal.w-90 .p-dialog {
  width: 98%;
  max-width: 90px;
}
.z-modal.w-100 .p-dialog {
  width: 98%;
  max-width: 100px;
}
.z-modal.w-110 .p-dialog {
  width: 98%;
  max-width: 110px;
}
.z-modal.w-120 .p-dialog {
  width: 98%;
  max-width: 120px;
}
.z-modal.w-130 .p-dialog {
  width: 98%;
  max-width: 130px;
}
.z-modal.w-140 .p-dialog {
  width: 98%;
  max-width: 140px;
}
.z-modal.w-150 .p-dialog {
  width: 98%;
  max-width: 150px;
}
.z-modal.w-160 .p-dialog {
  width: 98%;
  max-width: 160px;
}
.z-modal.w-170 .p-dialog {
  width: 98%;
  max-width: 170px;
}
.z-modal.w-180 .p-dialog {
  width: 98%;
  max-width: 180px;
}
.z-modal.w-190 .p-dialog {
  width: 98%;
  max-width: 190px;
}
.z-modal.w-200 .p-dialog {
  width: 98%;
  max-width: 200px;
}
.z-modal.w-210 .p-dialog {
  width: 98%;
  max-width: 210px;
}
.z-modal.w-220 .p-dialog {
  width: 98%;
  max-width: 220px;
}
.z-modal.w-230 .p-dialog {
  width: 98%;
  max-width: 230px;
}
.z-modal.w-240 .p-dialog {
  width: 98%;
  max-width: 240px;
}
.z-modal.w-250 .p-dialog {
  width: 98%;
  max-width: 250px;
}
.z-modal.w-260 .p-dialog {
  width: 98%;
  max-width: 260px;
}
.z-modal.w-270 .p-dialog {
  width: 98%;
  max-width: 270px;
}
.z-modal.w-280 .p-dialog {
  width: 98%;
  max-width: 280px;
}
.z-modal.w-290 .p-dialog {
  width: 98%;
  max-width: 290px;
}
.z-modal.w-300 .p-dialog {
  width: 98%;
  max-width: 300px;
}
.z-modal.w-310 .p-dialog {
  width: 98%;
  max-width: 310px;
}
.z-modal.w-320 .p-dialog {
  width: 98%;
  max-width: 320px;
}
.z-modal.w-330 .p-dialog {
  width: 98%;
  max-width: 330px;
}
.z-modal.w-340 .p-dialog {
  width: 98%;
  max-width: 340px;
}
.z-modal.w-350 .p-dialog {
  width: 98%;
  max-width: 350px;
}
.z-modal.w-360 .p-dialog {
  width: 98%;
  max-width: 360px;
}
.z-modal.w-370 .p-dialog {
  width: 98%;
  max-width: 370px;
}
.z-modal.w-380 .p-dialog {
  width: 98%;
  max-width: 380px;
}
.z-modal.w-390 .p-dialog {
  width: 98%;
  max-width: 390px;
}
.z-modal.w-400 .p-dialog {
  width: 98%;
  max-width: 400px;
}
.z-modal.w-410 .p-dialog {
  width: 98%;
  max-width: 410px;
}
.z-modal.w-420 .p-dialog {
  width: 98%;
  max-width: 420px;
}
.z-modal.w-430 .p-dialog {
  width: 98%;
  max-width: 430px;
}
.z-modal.w-440 .p-dialog {
  width: 98%;
  max-width: 440px;
}
.z-modal.w-450 .p-dialog {
  width: 98%;
  max-width: 450px;
}
.z-modal.w-460 .p-dialog {
  width: 98%;
  max-width: 460px;
}
.z-modal.w-470 .p-dialog {
  width: 98%;
  max-width: 470px;
}
.z-modal.w-480 .p-dialog {
  width: 98%;
  max-width: 480px;
}
.z-modal.w-490 .p-dialog {
  width: 98%;
  max-width: 490px;
}
.z-modal.w-500 .p-dialog {
  width: 98%;
  max-width: 500px;
}
.z-modal.w-510 .p-dialog {
  width: 98%;
  max-width: 510px;
}
.z-modal.w-520 .p-dialog {
  width: 98%;
  max-width: 520px;
}
.z-modal.w-530 .p-dialog {
  width: 98%;
  max-width: 530px;
}
.z-modal.w-540 .p-dialog {
  width: 98%;
  max-width: 540px;
}
.z-modal.w-550 .p-dialog {
  width: 98%;
  max-width: 550px;
}
.z-modal.w-560 .p-dialog {
  width: 98%;
  max-width: 560px;
}
.z-modal.w-570 .p-dialog {
  width: 98%;
  max-width: 570px;
}
.z-modal.w-580 .p-dialog {
  width: 98%;
  max-width: 580px;
}
.z-modal.w-590 .p-dialog {
  width: 98%;
  max-width: 590px;
}
.z-modal.w-600 .p-dialog {
  width: 98%;
  max-width: 600px;
}
.z-modal.w-610 .p-dialog {
  width: 98%;
  max-width: 610px;
}
.z-modal.w-620 .p-dialog {
  width: 98%;
  max-width: 620px;
}
.z-modal.w-630 .p-dialog {
  width: 98%;
  max-width: 630px;
}
.z-modal.w-640 .p-dialog {
  width: 98%;
  max-width: 640px;
}
.z-modal.w-650 .p-dialog {
  width: 98%;
  max-width: 650px;
}
.z-modal.w-660 .p-dialog {
  width: 98%;
  max-width: 660px;
}
.z-modal.w-670 .p-dialog {
  width: 98%;
  max-width: 670px;
}
.z-modal.w-680 .p-dialog {
  width: 98%;
  max-width: 680px;
}
.z-modal.w-690 .p-dialog {
  width: 98%;
  max-width: 690px;
}
.z-modal.w-700 .p-dialog {
  width: 98%;
  max-width: 700px;
}
.z-modal.w-710 .p-dialog {
  width: 98%;
  max-width: 710px;
}
.z-modal.w-720 .p-dialog {
  width: 98%;
  max-width: 720px;
}
.z-modal.w-730 .p-dialog {
  width: 98%;
  max-width: 730px;
}
.z-modal.w-740 .p-dialog {
  width: 98%;
  max-width: 740px;
}
.z-modal.w-750 .p-dialog {
  width: 98%;
  max-width: 750px;
}
.z-modal.w-760 .p-dialog {
  width: 98%;
  max-width: 760px;
}
.z-modal.w-770 .p-dialog {
  width: 98%;
  max-width: 770px;
}
.z-modal.w-780 .p-dialog {
  width: 98%;
  max-width: 780px;
}
.z-modal.w-790 .p-dialog {
  width: 98%;
  max-width: 790px;
}
.z-modal.w-800 .p-dialog {
  width: 98%;
  max-width: 800px;
}
.z-modal.w-810 .p-dialog {
  width: 98%;
  max-width: 810px;
}
.z-modal.w-820 .p-dialog {
  width: 98%;
  max-width: 820px;
}
.z-modal.w-830 .p-dialog {
  width: 98%;
  max-width: 830px;
}
.z-modal.w-840 .p-dialog {
  width: 98%;
  max-width: 840px;
}
.z-modal.w-850 .p-dialog {
  width: 98%;
  max-width: 850px;
}
.z-modal.w-860 .p-dialog {
  width: 98%;
  max-width: 860px;
}
.z-modal.w-870 .p-dialog {
  width: 98%;
  max-width: 870px;
}
.z-modal.w-880 .p-dialog {
  width: 98%;
  max-width: 880px;
}
.z-modal.w-890 .p-dialog {
  width: 98%;
  max-width: 890px;
}
.z-modal.w-900 .p-dialog {
  width: 98%;
  max-width: 900px;
}
.z-modal.w-910 .p-dialog {
  width: 98%;
  max-width: 910px;
}
.z-modal.w-920 .p-dialog {
  width: 98%;
  max-width: 920px;
}
.z-modal.w-930 .p-dialog {
  width: 98%;
  max-width: 930px;
}
.z-modal.w-940 .p-dialog {
  width: 98%;
  max-width: 940px;
}
.z-modal.w-950 .p-dialog {
  width: 98%;
  max-width: 950px;
}
.z-modal.w-960 .p-dialog {
  width: 98%;
  max-width: 960px;
}
.z-modal.w-970 .p-dialog {
  width: 98%;
  max-width: 970px;
}
.z-modal.w-980 .p-dialog {
  width: 98%;
  max-width: 980px;
}
.z-modal.w-990 .p-dialog {
  width: 98%;
  max-width: 990px;
}
.z-modal.w-1000 .p-dialog {
  width: 98%;
  max-width: 1000px;
}
.z-modal .p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}
.z-modal .p-dialog {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}
.z-modal .p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1.5rem 1.5rem 0.2rem 1.5rem;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: flex-start;
}
.z-modal .p-dialog .p-dialog-header .z-titulo,
.z-modal .p-dialog .p-dialog-header .h1,
.z-modal .p-dialog .p-dialog-header .h2,
.z-modal .p-dialog .p-dialog-header .h3,
.z-modal .p-dialog .p-dialog-header .h4,
.z-modal .p-dialog .p-dialog-header .h5,
.z-modal .p-dialog .p-dialog-header h1,
.z-modal .p-dialog .p-dialog-header h2,
.z-modal .p-dialog .p-dialog-header h3,
.z-modal .p-dialog .p-dialog-header h4,
.z-modal .p-dialog .p-dialog-header h5 {
  flex: 0 0 100%;
  width: 100%;
  padding: 8px 0 4px;
}
.z-modal .p-dialog .p-dialog-header .z-titulo figure,
.z-modal .p-dialog .p-dialog-header .h1 figure,
.z-modal .p-dialog .p-dialog-header .h2 figure,
.z-modal .p-dialog .p-dialog-header .h3 figure,
.z-modal .p-dialog .p-dialog-header .h4 figure,
.z-modal .p-dialog .p-dialog-header .h5 figure,
.z-modal .p-dialog .p-dialog-header h1 figure,
.z-modal .p-dialog .p-dialog-header h2 figure,
.z-modal .p-dialog .p-dialog-header h3 figure,
.z-modal .p-dialog .p-dialog-header h4 figure,
.z-modal .p-dialog .p-dialog-header h5 figure {
  margin: 20px auto 10px;
  display: block;
}
.z-modal .p-dialog .p-dialog-header .p-dialog-header-icons {
  position: absolute;
  top: 10px;
  right: 10px;
}
.z-modal .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-close {
  height: 24px;
  width: 24px;
  background: url(~src/assets/img/svg/icon_close_naranja.svg) center center no-repeat;
  background-size: auto 16px;
}
@media only screen and (min-width : 600px) {
  .z-modal .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-close {
    background-size: auto 20px;
  }
}
.z-modal .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-close .p-dialog-header-close-icon::before {
  display: none;
}
.z-modal .p-dialog .p-dialog-content {
  background: var(--bs--color-principal-blanco);
  padding: 0 20px;
}
@media only screen and (min-width : 600px) {
  .z-modal .p-dialog .p-dialog-content {
    padding: 0 30px;
  }
}
@media only screen and (min-width : 1000px) {
  .z-modal .p-dialog .p-dialog-content {
    padding: 0 60px;
  }
}
.z-modal .p-dialog .p-dialog-content .simple {
  color: var(--bs--color-grises-gris-50);
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-modal .p-dialog .p-dialog-content .simple ~ .simple {
  padding-top: 10px;
  padding-bottom: 20px;
}
.z-modal .p-dialog .dummy {
  width: 20px;
  color: var(--bs--color-grises-gris-40);
  text-align: center;
}
.z-modal .p-dialog .p-dialog-footer {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  background: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-negro);
  padding: 1rem 16px;
  position: relative;
}
.z-modal .p-dialog .p-dialog-footer .simple {
  color: var(--bs--color-grises-gris-50);
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-modal .p-dialog .p-dialog-footer .simple ~ .simple {
  padding-top: 10px;
  padding-bottom: 20px;
}
.z-modal .p-dialog .p-dialog-footer .extremos {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-modal .p-dialog .p-dialog-footer .derecha {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-modal .p-dialog .p-dialog-footer .izquierda {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-modal .p-dialog .p-dialog-footer .centrar {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (max-width : 600px) {
  .z-modal .p-dialog .p-dialog-footer .extremos .z-btn-primary,
.z-modal .p-dialog .p-dialog-footer .derecha .z-btn-primary,
.z-modal .p-dialog .p-dialog-footer .izquierda .z-btn-primary,
.z-modal .p-dialog .p-dialog-footer .centrar .z-btn-primary {
    order: 1;
  }
  .z-modal .p-dialog .p-dialog-footer .extremos .z-btn-secondary,
.z-modal .p-dialog .p-dialog-footer .derecha .z-btn-secondary,
.z-modal .p-dialog .p-dialog-footer .izquierda .z-btn-secondary,
.z-modal .p-dialog .p-dialog-footer .centrar .z-btn-secondary {
    order: 2;
  }
  .z-modal .p-dialog .p-dialog-footer .extremos .z-btn-link,
.z-modal .p-dialog .p-dialog-footer .derecha .z-btn-link,
.z-modal .p-dialog .p-dialog-footer .izquierda .z-btn-link,
.z-modal .p-dialog .p-dialog-footer .centrar .z-btn-link {
    order: 3;
  }
}
.z-modal .p-dialog .z-modulo-ayuda {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: var(--bs--color-secundario-naranja-10);
}
.z-modal .p-dialog .z-modulo-ayuda.gris {
  background-color: var(--bs--color-grises-gris-10);
}
.z-modal .p-dialog .z-modulo-ayuda p,
.z-modal .p-dialog .z-modulo-ayuda h5,
.z-modal .p-dialog .z-modulo-ayuda .h5 {
  text-align: center;
  line-height: 1.6;
}
.z-modal .p-dialog .z-modulo-ayuda p:not(.h5) {
  font-size: 1.7rem;
}
.z-modal.busqueda-avanzada .p-dialog {
  max-height: calc(100% - 1.5rem);
}
.z-modal.busqueda-avanzada .p-dialog .p-dialog-content {
  padding: 0 16px;
}
.z-modal.busqueda-avanzada .p-dialog .p-dialog-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.z-modal.busqueda-avanzada .p-dialog .p-dialog-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.z-modal.busqueda-avanzada .p-dialog .p-dialog-content::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
.z-modal.busqueda-avanzada .p-dialog-header {
  padding-top: 2px;
  padding-bottom: 2px;
}
@media only screen and (min-width : 600px) {
  .z-modal.busqueda-avanzada .p-dialog-header {
    padding-top: 48px;
  }
}
.z-modal.busqueda-avanzada .p-dialog-header .p-dialog-header-icons {
  top: 8px;
}
.z-modal.busqueda-avanzada .p-dialog-header h1,
.z-modal.busqueda-avanzada .p-dialog-header h2,
.z-modal.busqueda-avanzada .p-dialog-header h3,
.z-modal.busqueda-avanzada .p-dialog-header h4,
.z-modal.busqueda-avanzada .p-dialog-header h5 {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media only screen and (min-width : 600px) {
  .z-modal.busqueda-avanzada .p-dialog-header h1,
.z-modal.busqueda-avanzada .p-dialog-header h2,
.z-modal.busqueda-avanzada .p-dialog-header h3,
.z-modal.busqueda-avanzada .p-dialog-header h4,
.z-modal.busqueda-avanzada .p-dialog-header h5 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.z-modal.compacto .p-dialog-content {
  padding: 0;
}
.z-modal.compacto .p-dialog-header {
  padding-top: 2px;
  padding-bottom: 2px;
}
.z-modal.compacto .p-dialog-header .p-dialog-header-icons {
  top: 8px;
}
.z-modal.compacto .p-dialog-header h1,
.z-modal.compacto .p-dialog-header h2,
.z-modal.compacto .p-dialog-header h3,
.z-modal.compacto .p-dialog-header h4,
.z-modal.compacto .p-dialog-header h5 {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media only screen and (min-width : 600px) {
  .z-modal.compacto .p-dialog-header h1,
.z-modal.compacto .p-dialog-header h2,
.z-modal.compacto .p-dialog-header h3,
.z-modal.compacto .p-dialog-header h4,
.z-modal.compacto .p-dialog-header h5 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.z-modal.z-dividido .p-dialog .p-dialog-header {
  height: 0;
  padding: 0;
}
.z-modal.z-dividido .p-dialog .p-dialog-content {
  padding: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  display: block;
}
@media only screen and (min-width : 600px) {
  .z-modal.z-dividido .p-dialog .p-dialog-content {
    display: -ms-grid;
    display: -webkit-inline-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0px;
    row-gap: 0px;
    min-height: none;
  }
}
.z-modal.z-dividido .p-dialog .p-dialog-content .dialog-column {
  flex: 0 0 50%;
  padding: 20px;
  height: 100%;
  display: block;
}
.z-modal.z-dividido .p-dialog .p-dialog-content .dialog-column.background.gris-10 {
  background: var(--bs--color-grises-gris-10);
}
.z-modal.z-dividido .p-dialog .p-dialog-content .dialog-column.background.gris-20 {
  background: var(--bs--color-grises-gris-20);
}
.z-modal.z-dividido .p-dialog .p-dialog-content .dialog-column.background.gris-40 {
  background: var(--bs--color-grises-gris-40);
}

.z-modulo-dialog-imagen-detalle {
  width: 100%;
  min-height: 50px;
  display: block;
  position: relative;
  -webkit-transition: height 0.1s linear;
  -moz-transition: height 0.1s linear;
  -ms-transition: height 0.1s linear;
  -o-transition: height 0.1s linear;
  transition: height 0.1s linear;
}
@media only screen and (min-width : 600px) {
  .z-modulo-dialog-imagen-detalle {
    min-height: 50px;
  }
}
@media only screen and (min-width : 1000px) {
  .z-modulo-dialog-imagen-detalle {
    min-height: 50px;
  }
}
.z-modulo-dialog-imagen-detalle img {
  /*
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  object-fit: cover;*/
  width: calc(100% - 20px);
  height: auto;
  margin: 10px;
}

.z-modulo-lista-tabla-datos {
  width: 100%;
  padding: 16px 16px 4px;
}
.z-modulo-lista-tabla-datos .modulo {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  min-height: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid var(--bs--color-grises-gris-20);
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
}
@media only screen and (min-width : 600px) {
  .z-modulo-lista-tabla-datos .modulo {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.z-modulo-lista-tabla-datos .modulo:last-child {
  border-bottom: none;
}
.z-modulo-lista-tabla-datos .modulo figure {
  flex: 1 0 100%;
  max-width: 75px;
  margin-right: 12px;
}
.z-modulo-lista-tabla-datos .modulo figure img {
  width: 100%;
  height: 75px;
  object-fit: cover;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.z-modulo-lista-tabla-datos .modulo figcaption {
  flex: 1;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.z-modulo-lista-tabla-datos .modulo figcaption .z-simple-data {
  margin-bottom: 8px;
  display: inline;
  width: auto;
}
.z-modulo-lista-tabla-datos .modulo figcaption .z-simple-data:first-child {
  width: 100%;
}
@media only screen and (min-width : 600px) {
  .z-modulo-lista-tabla-datos .modulo figcaption .z-simple-data:first-child {
    width: auto;
  }
}
.z-modulo-lista-tabla-datos .modulo figcaption a {
  flex: 0 0 100%;
  text-align: left;
}

.z-tabla-datos {
  width: 100%;
  overflow: auto hidden;
}
.z-tabla-datos::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.z-tabla-datos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.z-tabla-datos::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
.z-tabla-datos p-datatable {
  width: 100%;
}

.z-tabla-panel {
  width: 100%;
  padding-right: 1rem;
  margin-left: auto;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.z-tabla-panel span.label {
  flex: 1;
}

.p-datatable {
  width: 100%;
}
.p-datatable .p-paginator {
  display: none !important;
}
.p-datatable .p-datatable-wrapper {
  margin-bottom: 16px;
}
.p-datatable .p-datatable-wrapper table {
  min-width: 1100px;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  background: var(--bs--color-grises-gris-10);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.auto .p-datatable .p-datatable-wrapper table {
  min-width: 0px;
}
.min-600 .p-datatable .p-datatable-wrapper table {
  min-width: 600px;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th {
  position: relative;
  padding: 1rem;
  min-height: 2.4rem;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  text-transform: uppercase;
  text-align: left;
  color: var(--bs--color-principal-negro);
  vertical-align: middle;
  border: 4px solid var(--bs--color-grises-gris-20);
  border-top: none !important;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .z-btn {
  position: absolute;
  top: 50%;
  left: 97%;
  transform: translate(-97%, -50%);
  min-width: max-content;
  white-space: nowrap;
  width: auto;
  padding-right: 16px;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="2"], .p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="3"], .p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="4"], .p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="5"] {
  text-align: center;
  border-top: none !important;
  border-bottom: none !important;
}
.compacto .p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th {
  padding: 0.3rem 0.3rem 0.3rem 1rem;
  font-size: 1.1rem;
  min-height: 2.2rem;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th span:not(.arrow) {
  display: inline-block;
  margin-right: auto;
  max-width: calc(100% - 30px);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon {
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center center;
  background-image: url(~src/assets/img/svg/icon-naranja-arrow-updown.svg);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon::before {
  display: none;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon.pi-sort-alt {
  background-image: url(~src/assets/img/svg/icon-naranja-arrow-updown.svg);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon.pi-sort-amount-up-alt {
  background-image: url(~src/assets/img/svg/icon-arrow-up.svg);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon.pi-sort-amount-down {
  background-image: url(~src/assets/img/svg/icon-arrow-down.svg);
}
.p-datatable .p-datatable-wrapper table tbody.p-datatable-tbody tr {
  background-color: var(--bs--color-principal-blanco);
}
.p-datatable .p-datatable-wrapper table tbody.p-datatable-tbody tr:nth-child(even) {
  background-color: white;
}
.p-datatable .p-datatable-wrapper table tbody.p-datatable-tbody tr td {
  min-height: 48px;
  height: auto;
  padding: 1rem;
  vertical-align: middle;
  text-align: left;
  border: 4px solid var(--bs--color-grises-gris-20);
  font-size: 1.5rem;
  color: var(--bs--color-principal-naranja);
  font-family: "overpassregular", sans-serif;
}
.compacto .p-datatable .p-datatable-wrapper table tbody.p-datatable-tbody tr td {
  padding: 0.5rem 0.3rem 0.5rem 1rem;
  font-size: 1.4rem;
  min-height: 2.2rem;
}
.p-datatable .p-datatable table {
  table-layout: auto !important;
}
.p-datatable th {
  background-color: #f4f4f4 !important;
}
.p-datatable .p-datatable-table {
  width: 100%;
}

p-progressbar .p-progressbar {
  border: 0 none;
  height: 1.5rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background: var(--bs--color-grises-gris-20);
  height: 6px;
  margin-bottom: 6px;
}
p-progressbar .p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--bs--color-principal-naranja);
}
p-progressbar .p-progressbar .p-progressbar-label {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
  line-height: 1.5rem;
}

.p-slider {
  background: var(--bs--color-grises-gris-40);
  border: 0 none;
  border-radius: 3px;
}

.p-slider.p-slider-horizontal {
  height: 0.486rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.7715rem;
  margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
  width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
  height: 1.543rem;
  width: 1.543rem;
  background: var(--bs--color-principal-naranja);
  border: 2px solid var(--bs--color-principal-naranja);
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #93cbf9;
}

.p-slider .p-slider-range {
  border: 0 none;
  border-radius: 3px;
  background: var(--bs--color-principal-naranja);
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--bs--color-secundario-naranja-90);
  border-color: #64b5f6;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "overpassregular", sans-serif;
  font-size: var(--bs--font-size-p);
  color: var(--bs--color-principal-negro);
  background-color: var(--bs--color-principal-blanco);
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}