p-calendar {
  width: 100%;

  .p-calendar {
    width: 100%;

    input.p-inputttext {
      @include border-radius(4px);
    }

    .p-button-icon-only {
      background: $icon_calendar center center no-repeat;
      width: 55px;
      height: $form-size;
      border: none;
      @include centrar(100%, 0%);
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:focus {
        @include no-shadow();
      }

      span {
        display: none;
      }

      &:hover,
      &:active {
        background: $icon_calendar center center no-repeat;
        opacity: 0.5;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  /* &.ng-invalid, */
  &.is-invalid {
    .p-button-icon-only {
      background-image: $icon_calendar_red;
    }

    input.p-inputttext {
      outline: 2px solid var(--bs--color-secundario-rojo) !important;
      color: var(--bs--color-secundario-rojo) !important;
      font-family: $overPass_bold;

      @include placeholder {
        color: var(--bs--color-secundario-rojo);
      }

      &:focus {
        outline: 2px solid var(--bs--color-secundario-rojo) !important;
      }
    }
  }
}

.p-calendar-w-btn {
  .p-inputtext {
    @include border-radius(4px, true);

    /* .ng-invalid &, */
    .is-invalid & {
      outline: 2px solid var(--bs--color-secundario-rojo) !important;
      color: var(--bs--color-secundario-rojo) !important;
      font-family: $overPass_bold;

      @include placeholder {
        color: var(--bs--color-secundario-rojo);
      }

      &:focus {
        outline: 2px solid var(--bs--color-secundario-rojo) !important;
      }
    }
  }
}

.p-timepicker {
  padding: 4px 0;

  .p-separator {
    width: 10px;
  }

  .p-ampm-picker,
  .p-second-picker,
  .p-minute-picker,
  .p-hour-picker {
    > span {
      display: grid;
      place-items: center;
      place-content: center;
      font-family: $overPass_bold;
      text-align: center;
      font-size: 1.4rem;
    }

    .p-link {
      span {
        &:before {
          display: none;
        }
      }

      &:first-child,
      &:last-child {
        &:hover {
          span {
            opacity: 0.5;
          }
        }

        span {
          width: 32px;
          height: 20px;
        }
      }

      &:first-child {
        span {
          background: $icon_up_naranja center center no-repeat;
        }
      }

      &:last-child {
        span {
          background: $icon_down_naranja center center no-repeat;
        }
      }
    }
  }
}

.p-datepicker-group-container {
  & + .p-timepicker {
    border-top: 1px solid var(--bs--color-grises-gris-20);
    background-color: var(--bs--color-grises-gris-10);
  }
}

.p-datepicker {
  @include border-radius(2px 2px 10px 10px);
  background-color: var(--bs--color-principal-blanco);

  border: 1px solid var(--bs--color-grises-gris-20);

  .p-datepicker-header {
    border: none;

    .p-datepicker-title {
      select {
        &::before,
        &::after {
          box-sizing: border-box;
        }

        appearance: none;
        // Additional resets for further consistency
        background-color: transparent;
        border: none;
        padding: 0 10px 0 0;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        min-width: 0px;
        width: auto;

        &::-ms-expand {
          display: none;
        }

        outline: none;
        min-width: 0px !important;

        &.p-datepicker-year {
          width: 80px !important;
          max-width: 80px !important;
        }

        &.p-datepicker-month {
          margin-right: 10px;
          width: 120px !important;
          max-width: 120px !important;
        }

        flex: 1 !important;
        line-height: $form-size;
        height: 36px;
        margin-bottom: 4px;
        margin-top: 4px;
        background: $icon_down_naranja calc(100% - 4px) center no-repeat;
        border: 1px solid var(--bs--color-grises-gris-50);
        @include border-radius(4px);
        padding: 0.2rem;
        text-indent: 12px;
        font-size: 1.6rem;
        cursor: pointer;
        line-height: 1.1;
        background-color: #fff;
      }

      @include flex();
      @include flex-position-child('center', 'center');
      line-height: 2rem;

      .p-datepicker-month:not(select) {
        margin-right: 5px;
      }

      .p-datepicker-month:not(select),
      .p-datepicker-year:not(select),
      select {
        font-size: 1.5rem;
        flex: 1;

        &:hover {
          color: var(--bs--color-principal-naranja-hover) !important;
        }
      }
    }

    .p-datepicker-prev {
      background: $icon_prev_naranja center center no-repeat;

      &:hover {
        opacity: 0.5;
        background: $icon_prev_naranja center center no-repeat !important;
      }
    }

    .p-datepicker-next {
      background: $icon_next_naranja center center no-repeat;

      &:hover {
        opacity: 0.5;
        background: $icon_next_naranja center center no-repeat !important;
      }
    }

    .p-datepicker-prev,
    .p-datepicker-next {
      height: 30px;
      width: 30px;

      span {
        display: none;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none !important;
      }
    }
  }

  table {
    font-size: 1rem;
    margin: 0.5rem 0;

    td {
      padding: 0.3rem 0.5rem;

      span {
        width: 30px;
        height: 30px;
      }

      > span {
        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }

  &:not(.p-disabled) {
    table {
      td {
        span {
          &:not(.p-highlight):not(.p-disabled) {
            &:hover {
              background: var(--bs--color-grises-gris-50);
              color: var(--bs--color-principal-negro);
            }

            // box-shadow: 0 0 0 0.2rem red;
          }
        }

        &.p-datepicker-today {
          span {
            &:not(.p-highlight):not(.p-disabled) {
              &:hover {
                background: var(--bs--color-secundario-naranja-dark);
                color: var(--bs--color-principal-blanco);
              }
            }
          }
        }
      }
    }
  }
}

.p-datepicker-calendar {
  thead {
    th {
      background-color: var(--bs--color-grises-gris-10);
      height: 30px;
      padding: 0.5rem;

      span {
        font-size: 1.2rem;

        font-family: $overPass_regular;

        color: var(--bs--color-principal-negro);
        text-transform: uppercase;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 1.6rem;
        font-family: $overPass_regular;

        color: var(--bs--color-grises-gris-80);
        height: 20px;

        span {
          font-size: 1.6rem;
          font-family: $overPass_regular;

          color: var(--bs--color-grises-gris-80);

          &.p-highlight {
            background: var(--bs--color-principal-negro);
            color: white;
            @include border-radius(30px);
          }

          &.p-disabled {
            opacity: 0.3;
          }

          &:hover,
          &:focus {
            background: var(--bs--color-principal-blanco) !important;
            color: var(--bs--color-principal-naranja) !important;
            font-family: $overPass_bold !important;
          }
        }

        &.p-datepicker-today {
          span {
            background: var(--bs--color-principal-naranja);
            @include border-radius(30px);
            color: white;

            &.p-highlight {
              background: var(--bs--color-principal-naranja);
              color: var(--bs--color-principal-blanco);
            }

            &:hover,
            &:focus {
              background: var(--bs--color-secundario-naranja-hover);
              color: var(--bs--color-principal-blanco);
            }
          }
        }
      }
    }
  }
}
