header.z-header {
  &.simple,
  &.comercial,
  &.privado {
    height: 54px;

    width: 100%;
    background-color: var(--bs--color-principal-blanco);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid var(--bs--color-grises-gris-10);
    @include flex();
    @include flex-position-child('center', 'center');
    @include flex-position-vertical('center');
    z-index: 9999999;

    @media #{$tablet} {
      height: 60px;
    }

    @media #{$desktop} {
      height: 70px;
    }

    a.menu {
      width: 32px;
      height: 54px;
      display: inline-block;
      background: $icon-menu center center no-repeat;
      background-size: auto 40px;
      opacity: 0.3;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

      @media #{$tablet} {
        background-size: auto 32px;
        height: 60px;
        width: 60px;
        background: $icon-menu left center no-repeat;
      }

      @media #{$desktop_t} {
        background-position: center center;
        height: 70px;
        width: 80px;
        background-size: auto 48px;
      }
    }

    a.logotipo {
      figure {
        margin: 0;
        padding: 0;

        img {
          height: 32px;
          width: auto;
          @include animate(height, 0.2s, ease-in-out);

          @media #{$tablet} {
            height: 36px;
          }

          @media #{$desktop } {
            height: 48px;
          }
        }
      }
    }

    ul.z-header-toolbar {
      @include flex();
      @include flex-position-child('right', 'center');
      @include flex-position-vertical('center');
      flex: 1;

      li {
        margin-left: 10px;
        list-style: none;

        &.no-margin {
          margin-left: 0;
        }

        a {
          text-decoration: none;
        }
      }
    }

    .z-wrapper {
      @include flex();
      @include flex-position-child('bw', 'center');
      @include flex-position-vertical('center');
      padding: 0 8px;

      @media #{$tablet} {
        padding: 0 16px;
      }

      a.logotipo {
        flex: 0 0 90px;

        @media #{$tablet} {
          flex: 1 0 130px;
        }
      }

      .z-header-toolbar {
        flex: 1;
      }
    }

    .z-btn {
      width: auto !important;
    }
  }

  &.privado {
    .z-wrapper {
      @include flex-position-child('left', 'center');

      a.menu {
        margin-right: 8px;
      }

      a.logotipo {
        max-width: 200px;
      }

      section {
        flex: 1;

        > ul {
          > li {
            position: relative;
            height: 40px;
            @include flex();
            @include flex-position-child('center', 'center');
            @include flex-position-vertical('center');

            @media #{$tablet} {
              height: 50px;
            }

            @media #{$desktop} {
              height: 64px;
            }

            > ul {
              display: none;
              position: fixed;
              top: 46px;
              right: 0;
              min-width: max-content;
              z-index: 3;
              width: 100%;

              @media #{$tablet} {
                min-width: max-content;
                width: auto;
                top: 101%;
                position: absolute;
              }
            }

            &[fix] {
              > ul {
                min-width: 300px;
              }
            }
          }
        }

        > ul {
          width: 100%;
          @include flex();
          @include flex-position-child('right', 'center');
          @include flex-position-vertical('center');

          > li.sep {
            width: 1px;
            height: 40px;
            display: inline-block;
            position: relative;
            margin: 0 8px;
            background-color: var(--bs--color-grises-gris-20);

            @media #{$tablet} {
              margin: 0 16px;
            }
          }

          > li:not(.sep) {
            list-style: none;
            min-width: 24px;
            min-height: 24px;

            @media #{$tablet} {
              min-width: 36px;
              min-height: 36px;
            }

            &[deploy] {
              &:hover {
                @include border-radius(8px 8px 0 0);
              }

              &[gris] {
                &:hover {
                  border-left: 1px solid var(--bs--color-grises-gris-20);
                  border-right: 1px solid var(--bs--color-grises-gris-20);
                  background-color: var(--bs--color-grises-gris-10);
                  border-bottom: 1px solid var(--bs--color-grises-gris-20);
                }

                > ul {
                  background-color: var(--bs--color-grises-gris-10);

                  > li {
                    border-top: 1px solid var(--bs--color-grises-gris-20);

                    &:first-child {
                      border: none;
                    }

                    a {
                      span {
                        color: var(--bs--color-grises-gris-80);
                      }

                      figure {
                        svg {
                          fill: var(--bs--color-grises-gris-80);
                        }
                      }

                      &:hover {
                        span {
                          color: var(--bs--color-principal-negro);
                        }

                        figure {
                          svg {
                            fill: var(--bs--color-principal-negro);
                          }
                        }
                      }
                    }
                  }
                }
              }

              &[naranja] {
                &:hover {
                  > .user-id,
                  > a {
                    color: var(--bs--color-principal-blanco);

                    span {
                      color: var(--bs--color-principal-blanco);
                    }

                    figure {
                      img {
                        filter: grayscale(100%) brightness(500%);
                        -webkit-filter: grayscale(100%) brightness(500%);
                      }
                    }
                  }

                  border-left: 1px solid var(--bs--color-secundario-naranja-50);
                  border-right: 1px solid var(--bs--color-secundario-naranja-50);
                  border-bottom: 1px solid
                    var(--bs--color-secundario-naranja-50);
                  background-color: var(--bs--color-principal-naranja);
                }

                > ul {
                  background-color: var(--bs--color-principal-naranja);

                  > li {
                    border-top: 1px solid var(--bs--color-secundario-naranja-50);

                    &:first-child {
                      border: none;
                    }

                    a {
                      span {
                        color: var(--bs--color-principal-blanco) !important;
                      }

                      &:hover {
                        span {
                          color: var(
                            --bs--color-secundario-naranja-50
                          ) !important;
                        }

                        figure {
                          svg {
                            fill: var(
                              --bs--color-secundario-naranja-50
                            ) !important;
                          }
                        }
                      }

                      figure {
                        svg {
                          fill: var(--bs--color-principal-blanco) !important;
                        }
                      }
                    }
                  }
                }
              }
            }

            &:hover {
              // border-right: 1px solid var(--bs--color-grises-gris-20);
              //border-left: 1px solid var(--bs--color-grises-gris-20);
              // @include box-shadow(0px, -6px, 10px, 0px, rgba(0, 0, 0, 0.1), false);
              > a,
              > .user-id {
                filter: (100%);
                -webkit-filter: (100%);
              }

              &[deploy] {
                > ul {
                  display: block;

                  @include box-shadow(
                    0px,
                    4px,
                    10px,
                    0px,
                    rgba(0, 0, 0, 0.2),
                    false
                  );

                  @media #{$tablet} {
                    @include border-radius(10px 0 10px 10px);

                    &:after {
                      z-index: 1;
                      content: '';
                      position: absolute;
                      top: 0;
                      right: 100%;
                      width: 100px;
                      display: block;
                      background-color: rgba(0, 0, 0, 0);
                      height: 150%;
                    }

                    &:before {
                      z-index: 1;
                      content: '';
                      position: absolute;
                      top: 100%;
                      left: -50px;
                      width: calc(100% + 100px);
                      display: block;
                      background-color: rgba(0, 0, 0, 0);
                      height: 200px;
                    }
                  }

                  > li {
                    z-index: 10;
                    list-style: none;
                    padding: 0 16px;

                    a {
                      @include flex();
                      @include flex-position-child('bw', 'center');
                      @include flex-position-vertical('center');
                      //   color: var(--bs--color-grises-gris-80);
                      text-decoration: none;
                      height: 64px;

                      figure {
                        margin: 0 0 0 16px;

                        &[big] {
                          svg {
                            height: 32px;
                            width: auto;
                          }
                        }
                      }
                    }
                  }

                  &[invert] {
                    > li {
                      > a {
                        span {
                          order: 2;
                        }

                        figure {
                          order: 1;
                          margin: 0 16px 0 0;
                        }
                      }
                    }
                  }
                }
              }
            }

            > a,
            > .user-id {
              text-decoration: none;

              &[push] {
                position: relative;

                &:after {
                  content: attr(push);

                  position: absolute;
                  display: block;
                  font-size: 1.1rem;
                  font-family: $overPass_bold;
                  padding: 0 5px;
                  margin: 0;
                  @include border-radius(20px);
                  top: -6px;
                  right: 15%;
                  background-color: var(--bs--color-secundario-rojo);
                  color: var(--bs--color-principal-blanco);
                }
              }

              cursor: pointer;
              min-width: 24px;
              min-height: 24px;
              display: inline-block;

              @media #{$tablet} {
                min-width: 36px;
                min-height: 36px;
              }

              figure {
                width: 24px;
                height: 24px;
                display: inline-block;

                @media #{$tablet} {
                  width: 36px;
                  height: 36px;
                }

                img {
                  width: auto;
                  height: 100%;
                }
              }
            }

            > .user-id {
              padding-left: 4px;
              padding-right: 4px;

              @media #{$tablet} {
                padding-left: 0px;
                padding-right: 0px;
              }
            }

            > a {
              padding-left: 4px;
              padding-right: 4px;

              @media #{$tablet} {
                padding-left: 8px;
                padding-right: 8px;
              }
            }

            > .user-id {
              @include flex();
              @include flex-position-child('right', 'center');
              @include flex-position-vertical('center');

              figure {
                @media #{$tablet} {
                  margin-right: 8px;
                }
              }

              span {
                display: none;

                @media #{$desktop} {
                  flex: 1;
                  text-align: right;
                  font-size: 1.4rem;
                  line-height: 1;
                  margin-right: 8px;
                  padding-left: 8px;
                  display: inline-block;

                  strong {
                    display: block;
                    font-family: $overPass_bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.comercial {
    section {
      @include flex();
      @include flex-position-child('right', 'center');
      @include flex-position-vertical('center');

      .z-btn {
        margin-left: 4px;

        height: $btn-height - 4px;

        @media #{$desktop } {
          margin-left: 6px;
          height: $btn-height;
        }

        @media #{$desktop_t } {
          margin-left: 10px;
          height: $btn-height;
        }
      }
    }
  }
}

.z-header-separator {
  width: 10px;
  position: relative;
  height: 50px;
  display: none;

  &:not(.ghost) {
    &:after {
      @include centrar();
      width: 1px;
      height: 100%;
      display: block;
      content: '';
      background-color: var(--bs--color-grises-gris-20);

      @media #{$tablet } {
        display: block;
      }
    }
  }
}

.z-header-span {
  height: 60px;
  display: block;
  width: 100%;

  @media #{$tablet} {
    height: 70px;
  }

  @media #{$desktop} {
    height: 80px;
  }
}
