.z-selector {
  position: relative;
  min-height: 80px;
  @include border-radius(4px);
  border: 3px solid var(--bs--color-grises-gris-10);
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;
  @include flex-inline();
  @include flex-position-child('bw', 'center');
  @include flex-position-vertical('center');
  margin: 6px 6px 6px 0;
  text-decoration: none;
  color: var(--bs--color-principal-negro);
  background-color: var(--bs--color-principal-blanco);

  &.sombra {
    border: 3px solid var(--bs--color-principal-blanco);
    @include border-radius(10px);
    @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.1), false);
  }

  &:hover {
    border: 3px solid var(--bs--color-secundario-naranja-50);
  }

  &.active {
    &.simple {
      background-color: var(--bs--color-principal-naranja);
      border: 3px solid var(--bs--color-principal-naranja);

      .label {
        color: var(--bs--color-principal-blanco);
      }
    }
  }

  &.simple {
    width: auto;
    min-width: 50px;
    max-width: 200px;
    min-height: 50px;
    span.label {
      line-height: 1;
    }
  }

  @media #{$desktop} {
    width: auto;
    max-width: 800px;
    min-width: 220px;
  }

  &.w-100p {
    width: 100%;
  }

  @for $i from 1 through 20 {
    $width_selector: ($i * 5%);
    $name_selector: ($i * 5);

    &.w-#{$name_selector}p {
      width: $width_selector;
    }
  }

  &.suspended {
    figure {
      opacity: 0.5;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }

    span {
      &.label,
      &.preface,
      &.caption {
        color: var(--bs--color-grises-gris-50) !important;
      }

      &.arrow,
      &.delete {
        opacity: 0.5;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }
    }
  }

  &[disabled] {
    border: 3px solid var(--bs--color-grises-gris-10);
    cursor: not-allowed;

    span {
      &.caption,
      &.label,
      &.preface {
        color: var(--bs--color-grises-gris-50);

        color: var(--bs--color-grises-gris-50);
      }

      &.arrow,
      &.delete {
        opacity: 0.5;
      }
    }
  }

  > figure {
    margin-right: 16px;
    width: 30px;
    height: 30px;
    display: inline-block;

    img {
      width: 100%;
      height: auto;
    }
  }

  &.grande {
    > figure {
      height: auto;
      max-height: 48px;
      width: 48px;
    }
  }

  &.superior {
    @include flex-position-child('bw', 'top');

    span.arrow {
      margin-top: 20px;
    }
  }

  div.datos-informacion {
    width: 100%;
    flex: 0 0 100%;

    @include flex-inline();
    @include flex-position-child('bw', 'center');
    @include flex-position-vertical('center');
    margin-top: 12px;
    padding-top: 8px;
    border-top: 1px solid var(--bs--color-grises-gris-20);

    .dato {
      span {
        display: block;
        color: var(--bs--color-grises-gris-50);
        font-size: 1.4rem;
        font-family: $overPass_regular;
        line-height: 1.3;
      }

      h4 {
        display: block;
        color: var(--bs--color-principal-azul);
        font-size: 1.8rem;
        font-family: $overPass_bold;
      }

      &:last-child {
        > span,
        > h4 {
          text-align: right;
        }
      }

      &.estado {
        flex: 1;
      }

      &.monto {
        flex: 1;
      }
    }
  }

  span {
    text-align: left;

    &.arrow {
      height: 20px;
      width: 20px;
      display: inline-block;
      background: $icon_next_naranja center center no-repeat;

      margin-left: 16px;
    }

    &.delete {
      height: 20px;
      width: 20px;
      display: inline-block;
      background: $icon_close_naranja center center no-repeat;
      background-size: auto 18px;
      margin-left: 16px;
    }

    &.label {
      font-family: $overPass_bold;
      font-size: 1.5rem;
      color: var(--bs--color-principal-negro);
      flex: 1;

      line-height: normal;

      @media #{$desktop} {
        line-height: 2.2rem;
      }

      & + .caption,
      & + .z-caption {
        margin-left: 16px;
      }
    }

    &.z-title {
      display: block;
      font-family: $overPass_bold;
      font-size: 1.5rem;
      color: var(--bs--color-principal-negro);
      line-height: normal;
    }

    &.z-description {
      display: block;
      font-family: $overPass_regular;
      font-size: 1rem;
      color: var(--bs--color-grises-gris-80);
      line-height: normal;

      @media #{$tablet} {
        font-size: 1.1rem;
      }
    }

    &.preface {
      display: block;
      font-family: $overPass_regular;
      font-size: 1.3rem;
      color: var(--bs--color-principal-naranja);
      line-height: normal;
    }

    &.z-caption {
      display: block;
      font-family: $overPass_regular;
      font-size: 1.3rem;
      color: var(--bs--color-grises-gris-80);
      line-height: normal;
    }

    &.z-caption {
      strong {
        color: var(--bs--color-principal-naranja);
        font-family: $overPass_bold;
      }
    }
  }

  &.expandible {
    width: 100%;

    &[open] {
      > summary.cta {
        padding: 0px 0px 10px 0;
        border-bottom: 1px solid var(--bs--color-grises-gris-20);
      }

      span {
        &.arrow {
          background: $icon_down_naranja center center no-repeat;
        }
      }
    }

    > summary::-webkit-details-marker {
      display: none;
    }

    .data {
      margin-top: 16px;
    }

    > summary.cta {
      @include flex-inline();
      @include flex-position-child('bw', 'center');
      @include flex-position-vertical('center');
      cursor: pointer;
      width: 100%;
      min-height: 50px;
      padding: 0px;
      -webkit-user-select: none;
      /* Safari */
      -ms-user-select: none;
      /* IE 10 and IE 11 */
      user-select: none;

      /* Standard syntax */
      > figure {
        margin-right: 16px;
        width: 30px;
        height: 30px;
        display: inline-block;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &.grande {
      > summary.cta {
        > figure {
          height: auto;
          max-height: 48px;
          width: 48px;
        }
      }
    }
  }

  &.visited {
    border: 3px solid var(--bs--color-secundario-naranja-50);

    span {
      &.arrow {
        background-image: $check_button_naranja;
      }
    }
  }
}
