.z-badges {
  display: inline-block;
  background-color: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-blanco);
  margin-right: 10px;
  position: relative;

  span {
    user-select: none;
    height: 24px;
    line-height: 22px;
    font-family: $overPass_bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    white-space: nowrap;
    vertical-align: middle;
    display: grid;
    place-content: self-start center;
    text-align: left;

    @media #{$tablet} {
      height: 28px;
      line-height: 26px;
      font-size: 1.4rem;
    }
  }

  button {
    margin: 0 12px 0 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;

    svg {
      fill: var(--bs--color-grises-gris-10);
      position: absolute;
      height: 16px;
      width: 16px;
      top: calc(50% - 8px);
      right: 8px;
    }
  }

  margin: 0 12px 12px 0;
  padding: 0px 32px 0px 12px;

  &.simple {
    padding-right: 12px;
  }

  font-size: 1.4rem;
  text-transform: uppercase;
  vertical-align: top;
  @include border-radius(16px);
  box-sizing: border-box;
  font-family: $overPass_regular;
  box-sizing: border-box;
  height: 24px;
  @include box-shadow(0px, 0px, 2px, 2px, rgba(0, 0, 0, 0.09), false);
  @include animate(all, 0.1s, linear);

  &:not(.simple) {
    &:hover {
      opacity: 0.5;
    }
  }

  @media #{$tablet} {
    height: 28px;
  }

  &.lleno {
    color: var(--bs--color-principal-blanco);

    button {
      svg {
        fill: var(--bs--color-principal-blanco);
      }
    }

    &.gris-10 {
      background-color: var(--bs--color-grises-gris-10);

      span {
        color: var(--bs--color-principal-negro) !important;
      }

      button {
        svg {
          fill: var(--bs--color-grises-gris-80);
        }
      }
    }

    &.gris-40 {
      background-color: var(--bs--color-grises-gris-40);
      span {
        color: var(--bs--color-principal-negro) !important;
      }
    }

    &.gris-80 {
      background-color: var(--bs--color-grises-gris-80);
    }

    &.rojo {
      background-color: var(--bs--color-secundario-rojo);
    }

    &.negro {
      background-color: var(--bs--color-principal-negro);
    }

    &.naranja {
      background-color: var(--bs--color-principal-naranja);
    }
  }

  &.borde {
    border-width: 2px;
    border-style: solid;

    &.gris-10 {
      border-color: var(--bs--color-grises-gris-10);
      color: var(--bs--color-grises-gris-80);

      span {
        color: var(--bs--color-grises-gris-80) !important;
      }

      button {
        svg {
          fill: var(--bs--color-grises-gris-80);
        }
      }
    }

    &.gris-40 {
      border-color: var(--bs--color-grises-gris-40);
      color: var(--bs--color-grises-gris-40);

      button {
        svg {
          fill: var(--bs--color-grises-gris-40);
        }
      }
    }

    &.gris-80 {
      border-color: var(--bs--color-grises-gris-80);
      color: var(--bs--color-grises-gris-80);

      button {
        svg {
          fill: var(--bs--color-grises-gris-80);
        }
      }
    }

    &.rojo {
      border-color: var(--bs--color-secundario-rojo);
      color: var(--bs--color-secundario-rojo);

      button {
        svg {
          fill: var(--bs--color-secundario-rojo);
        }
      }
    }

    &.negro {
      border-color: var(--bs--color-principal-negro);
      color: var(--bs--color-principal-negro);

      button {
        svg {
          fill: var(--bs--color-principal-negro);
        }
      }
    }

    &.naranja {
      border-color: var(--bs--color-principal-naranja);
      color: var(--bs--color-principal-naranja);

      button {
        svg {
          fill: var(--bs--color-principal-naranja);
        }
      }
    }
  }
}

.z-badges-group {
  width: 100%;
  @include flex();
  @include flex-position-child('left', 'top');
  @include flex-position-vertical('top');

  .z-badges {
    flex: 1;
  }

  @media #{$tablet} {
    .z-badges {
      flex: none;
    }
  }

  &.flex {
    .z-badges {
      flex: 1 !important;
    }
  }
}
