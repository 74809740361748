@mixin centrar($offset-x: 50%, $offset-y: 50%) {
  position: absolute;
  top: $offset-y;
  left: $offset-x;
  transform: translate($offset-x * -1, $offset-y * -1);
}
@mixin border-radius($radius, $status: false) {
  @if ($status) {
    -webkit-border-radius: $radius !important;
    -moz-border-radius: $radius !important;
    -ms-border-radius: $radius !important;
    border-radius: $radius !important;
  } @else {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
  }
}
@mixin scrolling(
  $width: 2px,
  $height: 2px,
  $shadow: 6px,
  $shadowcolor: rgba(0, 0, 0, 0.2),
  $background: #f0aabb
) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 $shadow $shadowcolor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $background;

    cursor: pointer;
  }
}
@mixin flex($type: false) {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;

  @if ($type) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
  } @else {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
}

@mixin flex-inline($type: false) {
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;

  @if ($type) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
  } @else {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
}

@mixin flex-position-child($horizontal: "left", $vertical: "top") {
  // left, right, center, bw, ar,unset
  @if $horizontal== "left" {
    justify-content: flex-start;
  } @else if $horizontal== "center" {
    justify-content: center;
  } @else if $horizontal== "right" {
    justify-content: flex-end;
  } @else if $horizontal== "ar" {
    justify-content: space-around;
  } @else if $horizontal== "bw" {
    justify-content: space-between;
  } @else if $horizontal== "unset" {
    justify-content: unset;
  } @else if $horizontal== "even" {
    justify-content: space-evenly;
  }

  // top, bottom, center, base, str
  @if $vertical== "top" {
    align-items: flex-start;
  } @else if $vertical== "center" {
    align-items: center;
  } @else if $vertical== "bottom" {
    align-items: flex-end;
  } @else if $vertical== "base" {
    align-items: baseline;
  } @else if $vertical== "str" {
    align-items: stretch;
  } @else if $vertical== "unset" {
    align-items: unset;
  }
}
@mixin no-shadow() {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
@mixin box-shadow($left, $top, $blur, $size, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $left $top $blur $size $color;
    -moz-box-shadow: inset $left $top $blur $size $color;
    -ms-box-shadow: inset $left $top $blur $size $color;
    box-shadow: inset $left $top $blur $size $color;
  } @else {
    -webkit-box-shadow: $left $top $blur $size $color;
    -moz-box-shadow: $left $top $blur $size $color;
    -ms-box-shadow: $left $top $blur $size $color;
    box-shadow: $left $top $blur $size $color;
  }
}

@mixin flex-position-vertical($distribution: "top") {
  @if $distribution== "top" {
    align-content: flex-start;
    -webkit-align-content: flex-start;
    -moz-flex-direction: flex-start;
    -ms-flex-direction: flex-start;
  } @else if $distribution== "center" {
    align-content: center;
    -webkit-align-content: center;
    -moz-flex-direction: center;
    -ms-flex-direction: center;
  } @else if $distribution== "bottom" {
    align-content: flex-end;
    -webkit-align-content: flex-end;
    -moz-flex-direction: flex-end;
    -ms-flex-direction: flex-end;
  } @else if $distribution== "str" {
    align-content: stretch;
    -webkit-align-content: stretch;
    -moz-flex-direction: stretch;
    -ms-flex-direction: stretch;
  } @else if $distribution== "bw" {
    align-content: space-between;
    -webkit-align-content: space-between;
    -moz-flex-direction: space-between;
    -ms-flex-direction: space-between;
  } @else if $distribution== "ar" {
    align-content: space-around;
    -webkit-align-content: space-around;
    -moz-flex-direction: space-around;
    -ms-flex-direction: space-around;
  } @else if $distribution== "ev" {
    align-content: space-evenly;
    -webkit-align-content: space-evenly;
    -moz-flex-direction: space-evenly;
    -ms-flex-direction: space-evenly;
  }
}
@mixin flex-direction($position: "row", $reverse: false) {
  @if ($position== "row" and $reverse==true) {
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
  } @else if ($position== "row" and $reverse==false) {
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
  } @else if ($position== "column" and $reverse==true) {
    flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
  } @else if ($position== "column" and $reverse==false) {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
  } @else if ($position== "initial") {
    flex-direction: initial;
    -webkit-flex-direction: initial;
    -moz-flex-direction: initial;
    -ms-flex-direction: initial;
  }
}
@mixin lineClamp ($lines:2) {

    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
@mixin grid($columns: 3, $gap_c: 8px, $gap_r: 8px, $grid_h: none) {
  display: -ms-grid;
  display: -webkit-inline-grid;
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  column-gap: $gap_c;
  row-gap: $gap_r;
  min-height: $grid_h;
}
@mixin animate($property: all, $duration: 0.3s, $ease: linear) {
  -webkit-transition: $property $duration $ease;
  -moz-transition: $property $duration $ease;
  -ms-transition: $property $duration $ease;
  -o-transition: $property $duration $ease;
  transition: $property $duration $ease;
}

@mixin figure-size($height: 48px, $width: 48px) {
  height: $height !important;
  width: $width !important;
}
@mixin illus-size(  $width: 48px) {
  height: auto;
  width: $width !important;
}
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}
