.z-forms {
  width: 100%;
  display: block;
  margin-bottom: 8px;
  position: relative;

  .prefix {
    position: absolute;
    left: 0px;
    line-height: $form-size;
    width: auto;
    min-width: min-content;
    border-right: 1px solid var(--bs--color-grises-gris-50) !important;
    color: var(--bs--color-grises-gris-80) !important;
    z-index: 13;
    height: $form-size;
    padding: 0 8px;
    user-select: none;

    & + input {
      padding-left: 36px;
    }
  }

  [disabled] {
    & ~ .prefix {
      cursor: not-allowed;
      border-right: 1px solid var(--bs--color-grises-gris-40) !important;
      color: var(--bs--color-grises-gris-40) !important;
    }
  }

  .mix {
    position: relative;
    width: 100%;
    @include flex();
    @include flex-position-child('bw', 'top');
    @include flex-position-vertical('top');

    input.p-inputtext {
      flex: 1;
      padding-right: 40px;
    }

    &.number {
      input.p-inputtext {
        text-align: center !important;
        padding-right: 0px;
      }
    }

    .btn {
      margin-bottom: 6px;
    }

    p-checkbox {
      flex: 0 0 100%;

      @media #{$tablet} {
        flex: 1;
      }
    }
  }

  p-checkbox {
    width: 100%;
  }
}
