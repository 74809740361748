.z-accordeon {
  .accordeon-item {
    border: 1px solid var(--bs--color-grises-gris-10);
    margin-bottom: 10px;

    input {
      display: none;

      &:checked {
        & ~ .accordeon-item-content {
          height: auto;
          display: block;
        }

        & ~ .accordeon-item-header {
          figure {
            &::before {
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              transform: rotate(180deg);
              filter: grayscale(0%);
              -webkit-filter: grayscale(0%);
            }
          }

          span {
            font-family: $overPass_bold;
            color: var(--bs--color-principal-naranja);
          }
        }
      }
    }

    .accordeon-item-header {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
      width: 100%;
      cursor: pointer;
      min-height: 50px;
      @include flex-inline();
      @include flex-position-child('left', 'center');
      @include flex-position-vertical('center');
      position: relative;
      border-bottom: 1px solid var(--bs--color-grises-gris-10);
      @include animate(all, 0.2s, linear);

      figure {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50px;
        background: none;

        @media #{$tablet} {
          width: 60px;
        }

        &::before {
          @include animate(all, 0.2s, linear);
          display: block;
          content: '';
          height: 18px;
          width: 18px;
          position: absolute;
          top: calc(50% - 9px);
          left: calc(50% - 9px);
          background: $icon_up_naranja center center no-repeat;
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      span {
        margin-left: 10px;
        width: calc(100% - 10px);
        padding-left: 20px;

        @media #{$tablet} {
          margin-left: 20px;
          width: calc(100% - 20px);
          padding-left: 10px;
        }
      }
    }

    .accordeon-item-content {
      display: none;
      padding: 10px 30px;
    }
  }
}
