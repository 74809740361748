.p-slider {
  background: var(--bs--color-grises-gris-40);
  border: 0 none;
  border-radius: 3px;
}
.p-slider.p-slider-horizontal {
  height: 0.486rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.7715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.543rem;
  width: 1.543rem;
  background: var(--bs--color-principal-naranja);
  border: 2px solid var(--bs--color-principal-naranja);
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #93cbf9;
}
.p-slider .p-slider-range {
  border: 0 none;
  border-radius: 3px;
  background: var(--bs--color-principal-naranja);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--bs--color-secundario-naranja-90);
  border-color: #64b5f6;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s, left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s, bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}
