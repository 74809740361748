.z-publicidad-banner {
  position: relative;
  overflow: hidden;
  background-color: var(--bs--color-grises-gris-10);
  width: 100%;
  @include border-radius(4px);

  &.horizontal {
    height: 480px;

    @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.05), false);

    @media #{$tablet} {
      height: 320px;
    }

    figure {
      @include centrar(0%, 0%);
      width: 100%;
      display: block;
      height: 60%;

      @media #{$tablet} {
        @include centrar(0%, 50%);
        width: 60%;
        height: 320px;
      }

      @media #{$desktop} {
        width: 55%;
      }

      .blob {
        &.top-left {
          @include centrar(0%, -2%);
          background: $Blob-top-left bottom right no-repeat;
          height: 80px;
          width: 80px;
        }

        &.top-right {
          @include centrar(100%, -2%);
          background: $Blob-top-right left bottom no-repeat;
          height: 120px;
          width: 120px;
        }

        &.bottom-center {
          @include centrar(50%, 103%);
          background: $Blob-center-bottom center center no-repeat;
          height: 80px;
          width: 100%;
          display: none;

          @media #{$desktop} {
            display: block;
          }
        }
      }

      img {
        @include border-radius(4px 4px 0 0);
        width: 100%;
        height: 100%;
        object-fit: cover;

        height: 320px;
      }
    }

    figcaption {
      @include border-radius(0 0 4px 4px);
      height: 40%;
      width: 100%;
      display: block;
      @include centrar(50%, 100%);
      background: $curve-grad-horizontal center top no-repeat;
      background-size: 110% auto;

      @media #{$tablet} {
        @include centrar(100%, 0%);
        background: $curve-grad-vertical left center no-repeat;
        background-size: auto 110%;
        width: 60%;
        height: 320px;
      }

      @media #{$desktop} {
        width: 55%;
      }

      section {
        @include centrar();
        width: 100%;
        padding: 0 16px;

        h1,
        h2 {
          @media #{$no-mobile} {
            &.z-titulo {
              text-align: center !important;
            }
          }
        }

        @media #{$tablet} {
          padding: 0 16px 0 120px;
        }

        @media #{$desktop} {
          padding: 0 32px 0 120px;
        }
      }
    }

    &.invertido {
      @include border-radius(4px 4px 0 0);

      figure {
        @media #{$tablet} {
          @include centrar(100%, 50%);
        }
      }

      figcaption {
        @media #{$tablet} {
          @include centrar(0%, 50%);
          background-image: $curve-grad-vertical-inv;
          background-position: right center;
        }

        section {
          padding: 0 16px;

          @media #{$tablet} {
            padding: 0 120px 0 16px;
          }

          @media #{$desktop} {
            padding: 0 120px 0 32px;
          }
        }
      }
    }
  }

  &.vertical {
    display: block;
    max-width: 550px;
    height: 690px;

    figure {
      height: 690px;
      width: 100%;
      display: block;

      img {
        height: 690px;
        width: 100%;
        object-fit: cover;
      }
    }

    figcaption {
      width: 100%;
      min-height: 100px;
      display: block;
      margin: 0;

      .z-btn {
        width: 100%;
      }

      &.top {
        padding: 16px 16px 32px;
        @include centrar(50%, 0%);
        background: $Blob-top-big center bottom no-repeat;
        background-size: cover;
        @include flex();
        @include flex-position-child('center', 'center');
        @include flex-position-vertical('center');
      }

      &.bottom {
        padding: 32px 16px 8px;
        @include centrar(50%, 100%);
        background: $Blob-bottom-big center top no-repeat;
        background-size: cover;
        @include flex();
        @include flex-position-child('center', 'center');
        @include flex-position-vertical('center');
      }
    }
  }
}
