.z-modulo-carga-imagenes {
  outline: 2px dashed var(--bs--color-grises-gris-50);
  min-height: 172px;
  @include border-radius(4px);
  margin-bottom: 16px;
  padding: 4px 10px;
  width: calc(100% - 20px);
  @include flex();
  @include flex-position-child('bw', 'str');
  @include flex-position-vertical('str');

  .ver-imagenes {
    flex: 0 0 100% !important;
    height: 24px;

    a {
      color: var(--bs--color-grises-gris-50);

      &:hover {
        color: var(--bs--color-principal-naranja);
      }

      cursor: pointer;
      width: 100%;
      line-height: 24px;
      text-align: center;
      font-size: 1.4rem;
      display: inline-block;
    }
  }

  &.invertido {
    .cargas {
      order: 1;
    }

    .informacion {
      order: 2;
      border: none;
      border-top: 1px solid var(--bs--color-grises-gris-20);
    }
  }

  .cargas {
    flex: 0 0 100%;
    min-height: 120px;
    width: 100%;

    @media #{$tablet} {
      min-height: 150px;
    }

    @include flex();
    @include flex-position-child('even', 'center');
    @include flex-position-vertical('center');

    .elemento-cargado {
      flex: 1;
      max-width: 84px;
      margin: 0 10px 0 0;
      position: relative;

      a.imagen {
        width: 100%;
        display: inline-block;

        figure {
          display: inline-block;
          width: 100%;

          img {
            width: 100%;
            height: 72px;
            object-fit: cover;
          }
        }

        cursor: pointer;
        @include animate(opacity, 0.1s, linear);

        &:hover {
          opacity: 0.5;
        }
      }

      a.eliminar {
        height: 24px;
        width: 24px;
        position: absolute;
        bottom: 0px;
        right: 0px;
        background: var(--bs--color-secundario-rojo) $icon-modulo-imagen-del
          center center no-repeat;
        background-size: auto 50%;
        @include border-radius(50%);
        cursor: pointer;

        &:hover {
          background-color: var(--bs--color-secundario-rojo-hover);
        }
      }
    }

    a.carga {
      max-width: 124px;
      height: 130px;
      display: inline-block;

      @include flex();
      @include flex-position-child('center', 'center');
      @include flex-position-vertical('center');
      cursor: pointer;

      &:hover {
        figure {
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
        }

        span {
          color: var(--bs--color-grises-gris-80);
        }
      }

      &.full {
        span {
          display: none;
        }
      }

      figure {
        @include animate(all, 0.1s, linear);
        display: inline-block;
        width: 50px;
        height: 50px;
        background: $circle-sum center center no-repeat;
        background-size: 28px auto;
      }

      span {
        @include animate(all, 0.1s, linear);
        text-align: center;
        font-size: 1.5rem;
        color: var(--bs--color-grises-gris-50);
        text-align: center;
      }
    }
  }

  .informacion {
    border: none;
    border-bottom: 1px solid var(--bs--color-grises-gris-20);
    flex: 0 0 100%;
    @include flex();
    @include flex-position-child('bw', 'center');
    @include flex-position-vertical('center');
    height: 50px;

    h4 {
      flex: 1;
      text-align: center;
      color: var(--bs--color-principal-naranja);
      font-family: $overPass_bold;
      padding-left: 25px;
      line-height: 1.4;
      font-size: 1.5rem;
      @include lineClamp();

      &.no-padding {
        padding-left: 0;
      }
    }
  }
}

span.z-tooltip {
  height: 24px;
  width: 24px;
  display: inline-block;
  background: $tooltip-file center center no-repeat;
  background-size: 24px 24px;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  opacity: 0.7;
  &.input {
    height: 20px;
    width: 24px;
    background-size: 20px auto;
    position: absolute;
    right: 0;
    top: 0;
    .mensaje {
      min-width: 150px;
      min-width: fit-content;
      max-width: max-content;
    }
  }

  .mensaje {
    z-index: 401;
    font-family: $overPass_bold;

    &.uppercase {
      text-transform: uppercase;
    }

    &::after {
      position: absolute;

      content: '\25B2';
      bottom: inherit;
      top: -16px;
      right: 12px;
      font-size: 2rem;
      color: var(--bs--color-secundario-naranja-10);
      height: 8px;
      width: 10px;
    }

    bottom: inherit;
    top: 110%;
    display: none;
    position: absolute;
    background-color: var(--bs--color-secundario-naranja-10);
    color: var(--bs--color-principal-naranja);
    padding: 10px;
    font-size: 1.6rem;
    text-align: right;
    width: 170px;
    line-height: 1.4;

    right: 0px;
    @include border-radius(8px);
  }

  &:hover {
    opacity: 1;
    .mensaje {
      display: block;
    }
  }

  .invertido & {
    .mensaje {
      bottom: 110%;
      top: inherit;

      &::after {
        content: '\25BC';
        bottom: 0px;
        top: inherit;
      }
    }
  }
}
