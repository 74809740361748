button {
  margin: 0;
  padding: 0;
  border: none; //custom border
  outline: none;
  background: none;
  cursor: pointer;
}

div.z-btn,
button.z-btn,
a.z-btn {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1;
  height: $btn-height;
  @include border-radius($btn-height/2);
  text-align: center;
  padding: 0 16px;
  min-width: 160px;
  cursor: pointer;
  font-size: var(--bs--font-size-button);
  font-family: $overPass_bold;
  text-transform: uppercase;
  margin: 6px;
  position: relative;
  @include flex-inline();
  @include flex-position-child('center', 'center');
  @include flex-position-vertical('center');
  //
  text-decoration: none;
  width: 100%;

  @media #{$tablet } {
    width: auto;
  }

  &.no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.mobile-compact {
    @media #{$no-mobile } {
      padding-left: 12px;
      padding-right: 12px;
      min-width: 10px;
    }
  }

  &.center {
    margin: 0 auto !important;
    text-align: center;
    display: block;
  }

  &.extendido {
    padding-left: 48px !important;
    padding-right: 48px !important;

    @media #{$no-mobile } {
      padding-left: 32px !important;
      padding-right: 32px !important;
    }
  }

  &.min {
    min-width: 220px;

    @for $i from 0 through 50 {
      $width: 0px+ ($i * 10);
      $name: 0+ ($i * 10);

      &.w-#{$name} {
        min-width: auto;

        @media #{$tablet } {
          min-width: $width;
        }
      }
    }
  }

  &-primary {
    background-color: var(--bs--color-principal-naranja);
    color: var(--bs--color-principal-blanco);

    &:hover {
      background-color: var(--bs--color-secundario-naranja-hover);
    }

    &:active {
      background-color: var(--bs--color-secundario-naranja-hover);
    }

    &[disabled] {
      cursor: not-allowed;

      background-color: var(--bs--color-secundario-naranja-20);

      &:hover {
        background-color: var(--bs--color-secundario-naranja-20);
      }

      &.icon {
        figure {
          img {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &-primary-negro {
    background-color: var(--bs--color-principal-negro);
    color: var(--bs--color-principal-blanco);

    &:hover {
      background-color: var(--bs--color-secundario-negro-hover);
    }

    &:active {
      background-color: var(--bs--color-secundario-negro-hover);
    }

    &[disabled] {
      cursor: not-allowed;

      background-color: var(--bs--color-grises-gris-50);
      color: var(--bs--color-grises-gris-40);

      &:hover {
        color: var(--bs--color-grises-gris-40);
        background-color: var(--bs--color-grises-gris-50);
      }

      &.icon {
        figure {
          img {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &-primary-blanco {
    background-color: var(--bs--color-principal-blanco);
    color: var(--bs--color-principal-naranja);

    &:hover,
    &:active {
      color: var(--bs--color-secundario-naranja-hover);
    }
  }

  &.full {
    width: 100%;
  }

  &.regular {
    font-family: $overPass_regular;
  }

  &-download {
    color: var(--bs--color-principal-naranja);
    background: none;
    @include flex-inline();
    @include flex-position-child('left', 'center');
    @include flex-position-vertical('center');

    span {
      flex: 1;
      text-align: left;
    }

    svg {
      fill: var(--bs--color-principal-naranja);
    }

    &:hover {
      color: var(--bs--color-secundario-naranja-hover);
      // border: 2px solid var(--bs--color-secundario-naranja-hover);

      svg {
        fill: var(--bs--color-secundario-naranja-hover);
      }
    }

    &:active {
      color: var(--bs--color-secundario-naranja-hover);

      border: 2px solid var(--bs--color-secundario-naranja-hover);
    }

    figure {
      margin: 0;
      padding: 0;
      height: 24px;

      &:first-child {
        margin-right: 6px;

        @media #{$tablet} {
          margin-right: 10px;
        }
      }

      &:last-child {
        margin-left: 6px;

        @media #{$tablet} {
          margin-left: 10px;
        }
      }

      svg {
        height: 24px;
        width: auto;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      color: var(--bs--color-secundario-naranja-20);
      //  border: 2px solid var(--bs--color-secundario-naranja-20);
      background: none !important;

      svg {
        fill: var(--bs--color-secundario-naranja-20);
      }

      &:hover {
        color: var(--bs--color-secundario-naranja-20);
        background-color: var(--bs--color-secundario-naranja-20);
      }

      figure {
        img {
          opacity: 0.3;
        }
      }
    }
  }

  &-secondary {
    color: var(--bs--color-principal-naranja);
    border: 2px solid var(--bs--color-principal-naranja);
    background: none;

    &.no-border {
      border-color: rgba(1, 1, 1, 0);

      &:hover {
        border-color: rgba(1, 1, 1, 0);
      }
    }

    &:hover {
      color: var(--bs--color-secundario-naranja-hover);

      border: 2px solid var(--bs--color-secundario-naranja-hover);
    }

    &:active {
      color: var(--bs--color-secundario-naranja-hover);

      border: 2px solid var(--bs--color-secundario-naranja-hover);
    }

    &[disabled] {
      cursor: not-allowed;

      color: var(--bs--color-secundario-naranja-20);
      border: 2px solid var(--bs--color-secundario-naranja-20);
      background: none !important;

      &:hover {
        color: var(--bs--color-secundario-naranja-20);
        background-color: var(--bs--color-secundario-naranja-20);
      }

      &.icon {
        figure {
          img {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &-secondary-negro {
    color: var(--bs--color-principal-negro);
    border: 2px solid var(--bs--color-principal-negro);

    &:hover {
      color: var(--bs--color-secundario-negro-hover);
      border: 2px solid var(--bs--color-secundario-negro-hover);
    }

    &:active {
      color: var(--bs--color-secundario-negro-hover);
      border: 2px solid var(--bs--color-secundario-negro-hover);
    }

    &[disabled] {
      cursor: not-allowed;

      color: var(--bs--color-grises-gris-40);
      border: 2px solid var(--bs--color-grises-gris-40);
      background: none !important;

      &:hover {
        color: var(--bs--color-grises-gris-40);
        background-color: var(--bs--color-grises-gris-40);
      }

      &.icon {
        figure {
          img {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &.icon {
    @include flex-inline();
    @include flex-position-child('bw    ', 'center');
    @include flex-position-vertical('center');
    min-width: 60px;

    @media #{$tablet} {
      @include flex-position-child('center', 'center');
    }

    span {
      margin: 0 4px;
      text-align: center;
      flex: 1;
      padding-right: 36px;

      @media #{$tablet} {
        margin: 0 8px;
        flex: none;
        text-align: left;
        padding-right: 0px;
      }
    }

    figure {
      margin: 0;
      padding: 0;
      height: 24px;

      &:first-child {
        margin-right: 6px;

        @media #{$tablet} {
          margin-right: 10px;
        }
      }

      &:last-child {
        margin-left: 6px;

        @media #{$tablet} {
          margin-left: 10px;
        }
      }

      img {
        height: 24px;
        width: auto;
      }
    }

    &.mobile-icon-reduction {
      min-width: 20px;
      padding: 0 8px;
      margin: 0px;

      figure {
        height: 28px;

        @media #{$desktop } {
          height: 24px;
        }

        img {
          height: 28px;

          @media #{$desktop } {
            height: 24px;
          }
        }

        &:first-child {
          margin-right: 0px;

          @media #{$desktop } {
            margin-right: 6px;
          }
        }

        &:last-child {
          margin-left: 0px;

          @media #{$desktop} {
            margin-left: 10px;
          }
        }
      }

      span {
        display: none;
      }

      @media #{$desktop } {
        margin: 6px;
        padding: 0 16px;
        min-width: 60px;

        span {
          display: inline-block;
        }
      }
    }
  }

  &-form {
    height: $form-size;
    background-color: var(--bs--color-principal-naranja);
    outline: var(--bs--color-principal-naranja) 1px solid;
    @include border-radius(0 4px 4px 0);
    margin: 0;
    padding: 0 16px;
    min-width: 0px;
    width: auto !important;
    max-width: 120px;
    color: var(--bs--color-principal-blanco);

    &.auto {
      max-width: max-content;
    }

    &.icons {
      cursor: pointer;

      figure {
        margin: 0;
      }
    }

    &.no-bg {
      background: none;
      outline: none;

      &:hover {
        background-color: none !important;
        opacity: 0.5;
      }
    }

    &.float {
      position: absolute;
      top: 0;
      right: 0px;
    }

    &.numbers {
      position: absolute;
      height: $form-size * 0.8 !important;
      width: $form-size * 0.8 !important;
      @include border-radius(50%);
      display: block;
      margin: 0;
      padding: 0;
      top: ($form-size * 0.5) - ($form-size * 0.4);

      &:after {
        content: '';
        display: block;
        @include centrar();
        height: 4px;
        width: 50%;
        background-color: white;
        @include border-radius(2px);
      }

      &.less {
        z-index: 12;
        left: 6px;
      }

      &.plus {
        z-index: 13;
        right: 6px;

        &:before {
          content: '';
          display: block;
          @include centrar();
          height: 50%;
          width: 4px;
          background-color: white;
          @include border-radius(2px);
        }
      }
    }

    &.password {
      background: $icon_watch_off center center no-repeat;

      &.active {
        background: $icon_watch center center no-repeat;
      }
    }

    @include animate(all, 0s, linear);

    &:hover:not(.audio):not(.password):not(.no-bg) {
      background-color: var(--bs--color-secundario-naranja-hover);
      outline: var(--bs--color-secundario-naranja-hover) 1px solid;
    }

    &[disabled] {
      cursor: not-allowed;

      background-color: var(--bs--color-secundario-naranja-20);
      outline: var(--bs--color-secundario-naranja-20) 1px solid;

      &:hover:not(.audio):not(.password):not(.no-bg) {
        background-color: var(--bs--color-secundario-naranja-20);
        outline: var(--bs--color-secundario-naranja-20) 1px solid;
      }

      &.icon {
        figure {
          img {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &-link {
    height: auto;
    margin: 0 !important;
    text-transform: none;
    min-width: auto !important;
    width: auto !important;
    padding: 0;
    cursor: pointer;
    color: var(--bs--color-principal-naranja);
    text-decoration: underline;
    font-family: $overPass_bold;

    &:hover {
      color: var(--bs--color-secundario-naranja-hover);
    }

    &[disabled] {
      cursor: not-allowed;
      color: var(--bs--color-secundario-naranja-20);
    }

    &.blanco {
      color: var(--bs--color-principal-blanco);

      &:hover {
        color: var(--bs--color-grises-gris-20);
      }
    }

    &.negro {
      color: var(--bs--color-principal-negro);

      &:hover {
        color: var(--bs--color-grises-gris-40);
      }
    }
  }

  // tamanos
  &.compacto {
    min-width: min-content;
    height: auto;
    min-height: 26px;
    padding: 0 8px;
  }
}
