input.p-inputtext {
  padding: 0;
  &:focus {
    outline: 2px solid var(--bs--color-principal-negro) !important;
    & + .z-btn-form {
      outline-width: 2px;
    }
  }
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  &.no-border {
    outline: none !important;
  }
  border: none;
  line-height: $form-size;
  @include border-radius(4px);
  font-size: 1.5rem;
  text-indent: 15px;
  margin: 0 0 6px 0;
  color: var(--bs--color-principal-negro);
  position: relative;
  width: 100%;
  font-family: $overPass_bold;

  @include placeholder {
    color: var(--bs--color-grises-gris-80);
    font-family: $overPass_regular;
    font-size: 1.5rem;
  }
  .mix & {
    :not(.icon) {
      @include border-radius(4px 0 0 4px);
    }
  }

  /* &.ng-invalid,
  & */
  .is-invalid {
    outline: 2px solid var(--bs--color-secundario-rojo) !important;
    color: var(--bs--color-secundario-rojo) !important;
    font-family: $overPass_bold;
    @include placeholder {
      color: var(--bs--color-secundario-rojo);
    }
    &:focus {
      outline: 2px solid var(--bs--color-secundario-rojo) !important;
    }
    & + .z-btn-form {
      outline-width: 2px;
    }
    &.no-border {
      outline: none !important;
    }
  }
  &[disabled] {
    & + .z-btn-form {
      margin-left: 2px;
    }
    outline: 1px solid var(--bs--color-grises-gris-40) !important;
    &.no-border {
      outline: none !important;
    }

    cursor: not-allowed;
    color: rgba($gris-50, 0.8);
    opacity: 1;
    @include placeholder {
      color: rgba($gris-50, 0.8);
    }
    & + .z-caption,
    & + .z-caption.z-error {
      color: rgba($gris-50, 0.8);
    }
  }
}
