.z-bloque {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 50px;
  display: block;
  box-sizing: border-box;
  padding: 20px;

  &.mensaje {
    > article {
      width: 100%;
      max-width: 400px;
    }
  }

  &.fotografia {
    padding: 0;
  }

  &.no-padding {
    padding: 0;

    > article {
      padding: 20px;

      &.no-mobile {
        @media #{$no-mobile} {
          padding: 8px 0;
        }
      }

      &.background {
        &.naranja {
          background-color: var(--bs--color-principal-naranja);
        }

        &.naranja-20 {
          background-color: var(--bs--color-secundario-naranja-20);
        }
      }
    }
  }

  .z-selector & {
    padding: 0 !important;
  }

  &[disabled] {
    opacity: 0.3;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  & + & {
    margin-top: 8px;
  }

  //
  @for $i from 0 through 200 {
    $height: 0px+ ($i * 5);
    $name: 0+ ($i * 5);

    &.h-#{$name} {
      height: $height;
    }
  }

  @for $i from 0 through 10 {
    $width: ($i * 10%);
    $name: ($i * 10);

    &.max-w-#{$name}p {
      max-width: 100%;

      @media #{$desktop} {
        max-width: $width;
      }
    }
  }

  @for $i from 0 through 200 {
    $height: 0px+ ($i * 5);
    $name: 0+ ($i * 5);

    &.min-h-#{$name} {
      min-height: $height;
    }
  }

  > img {
    width: 100%;
    height: auto;
  }

  &.fixed {
    height: calc(100% - 60px);
    width: 100%;
    display: block;
    position: fixed;
    left: 0;
    top: 60px;
    overflow: hidden auto;
    @include scrolling(
      2px,
      4px,
      6px,
      var(--bs--color-grises-gris-10),
      var(--bs--color-principal-negro)
    );
  }
  &.center {
  }
  &.background {
    @for $i from 0 through 20 {
      $radius: 0px + $i;
      $name: 0 + $i;

      &.r-#{$name} {
        @include border-radius($radius);

        &.no-padding {
          > article {
            &:first-child {
              @include border-radius($radius $radius 0 0);
            }

            &:last-child {
              @include border-radius(0 0 $radius $radius);
            }
          }
        }
      }
    }

    &.blanco {
      background-color: var(--bs--color-principal-blanco);
    }

    &.gris,
    &.gris-10 {
      background-color: var(--bs--color-grises-gris-10);
    }

    &.gris-20 {
      background-color: var(--bs--color-grises-gris-20);
    }

    &.naranja {
      background-color: var(--bs--color-principal-naranja);
    }

    &.naranja-20 {
      background-color: var(--bs--color-secundario-naranja-20);
    }

    &.naranja-10 {
      background-color: var(--bs--color-secundario-naranja-10);
    }
  }
}

.center-objects {
  @include flex();
  @include flex-position-child('center', 'center');
  @include flex-position-vertical('center');

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    flex: 0 0 100%;
  }

  > section,
  > article,
  > div,
  > figure {
    flex: 0 0 100%;
  }

  &.mensaje {
    > article {
      @include flex();
      @include flex-position-child('center', 'top ');
      @include flex-position-vertical('top    ');
    }
  }
}

// margin
@for $i from 0 through 30 {
  $margin: 0px+ ($i * 1);
  $name: 0+ ($i * 1);

  .m-#{$name} {
    margin: $margin !important;
  }

  .m-t-#{$name} {
    margin-top: $margin !important;
  }

  .m-b-#{$name} {
    margin-bottom: $margin !important;
  }

  .m-l-#{$name} {
    margin-left: $margin !important;
  }

  .m-r-#{$name} {
    margin-right: $margin !important;
  }
}

// padding
@for $i from 0 through 30 {
  $padding: 0px+ ($i * 1);
  $name: 0+ ($i * 1);

  .p-#{$name} {
    padding: $padding !important;
  }

  .p-t-#{$name} {
    padding-top: $padding !important;
  }

  .p-b-#{$name} {
    padding-bottom: $padding !important;
  }

  .p-r-#{$name} {
    padding-right: $padding !important;
  }

  .p-l-#{$name} {
    padding-left: $padding !important;
  }
}

// bordes

.z-border-all {
  border: 3px solid var(--bs--color-grises-gris-10);

  &.gris-10 {
    border-color: var(--bs--color-grises-gris-10);
  }

  &.gris-15 {
    border-color: var(--bs--color-grises-gris-15);
  }

  &.gris-20 {
    border-color: var(--bs--color-grises-gris-20);
  }

  &.gris-40 {
    border-color: var(--bs--color-grises-gris-40);
  }

  &.gris-50 {
    border-color: var(--bs--color-grises-gris-50);
  }

  &.gris-80 {
    border-color: var(--bs--color-grises-gris-80);
  }

  &.no-border-tablet {
    @media #{$no-tablet} {
      border: none;
    }
  }

  &.no-border-mobile {
    @media #{$no-mobile} {
      border: none;
    }
  }
}

.z-border {
  &-left {
    border-left: 2px solid var(--bs--color-grises-gris-20);
  }

  &-right {
    border-right: 2px solid var(--bs--color-grises-gris-20);
  }

  &-top {
    border-top: 2px solid var(--bs--color-grises-gris-20);
  }

  &-bottom {
    border-bottom: 2px solid var(--bs--color-grises-gris-20);
  }

  // color
  &-left,
  &-right,
  &-top,
  &-bottom {
    &.no-border-tablet {
      @media #{$no-tablet} {
        border: none;
      }
    }

    &.no-border-mobile {
      @media #{$no-mobile} {
        border: none;
      }
    }

    &.gris-10 {
      border-color: var(--bs--color-grises-gris-10);
    }

    &.gris-15 {
      border-color: var(--bs--color-grises-gris-15);
    }

    &.gris-20 {
      border-color: var(--bs--color-grises-gris-20);
    }

    &.gris-40 {
      border-color: var(--bs--color-grises-gris-40);
    }

    &.gris-50 {
      border-color: var(--bs--color-grises-gris-50);
    }

    &.gris-80 {
      border-color: var(--bs--color-grises-gris-80);
    }
  }

  &.no-border-tablet {
    @media #{$no-tablet} {
      border: none;
    }
  }

  &.no-border-mobile {
    @media #{$no-mobile} {
      border: none;
    }
  }
}

img {
  $sizes: 4, 8, 12, 20;

  @each $size in $sizes {
    &.border-#{$size} {
      @include border-radius(#{$size}px);
    }
  }

  &.z-fotografia {
    height: auto;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    //   filter: saturate(65%);
    // -webkit-filter: saturate(65%);
  }
}
