 
$check_button_naranja: url(#{$svgpath}checkbox_naranja_check.svg);
$icon_close_naranja: url(#{$svgpath}icon_close_naranja.svg);
$icon-table-min: url(#{$svgpath}minus.svg);
$check_button_blanco: url(#{$svgpath}checkbox_blanco_check.svg);
$icon_prev_naranja: url(#{$svgpath}icon-arrow-prev.svg);
$icon_next_naranja: url(#{$svgpath}icon-arrow-next.svg);
$icon_next_naranja-w: url(#{$svgpath}icon-arrow-next-w.svg);
$icon_up_naranja: url(#{$svgpath}icon-arrow-up.svg);
$icon_down_naranja: url(#{$svgpath}icon-arrow-down.svg);
$icon_sort_mixto: url(#{$svgpath}icon-naranja-arrow-updown.svg);
//icon-naranja-arrow-updown.svg
$icon_down_rojo: url(#{$svgpath}icon-arrow-down-r.svg);
$icon_calendar: url(#{$svgpath}icon-calendar.svg);
$icon_calendar_red: url(#{$svgpath}icon-calendar-red.svg);
$icon_search: url(#{$svgpath}icon-search.svg);
//$icon_prev_naranja: url(#{$svgpath}icon-arrow-prev.svg);
//$icon_prev_naranja: url(#{$svgpath}icon-arrow-prev.svg);

$icon_watch: url(#{$svgpath}icon-watch.svg);
$icon_watch_off: url(#{$svgpath}icon-watch-off.svg);

// desplegable
$check_button_blue: url(#{$svgpath}icon-arrow-prev.svg);

// paginador
$icon-paginator-first:url(#{$svgpath}icon-arrow-first.svg);
$icon-paginator-last:url(#{$svgpath}icon-arrow-last.svg);
$icon-paginator-next:url(#{$svgpath}icon-arrow-prev.svg);
$icon-paginator-prev:url(#{$svgpath}icon-arrow-prev.svg);
$icon-menu:url(#{$svgpath}icon-ui-nav-menu-menu-simple.svg);
$icon-zenit-cruz: url(#{$svgpath}icon-zenit-cruz.svg);

//

$toast_info: url(#{$svgpath}icon_mensaje_emergente_info.svg);
$toast_error: url(#{$svgpath}icon_mensaje_emergente_error.svg);
$toast_exito: url(#{$svgpath}icon_mensaje_emergente_ok.svg);
$toast_advertencia: url(#{$svgpath}icon_mensaje_emergente_advertencia.svg);

//carga
$icon-modulo-imagen-del:url(#{$svgpath}icon-modulo-imagen-del.svg);
$circle-sum:url(#{$svgpath}circle-sum.svg);
$tooltip-file:url(#{$svgpath}tooltip-file.svg);


// gradients
 
$curve-grad-vertical-inv: url(#{$svgpath}curve-grad-horizontal-inv.svg);
$curve-grad-horizontal: url(#{$svgpath}curve-grad-horizontal.svg);
$curve-grad-vertical: url(#{$svgpath}curve-grad-vertical.svg);

// blob
$Blob-top-left: url(#{$svgpath}Blob-top-left.svg);
$Blob-top-right: url(#{$svgpath}Blob-top-right.svg);
$Blob-center-bottom: url(#{$svgpath}Blob-center-bottom.svg);
//Blob-top-left.svg
$Blob-bottom-big: url(#{$svgpath}Blob-center-bottom-big.svg);
$Blob-top-big: url(#{$svgpath}Blob-center-top-big.svg);