.z-simple-data {
  width: 100%;
  display: block;
  margin-bottom: 16px;

  p,
  h3 {
    color: var(--bs--color-principal-negro);
    line-height: normal;
  }

  &.naranja {
    p,
    h3,
    p.h3,
    p.h4 {
      color: var(--bs--color-principal-naranja);
    }
  }

  &.gris-80 {
    p,
    h3,
    p.h3,
    p.h4 {
      color: var(--bs--color-grises-gris-80);
    }
  }

  &:not(.invertido) {
    h3,
    p.h3,
    p.h4,
    h4 {
      font-family: $overPass_regular;
      font-size: 1.4rem;

      & + p {
        font-family: $overPass_bold;
        font-size: 1.6rem;
      }
    }
  }

  &.invertido {
    p {
      font-family: $overPass_bold;
      font-size: 1.3rem;
    }

    h3,
    p.h3,
    p.h4,
    h4 {
      font-family: $overPass_regular;
      font-size: 1.6rem;
    }
  }
}
