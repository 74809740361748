@import 'assets/scss/shared/config/mixins.scss';
@import 'assets/scss/shared/config/variables.scss';

.z-contenedor {
  max-width: 1200px !important;
}

.z-optional {
  margin-left: 4px;
  color: var(--bs--color-grises-gris-50);
  font-size: 1.1rem;
  text-transform: uppercase;
}

.z-accionables {
  width: auto;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 8px 24px 8px 4px;
  @include border-radius(8px);
  border: 3px solid var(--bs--color-grises-gris-10);
  margin-bottom: 16px;
  background-color: var(--bs--color-principal-blanco);
  @include box-shadow(0px, 8px, 10px, 0px, rgba(100, 100, 100, 0.12), false);

  &:hover {
    background-color: var(--bs--color-grises-gris-10);
  }

  &.full {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: var(--bs--color-principal-negro);
    width: 100%;
    display: grid;
    grid-template-columns: 56px auto 12px;

    span {
      height: 48px;
      display: grid;
      place-items: center start;
      line-height: 2rem;
      font-size: var(--bs--font-size-p);
    }

    svg {
      position: absolute;
      right: 10px;
      height: 16px;
      top: calc(50% - 8px);
      fill: var(--bs--color-grises-gris-40);
    }
  }
}

.p-datatable .p-datatable-wrapper table {
  min-width: 100% !important;
}

.p-inputgroup {
  outline: 1px solid var(--bs--color-grises-gris-40) !important;
  border-radius: 4px;

  input.p-inputtext {
    outline: none !important;
    margin: 0;
  }
  input.p-inputtext[disabled] {
    outline: none !important;
  }
}
.p-inputgroup-addon {
  color: var(--bs--color-principal-negro);
  padding-left: 10px;
  padding-right: 10px;
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  border-right: 1px solid var(--bs--color-grises-gris-40) !important;
}

.z-btn-back span.arrow {
  margin-left: 0;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  display: block;
  background: url(assets/img/svg/arrow-button-back.svg) center center no-repeat;
}

.z-plan-setup {
  width: 100%;
  border: 4px solid var(--bs--color-grises-gris-10);
  box-sizing: border-box;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  -webkit-align-content: stretch;
  -moz-flex-direction: stretch;
  margin-bottom: 16px;
}

.z-tarjeta-detalle {
  width: 100%;
  display: block;
  background: var(--bs--color-principal-blanco);
  border: 2px solid var(--bs--color-grises-gris-20);
  margin-bottom: 20px;
}

.z-tarjeta-detalle .top {
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  padding: 4px 6px;
}

.z-tarjeta-detalle .top h1 {
  flex: 1;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--bs--color-principal-negro);
  font-family: 'overpassbold', sans-serif;
}

.z-tarjeta-detalle .top .bs-chip {
  margin: 0;
  background-color: rgb(223, 223, 31);
}

.z-chip {
  margin: 0 8px 8px 0;
  background-color: var(--bs--color-principal-negro);
  color: var(--bs--color-principal-blanco);
  display: inline-block;
  padding: 4px 8px 3px;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'overpassregular', sans-serif;
}

.z-tarjeta-detalle .content {
  background: var(--bs--color-grises-gris-10);
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
}

.z-tarjeta-detalle .extra {
  background: var(--bs--color-principal-blanco);
}

.z-tarjeta-detalle .extra input[type='checkbox'] {
  display: none;
}

.bs-tarjeta-detalle .extra input[type='checkbox'] + label {
  color: var(--bs--color-principal-verde);
  font-family: 'overpassbold', sans-serif;
  line-height: 2.4;
  width: 95%;
  margin: 0 auto;
  text-decoration: underline;
  display: block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  transition: width 0.2s linear;
  cursor: pointer;
}

.z-tarjeta-detalle .content .data .data-module p {
  line-height: 1.5;
  font-family: 'overpassregular', sans-serif;
  font-size: 1.1rem;
}

.z-tarjeta-detalle .content .data .data-module h5 {
  line-height: 1.2;
  font-family: 'overpassbold', sans-serif;
  font-size: 1.5rem;
}

.z-tarjeta-detalle .content .data .data-module {
  flex: 1;
  padding: 8px;
  display: block;
}

.z-tarjeta-detalle .content .data {
  width: 100%;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  border-bottom: 1px solid var(--bs--color-grises-gris-40);
}
