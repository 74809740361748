p-progressbar {
  .p-progressbar {
    border: 0 none;
    height: 1.5rem;

    @include border-radius(3px);
    background: var(--bs--color-grises-gris-20);
    .p-progressbar-value {
      border: 0 none;
      margin: 0;
      background: var(--bs--color-principal-naranja);
    }
    height: 6px;
    margin-bottom: 6px;
    .p-progressbar-label {
      color: var(--bs--color-grises-gris-80);
      font-family: $overPass_regular;
      line-height: 1.5rem;
    }
  }
}
