.z-botonera {
  width: 100%;
  @include flex();
  @include flex-position-child('bw', 'center');
  @include flex-position-vertical('center');
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;

  &.derecha {
    @include flex-position-child('right', 'center');
  }

  &.centro {
    @include flex-position-child('center', 'center');
  }
  &.no-padding {
    padding: 0;
  }
  flex: 0 0 100%;

  &:not(.no-border) {
    border-top: 1px solid var(--bs--color-grises-gris-20);
  }

  .dummy {
    flex: 1;
  }

  @media #{$no-mobile} {
    .z-btn {
      &-primary {
        order: 1;
      }

      &-secondary {
        order: 3;
      }

      &-secondary-negro {
        order: 2;
      }
    }
  }
}
