.z-mensaje-emergente {
  display: block;
  background-color: var(--bs--color-principal-blanco);
  @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.2), false);
  @include border-radius(8px);

  article {
    @include border-radius(8px);
  }

  position: relative;

  &.fijo {
    height: auto;
    position: relative;
    z-index: 0;
    margin: 4px auto 16px;
  }

  &.flotante {
    position: fixed;
    z-index: 10000000;
    width: 96%;
    max-width: 480px;
    @include animate(all, 0.2s, linear);

    &.superior {
      top: 60px;

      @media #{$tablet} {
        top: 70px;
      }
    }

    &.inferior {
      bottom: 10px;
    }

    &.derecha {
      left: 50%;
      transform: translate(-50%, 0);

      @media #{$tablet} {
        transform: translate(0, 0);
        left: inherit;
        right: 10px;
      }
    }

    &.centro {
      left: 50%;
      transform: translate(-50%, 0);
    }

    &.izquierda {
      left: 50%;
      transform: translate(-50%, 0);

      @media #{$tablet} {
        transform: translate(0, 0);
        left: 10px;
      }
    }
  }

  article {
    margin: 0;
    @include flex();
    @include flex-position-child('bw', 'top');
    @include flex-position-vertical('top');
    position: relative;
    background-color: var(--bs--color-principal-blanco);

    figure {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto 48px;
    }

    figure,
    button {
      flex: 0 0 48px;
      height: 48px;
      display: block;
    }

    figcaption {
      flex: 1;
      margin: 12px 0 8px;

      .botonera {
        margin-top: 32px;
        &.simple {
          a {
            margin-right: 16px !important;
          }
        }
      }
    }

    button {
      cursor: pointer;
      //    background: $icon_delete-black center center no-repeat;
      // opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    /*&:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            display: block;
            content: '';
            background-color: var(--bs--color-grises-gris-10);
        }*/
  }

  &.informativo {
    button {
      svg {
        fill: var(--bs--color-principal-blanco);
      }

      &:hover {
        opacity: 0.5;
      }
    }

    article {
      background-color: var(--bs--color-principal-naranja);

      figure {
        background-image: $toast_info;
      }

      figcaption {
        h5,
        p {
          color: var(--bs--color-principal-blanco);
        }
      }

      &:before {
        background-color: var(--bs--color-principal-azul);
      }
    }
  }

  &.error,
  &.exito,
  &.advertencia {
    button {
      svg {
        fill: var(--bs--color-grises-gris-40);
      }

      &:hover {
        svg {
          fill: var(--bs--color-grises-gris-80);
        }
      }
    }
  }

  &.error {
    article {
      figure {
        background-image: $toast_error;
      }

      /*&:before {
                background-color: var(--bs--color-secundario-rojo);
            }*/
    }
  }

  &.exito {
    article {
      figure {
        background-image: $toast_exito;
      }

      /*&:before {
                background-color: var(--bs--color-principal-verde);
            }*/
    }
  }

  &.advertencia {
    article {
      figure {
        background-image: $toast_advertencia;
      }

      /*&:before {
                background-color: var(--bs--color-principal-blanco);
            }*/
    }
  }

  &.mascara {
    @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0), false);

    > a.mascara {
      cursor: grab;
      position: absolute;
      z-index: 0;
      top: -200px;
      left: -10000px;
      width: 100%;
      height: 100%;
      display: block;
      border: rgba(255, 255, 255, 0.7) 10000px solid;
      box-sizing: border-box;

      & + article {
        z-index: 10;
        @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.2), false);
      }
    }
  }
}
